import { useEffect, useState } from "react";

export interface FileUploadServiceProps {
  file?: File;
}

interface FileUploadResult {
  uri: string;
}

export function useFileUpload({ file }: FileUploadServiceProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FileUploadResult | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fileUpload = async () => {
    if (!file || loading) {
      return;
    }

    setLoading(true);

    const formData = new FormData();

    formData.append("file", file);

    fetch(`/api/services/buriuploads/uploads`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setData(result as FileUploadResult);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, data, error, fileUpload };
}
