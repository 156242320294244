import Mustache from "mustache";
import { Markdown } from "../Markdown";

export interface MustacheTemplateRendererProps {
  template: string;
  view: {};
}

export function MustacheTemplateRenderer({
  template = "",
  view = {},
}: MustacheTemplateRendererProps) {
  return (
    <div
      className="w-full h-full"
      dangerouslySetInnerHTML={{ __html: Mustache.render(template, view) }}
    />
    // <div className="w-full">
    //   {/* <Markdown
    //     className="w-full bg-pink-300"
    //     text={Mustache.render(template, view)}
    //   /> */}
    //   <div
    //     className="w-full "
    //     dangerouslySetInnerHTML={{ __html: Mustache.render(template, view) }}
    //   />
    // </div>
  );
}
