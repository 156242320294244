import { Image, Button, Icon } from "../../../atoms/components";
import { useMutation, useQuery, gql } from "@apollo/client";
import { GetAllPlansQuery } from "../../../pages/components/SubscriptionDetail";
import { useErrorHandler } from "react-error-boundary";
import { LoadingCentered } from "../../../molecules/components";
import { useNavigate } from "react-router-dom";

const CreateCheckoutUrlQuery = gql`
  mutation Checkout_create($productId: Int!, $callbackUrl: String) {
    checkoutCreate: checkout_create(
      product_id: $productId
      callback_url: $callbackUrl
    ) {
      checkoutToken: checkout_token {
        checkoutUrl: checkout_url
        userId: user_id
        validUntil: valid_until
      }
    }
  }
`;

export interface Plan {
  name: string;
  price: number;
  uuid: string;
  freeTrial: boolean;
  productId: number;
}

export interface ChoosePlanProps {}

export function ChoosePlan() {
  // const [goToPayment, setGoToPayment] = useState<boolean>(false);
  // const plansData: Plan[] = [
  //   { name: "Test Plan 1", price: 200, uuid: "001", freeTrial: false },
  //   { name: "Test Plan 2", price: 400, uuid: "002", freeTrial: true },
  //   { name: "Test Plan 3", price: 600, uuid: "003", freeTrial: false },
  // ];

  const navigate = useNavigate();

  const { data, error } = useQuery(GetAllPlansQuery);
  useErrorHandler(error);

  const [
    mutatePaymentUrl,
    { loading: paymentLoading, data: paymentData, error: paymentError },
  ] = useMutation(CreateCheckoutUrlQuery);

  useErrorHandler(paymentError);

  const subscriptionPlans = data?.subscriptionScreen.subscriptionPlans;
  return (
    <div>
      {/* {!goToPayment ? ( */}
      <form className="w-3/4 flex flex-col items-center mx-auto">
        {data ? (
          <div className="w-full">
            <h1 className="mt-8 mb-5 text-center text-3xl font-black">
              <Icon
                name="chevron_left"
                className="absolute left-4"
                role="button"
                onClick={() => navigate("/profile/subscriptions")}
              ></Icon>
              Choose Plan
            </h1>
            {/* images */}
            <div className="w-80 lg:w-1/2 mx-auto mb-4">
              <Image src="https://new.account.buribooks.com/images/logo_buribooks_black.svg"></Image>
              <Image src="https://new.account.buribooks.com/images/perks-no-trial.png"></Image>
            </div>
            {subscriptionPlans?.map((plan: Plan) => {
              return (
                <div
                  key={plan.uuid}
                  className="rounded-lg mb-4 bg-gray-100 hover:bg-gray-400 hover:font-bold flex"
                >
                  <input
                    type="radio"
                    name="chosen_plan"
                    id={plan.name}
                    value={plan.uuid}
                    className="m-4 mr-8"
                    onChange={(e) => {
                      mutatePaymentUrl({
                        variables: {
                          callbackUrl: `${window.location.origin}/profile/subscriptions/result`,
                          productId: plan.productId,
                        },
                      });
                    }}
                  />
                  <label
                    htmlFor={plan.name}
                    className=" text-lg p-2 w-full hover:cursor-pointer"
                  >
                    <h3>{plan.name}</h3>
                    <h3>₱ {plan.price}</h3>
                  </label>
                </div>
              );
            })}
            <Button
              onClick={() => {
                // if (chosenPlan !== null) {
                //   setGoToPayment(true);
                // }

                // Sabi sa stackoverflow for external link ganito ginawa ref: https://stackoverflow.com/questions/71606230/usenavigate-navigate-to-external-link
                window.location.href =
                  paymentData.checkoutCreate.checkoutToken.checkoutUrl;
              }}
              className="mx-auto my-8"
              disabled={paymentLoading || !paymentData}
            >
              {paymentData === undefined
                ? "Choose A Plan"
                : paymentLoading || !paymentData
                ? "Loading"
                : "Next"}
            </Button>
          </div>
        ) : (
          <LoadingCentered></LoadingCentered>
        )}
      </form>
      {/* ) : */}

      {/* // (
      //   <Payment mode={mode} chosenPlan={chosenPlan as Plan}></Payment>
      // )} */}
    </div>
  );
}
