import { CategoryCard } from "../CategoryCard";
import { CamelCasedType } from "../../../helpers/components";
import { ResourceCollection as ResourceCollectionOriginal } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";

export type ResourceCollection = CamelCasedType<ResourceCollectionOriginal>;

export interface CategoryBlockProps {
  collections?: ResourceCollection[];
  castBlockUuid?: string;
}

export function CategoryBlock({
  collections = [],
  castBlockUuid = "",
}: CategoryBlockProps) {
  const navigate = useNavigate();
  const pathArray = window.location.pathname.split("/");
  const parentScreenName = pathArray.pop();

  function viewCollection(collectionUuid: string) {
    navigate(
      `/catalogviewer?collection_uuid=${
        collectionUuid || ""
      }&cast_block_id=${castBlockUuid}&from_screen=${parentScreenName}`
    );
  }

  if (collections.length < 1) {
    return <></>;
  }

  return (
    <section aria-label="categories" className="px-20 py-8 space-y-3">
      <h2 className="text-2xl font-black text-gray-700">Categories</h2>
      <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {collections.map((collection) => {
          return (
            <CategoryCard
              key={collection.uuid}
              categoryUuid={collection.uuid || ""}
              coverUri={collection.coverUri || ""}
              name={collection.name || ""}
              onClick={() => {
                viewCollection(collection.uuid || "");
              }}
            />
          );
        })}
      </div>
    </section>
  );
}
