import { useSearchParams } from "react-router-dom";

export interface ExternalLinkProps {
  uri?: string;
  children?: React.ReactNode;
  style?: any;
}

export function ExternalLink({
  uri = "https://google.com",
  children,
  style,
}: ExternalLinkProps) {
  const [searchParams] = useSearchParams();
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const resourceUuid = searchParams.get("resource_uuid");
  const referrerInfo = `${
    courseBlockUuid ? "&course_block_uuid=" + courseBlockUuid : ""
  }${courseUuid ? "&course_uuid=" + courseUuid : ""}${
    resourceUuid ? "&resource_uuid=" + resourceUuid : ""
  }`;
  const externalUrl = `/api/externallink?uri=${encodeURIComponent(
    uri
  )}${referrerInfo}`;

  return (
    <a
      href={externalUrl}
      target="_blank"
      rel={"noreferrer"}
      data-testid="link"
      style={style}
    >
      {children || "Go To External Link"}
    </a>
  );
}
