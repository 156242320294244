export interface NeedHelpProps {
  _type: "login" | "signup";
  clientId?: string;
}

export function NeedHelp({ _type = "login", clientId = "" }: NeedHelpProps) {
  return (
    <div className={`text-center ${_type === "login" ? "sm:text-left" : ""}`}>
      <div className="text-xl font-bold">Need Help?</div>
      {_type === "login" ? (
        <div>
          <a
            href={`${process.env.REACT_APP_PASS_URI}/users/password/new?client_id=${clientId}`}
            className="text-secondary-400"
          >
            Forgot password
          </a>
          <div>
            {/* eslint-disable */}
            <a
              href={`${process.env.REACT_APP_PASS_URI}/users/confirmation/new?client_id=${clientId}`}
              className="text-secondary-400"
            >
              Resend Confirmation Instructions
            </a>
          </div>
        </div>
      ) : (
        <a
          href={`${process.env.REACT_APP_PASS_URI}/users/password/new?client_id=${clientId}`}
          className="text-secondary-400"
        >
          Forgot password
        </a>
      )}
    </div>
  );
}
