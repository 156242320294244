import React from "react";
import { randomBuriDog } from "../../../helpers/components/RandomBuriDog";
import { Image } from "../../../atoms/components";

export interface CastNotFoundProps {
  prop?: string;
}

export function CastNotFound({ prop = "default value" }: CastNotFoundProps) {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <Image
        src={randomBuriDog()}
        className="w-2/3 max-h-[500px] "
        alt="Buri Dog Picture"
      />
      <h1 className="text-3xl text-primary-500 font-bold mb-4 w-4/5 text-center">
        This Cast does not exist... yet!
      </h1>
      <p className="w-2/3 mx-auto mb-6 text-center">
        Visit{" "}
        <a href="http://castlms.com" className="text-primary-600 font-bold">
          castlms.com
        </a>{" "}
        to get started on your own Cast!
      </p>
    </div>
  );
}
