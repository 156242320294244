import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Course,
  ModalObject,
  CourseViewScreen,
  CourseBlock,
} from "../../../pages/components/CourseViewer";
import { ColorObj } from "../../../helpers/ColorHelper";
import { CatalogCard } from "../../../molecules/components";

export interface CourseDefaultLayoutProps {
  courseViewScreen?: CourseViewScreen;
  modalObject?: ModalObject;
  cardStyle?: ColorObj;
  onCourseBlockClick?: (block: CourseBlock) => void;
}

export function CourseDefaultLayout({
  courseViewScreen,
  cardStyle,
  onCourseBlockClick,
}: CourseDefaultLayoutProps) {
  const location = useLocation();

  useEffect(() => {
    if (location.state) window.scrollTo(0, location.state.scrollYValue);
  }, []);

  const course = courseViewScreen?.course;

  return (
    <div>
      {course?.outline?.map((outline, sectionIdx) => {
        return (
          <div className="flex flex-col" key={sectionIdx}>
            <p className="mb-4 text-2xl font-black text-gray-900">
              {outline?.title}
            </p>

            <div className="flex flex-col flex-wrap items-center md:flex-row ">
              {outline?.blocks?.map((block, blockIdx) => {
                let successValue =
                  block?.userstatus?.status !== "not_started" &&
                  block?.userstatus?.status !== null &&
                  block?.userstatus !== null
                    ? block?.userstatus?.status === "passed"
                      ? true
                      : false
                    : null;
                
                const openDate = block?.scheduleReferenceInfo ? block.scheduleReferenceInfo.openDate : block?.openDate
                const isTimedBlock = openDate ? Date.now() < new Date(openDate).getTime() : false;

                return (
                  <button
                    className="relative mb-2 md:mb-0"
                    onClick={() => {
                      onCourseBlockClick?.(block as CourseBlock);
                    }}
                    key={blockIdx}
                  >
                    <div>
                      <CatalogCard
                        name={block?.title}
                        locked={
                          block?.isAccessible === null
                            ? block?.prerequisitesCourseBlocks?.some(
                                (prerequisitesCourseBlock) =>
                                  prerequisitesCourseBlock?.hasUserCompleted ===
                                  false
                              )
                            : !block?.isAccessible
                        }
                        isInProgress={
                          block?.userstatus?.status === "in_progress"
                        }
                        coverUri={block?.coverUri}
                        lockIcon={isTimedBlock ? "schedule" : "lock"}
                        isCourse={true}
                        type={block?.resource?.type || ""}
                        success={successValue}
                        expiryDate={block?.expiryDate || ""}
                        daysTilExpired={block?.daysTilExpired || 0}
                      />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
