import { CastColor as CastColorOriginal } from "../../generated/graphql";
import { CamelCasedType } from "../components";

export type CastColor = CamelCasedType<CastColorOriginal>;

export interface ColorObj {
  backgroundColor?: string;
  color?: string;
  fontFamily?: string;
}

// Deprecated
export function colorHelperBase(colorBg: CastColor, colorFg: CastColor) {
  return {
    backgroundColor: `rgba(${colorBg?.red},${colorBg?.green},${colorBg?.blue},${colorBg?.alpha})`,
    color: `rgba( ${colorFg?.red},${colorFg?.green},${colorFg?.blue},${colorFg?.alpha})`,
  };
}

export const parseColorToStyle = (
  params: {
    backgroundColor?: CastColor | null;
    foregroundColor?: CastColor | null;
    fontFamily?: (string | null)[] | null;
  } | null = {}
): ColorObj => {
  const { backgroundColor, foregroundColor, fontFamily } = params || {};

  return {
    backgroundColor: backgroundColor
      ? `rgba(${backgroundColor.red},${backgroundColor.green},${backgroundColor.blue},${backgroundColor.alpha})`
      : "",
    color: foregroundColor
      ? `rgba(${foregroundColor.red},${foregroundColor.green},${foregroundColor.blue},${foregroundColor.alpha})`
      : "",
    fontFamily: fontFamily?.join(","),
  };
};
