import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getNavSlideTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const NAVSLIDE_TEMPLATE = `
  <div class="w-full flex flex-col ${colorTheme.base} h-full px-4 py-2 md:px-8 md:py-4 overflow-clip md:overflow-visible space-y-4 rounded-xl">

  <div class="flex font-titillium justify-between items-center">
      <div class="flex text-4xl font-black whitespace-nowrap w-fit mr-8" > 
        {{#isGbf}}
          <p class=" text-teal-600 mr-1">GBF</p>  
          <p class=" text-teal-800 " >Class Builder</p>
        {{/isGbf}}
      </div>

      <!-- Progress Bar -->
      <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
        {{#progressBar}}
          <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
            <i class="material-icons " >
              {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
            </i> 
            <p class="text-sm w-fit line-clamp-1 font-bold text-center {{#lastVisitedTitlePage}}{{id}}{{/lastVisitedTitlePage}} " > 
              {{data.title}}  
            </p>
          </section>
          <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
          </div>
        {{/progressBar}}
      </div>
  </div>

  <!-- NAV TEMPLATE -->
    {{#imageUri}}

    <div class="h-full w-full overflow-auto">
      <img src="{{imageUri}}" class="flex-1 object-contain w-full h-full" />
    </div>

    {{/imageUri}}

    {{^imageUri}}
      <div class="h-full w-full"></div>
    {{/imageUri}}

    <section id="navButtonSection" class="flex ${colorTheme.gradientPanel} p-4 rounded-2xl justify-between items-center">
      <button id="backButton" class="text-white p-2 font-titillium font-semibold rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
        <i class="material-icons  " id="previousPage" >arrow_back</i>
      </button>
      <button id="nextButton" class="text-white p-2 font-titillium font-semibold md:px-4 md:py-2  rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
        <span class="hidden md:inline-block">{{buttonText}}</span>   <i class="material-icons  " id="previousPage" >arrow_forward</i>
      </button>
    </section>
  </div>`;

  if (theme === null || theme === undefined) {
    return NAVSLIDE_TEMPLATE;
  }

  return NAVSLIDE_TEMPLATE;
}
