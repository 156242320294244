import { useEffect, useState } from "react";
import { Icon } from "../../../atoms/components";

export interface TimerProps {
  timeLimit?: number | null;
  action?: any;
  stopTimer?: boolean;
}

export function Timer({ timeLimit, action, stopTimer }: TimerProps) {
  const _hours = timeLimit ? Math.floor(Number(timeLimit) / 3600) : 0;
  const _minutes = timeLimit ? Math.floor((Number(timeLimit) % 3600) / 60) : 0;
  const _seconds = timeLimit ? Math.floor((Number(timeLimit) % 3600) % 60) : 0;
  const [hours, setHours] = useState(_hours);
  const [minutes, setMinutes] = useState(_minutes);
  const [seconds, setSeconds] = useState(_seconds);

  useEffect(() => {
    if (timeLimit && hours > 0 && minutes > 0 && seconds > 0) {
      setHours(_hours);
      setMinutes(_minutes);
      setSeconds(_seconds);
    }
  }, [timeLimit, _hours, _minutes, _seconds, hours, minutes, seconds]);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!stopTimer) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              action();
              clearInterval(myInterval);
            } else {
              setHours(hours - 1);
              setMinutes(59);
              setSeconds(59);
            }
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes, hours]);
  return (
    <div className="flex text-info-600 font-bold">
      <Icon name="timer" />
      <div className="ml-1">
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </div>
    </div>
  );
}
