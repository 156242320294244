import React from "react";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children?: React.ReactNode;
}

export function Select({ children, ...props }: SelectProps) {
  return (
    <select
      {...props}
      className={`border-2 border-gray-300 rounded ${props.className}`}
    >
      {children}
    </select>
  );
}
