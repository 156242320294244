import {
  ActivityTypeSwitch,
  ResourceContentBurislideSpread,
  ResourceContentBurislideSpreadStyleInfo,
  FlagObject,
  SpreadInfo,
} from "../../../pages/components/GbfSlide";
import { ExamItemV2 } from "../../../pages/components/AssessmentViewerV2";
import { Icon, Loading, ProgressBar } from "../../../atoms/components";
import { useState, useEffect, useLayoutEffect } from "react";
import { useInView, InView } from "react-intersection-observer";
import { ColorObj, parseColorToStyle } from "../../../helpers/ColorHelper";
import { LoadingCentered } from "../../../molecules/components";
import { ExamResponseInput } from "../../../generated/graphql";
import { ExamResponse } from "../../../generated/graphql";
import { ExamItemType } from "../../../molecules/components/AssessmentItemFeedback";
import { twMerge } from "tailwind-merge";
import { ResultSlide } from "../ResultSlide";

export interface ActivityGroupSlideProps {
  item?: ResourceContentBurislideSpread;
  savedResponses?: ExamResponseInput[];
  handleResponse?: (response: ExamResponseInput) => void;
  manageNavDisabled?: (isNavDisabled: boolean) => void;
  savedFeedbacks?: ExamResponse[];
  feedbackForThisActivity?: false | ExamResponse;
  leftPage?: (
    handleRightPageFeedback: (feedback: ExamResponse) => void,
    feedbackForThisActivity: ExamResponse | false,
    examItemType: string,
    activityGroupExamItem: ExamItemV2
  ) => string | JSX.Element;
  activeFlags: FlagObject[];
  manageCurrentActivityGroupSlide?: (examItem: ExamItemV2) => void;
  id: number;
  manageSpreadInfo: (spreadInfo?: SpreadInfo) => SpreadInfo[];
  submissionUuid: string;
  finalAppearance: {
    finalBgColor: ColorObj;
    finalTextStyle: ColorObj;
  };
}

export function ActivityGroupSlide({
  item,
  manageNavDisabled = () => {},
  handleResponse = () => {},
  savedResponses,
  savedFeedbacks = [],
  feedbackForThisActivity,
  leftPage = () => "",
  activeFlags,
  manageCurrentActivityGroupSlide,
  id,
  manageSpreadInfo,
  submissionUuid,
  finalAppearance,
}: ActivityGroupSlideProps) {
  const [slideIndex, setSlideIndex] = useState(
    manageSpreadInfo().find((info) => info.spreadIndex === id)?.slideIndex || 0
  );

  const { inView, ref } = useInView();

  // const backgroundColor = parseColorToStyle({
  //   backgroundColor: appearance?.base?.backgroundColor,
  // });

  // For disabling continue

  const slideIndexChange = manageSpreadInfo().find(
    (info) => info.spreadIndex === id
  )?.slideIndex;

  const [slidesToDisplay, setSlidesToDisplay] = useState(
    item?.examSectionItems
  );

  const choicesUuidWithTriggerArray = item?.examChoiceTriggerFlags?.map(
    (flag) => flag?.examChoiceUuid
  );

  const isBranchingSlide = slidesToDisplay?.[slideIndex]?.choices?.some(
    (choice) => choicesUuidWithTriggerArray?.includes(choice?.uuid)
  );

  // const isBranchingSlide = false;

  const showResults = slidesToDisplay?.[slideIndex]?.showResults || false;

  useLayoutEffect(() => {
    if (slideIndex < (item?.examSectionItems?.length || 1) - 1) {
      manageNavDisabled(true);
    } else manageNavDisabled(false);

    if (slidesToDisplay?.[slideIndex] && manageCurrentActivityGroupSlide) {
      manageCurrentActivityGroupSlide(slidesToDisplay?.[slideIndex] || {});
    }

    manageSpreadInfo({
      slideIndex: slideIndex,
      spreadIndex: id,
      slideLength:
        (slidesToDisplay?.length || 1) + (item?.isResultPageShown ? 1 : 0),
      itemUuid: slidesToDisplay?.[slideIndex]?.uuid || "",
      hasResultsPage: item?.isResultPageShown || false,
      isBranchingSlide: isBranchingSlide || false,
      showResults: showResults,
    });
  }, [slideIndex]);

  // For slide movement using outside buttons
  useLayoutEffect(() => {
    const currentSlideIndexBasedOnSpreadInfo = manageSpreadInfo().find(
      (info) => info.spreadIndex === id
    )?.slideIndex;
    setSlideIndex(currentSlideIndexBasedOnSpreadInfo || 0);
  }, [slideIndexChange]);

  // Slides to display useEffect
  useLayoutEffect(() => {
    const itemsWithRequiredFlags = item?.examItemRequiredFlags?.map(
      (requiredFlag) => requiredFlag?.examItemUuid
    );

    const allItems = item?.examSectionItems?.map((item) => item?.uuid);

    const itemsWithNoRequiredFlags = allItems?.filter(
      (item) => !itemsWithRequiredFlags?.includes(item)
    );

    const passingFlags = item?.examItemRequiredFlags?.filter((items) =>
      items?.flags?.some((flag) =>
        activeFlags.some(
          (activeFlag) =>
            activeFlag.flagId === flag?.flagId && activeFlag.spreadIndex === id
        )
      )
    );

    const accessibleSlidesUuid = passingFlags?.map(
      (flag) => flag?.examItemUuid
    );
    const accessibleSectionItems = item?.examSectionItems?.filter(
      (sectionItem) => {
        if (accessibleSlidesUuid?.includes(sectionItem?.uuid)) {
          return sectionItem;
        }

        if (itemsWithNoRequiredFlags?.includes(sectionItem?.uuid)) {
          return sectionItem;
        }

        if (
          sectionItem?.choices?.some((choice) =>
            choicesUuidWithTriggerArray?.includes(choice?.uuid)
          )
        ) {
          return sectionItem;
        }

        return false;
      }
    );
    setSlidesToDisplay(accessibleSectionItems);
  }, [activeFlags, item, id]);

  // To initiate Spread Info in case slides to display length changes
  useEffect(() => {
    const slideIndexToUse = () => {
      const currentExamSectionItem = item?.examSectionItems?.[slideIndex];
      const feedbackForThisActivity =
        savedFeedbacks.find(
          (feedback) => feedback.item === currentExamSectionItem?.uuid
        ) || false;
      const hasNextSlide = slideIndex + 1 < (slidesToDisplay?.length || 1);
      if (
        isBranchingSlide &&
        !currentExamSectionItem?.showResults &&
        !!feedbackForThisActivity &&
        hasNextSlide
      ) {
        if (!!feedbackForThisActivity) {
          return slideIndex + 1;
        }
      }
      return slideIndex;
    };
    manageSpreadInfo({
      slideIndex: slideIndexToUse(),
      spreadIndex: id,
      slideLength:
        (slidesToDisplay?.length || 1) + (item?.isResultPageShown ? 1 : 0),
      itemUuid: slidesToDisplay?.[slideIndex]?.uuid || "",
      hasResultsPage: item?.isResultPageShown || false,
      isBranchingSlide: isBranchingSlide || false,
      showResults: showResults || false,
    });
  }, [slidesToDisplay]);

  // if the activity group is still sliding

  useEffect(() => {
    if (
      slideIndex + 1 <
      (slidesToDisplay?.length || 1) + (item?.isResultPageShown ? 1 : 0)
    ) {
      manageNavDisabled(true);
      setTimeout(() => {
        manageNavDisabled(false);
      }, 300);
    } else {
      manageNavDisabled(false);
    }
  }, [slideIndex]);

  return (
    <div
      className="box-border relative h-full overflow-x-hidden overflow-y-hidden bg-gray-100 border border-black max-h-[88vh] rounded-lg"
      style={parseColorToStyle({
        backgroundColor: item?.appearance?.base?.backgroundColor,
      })}
    >
      {/* <button
        onClick={() => {
          setSlideIndex((prev) => {
            if (prev > 0) return prev - 1;
            return prev;
          });
        }}
        className={`text-4xl md:text-5xl lg:text-5xl transition-all absolute left-2 my-auto top-0 bottom-0 z-30 ${
          1 <= 0
            ? "hidden"
            : "rounded-full flex items-center bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 mr-4 opacity-50 hover:opacity-100"
        } `}
      >
        <Icon name="chevron_left" />
      </button> */}

      <div className=" h-full max-h-[88vh] overflow-y-auto overflow-x-clip">
        {/* Activity slide */}

        <div
          className="flex h-full mx-auto transition-transform duration-700 ease-in-out "
          style={{
            transform: `translateX(-${105 * slideIndex}%) `,
          }}
        >
          {slidesToDisplay?.map((examSectionItem) => {
            let rightPageFeedback: ExamResponse = {};
            function handleRightPageFeedback(
              rightPageFeedbackObject: ExamResponse
            ) {
              rightPageFeedback = rightPageFeedbackObject;
            }

            const feedbackForThisActivity =
              savedFeedbacks.find(
                (feedback) => feedback.item === examSectionItem?.uuid
              ) || false;

            return (
              <InView key={examSectionItem?.uuid} threshold={0}>
                {({ inView, ref, entry }) => (
                  <div
                    className={twMerge(
                      ` min-w-[100%] max-w-[90%] mr-[5%] box-border h-full flex flex-col md:flex-row`
                    )}
                    ref={ref}
                  >
                    {leftPage(
                      handleRightPageFeedback,
                      feedbackForThisActivity,
                      examSectionItem?.type || "",
                      examSectionItem || {}
                    )}
                    {inView &&
                      (!feedbackForThisActivity ||
                        examSectionItem?.subtype !== "SU") && (
                        <div
                          className={`w-full md:w-1/2 flex-1 bg-gray-100 rounded-b-lg relative ${
                            examSectionItem?.mediaUrl || rightPageFeedback.uuid
                              ? "md:rounded-r-lg  md:rounded-l-none"
                              : "rounded-lg"
                          } md:rounded-r-lg px-4 p-10 overflow-y-auto custom-scrollbar scrollbar-color-gray bg-center bg-cover `}
                          style={
                            examSectionItem?.subtype === "SU" &&
                            examSectionItem?.mediaUrl
                              ? {
                                  backgroundImage: `url(${examSectionItem?.mediaUrl})`,
                                }
                              : finalAppearance.finalBgColor
                          }
                        >
                          <ActivityTypeSwitch
                            examItem={examSectionItem as ExamItemV2}
                            rightPageFeedback={rightPageFeedback}
                            handleResponse={handleResponse}
                            savedResponses={savedResponses || []}
                            finalAppearance={finalAppearance}
                          />
                        </div>
                      )}

                    {!inView && (
                      <div className="w-2 h-2">
                        <Loading />
                      </div>
                    )}
                  </div>
                )}
              </InView>
            );
          })}

          <div ref={ref} className="min-w-[100%] max-h-[100%]">
            {item?.isResultPageShown && inView ? (
              <ResultSlide
                examSectionUuid={slidesToDisplay?.[0]?.section || ""}
                submissionUuid={submissionUuid}
                id={id}
                key={id}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* <button
        onClick={() => {
          setSlideIndex((prev) => {
            if (prev + 1 < (item?.examSectionItems?.length || 1)) {
              return prev + 1;
            }
            return prev;
          });
        }}
        className={`text-4xl md:text-5xl lg:text-5xl transition-all absolute right-2 my-auto top-0 bottom-0 z-30 ${
          // index === (item.uris?.length || 1) - 1 || index !== slideIndex
          // slideIndex === (item.uris?.length || 1) - 1
          false
            ? "hidden"
            : "rounded-full flex items-center bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12  opacity-50 hover:opacity-100"
        }`}
      >
        <Icon name="chevron_right" />
      </button> */}

      {/* <ProgressBar value={slideIndex + 1} max={item.uris?.length || 1} />
       */}

      <ProgressBar
        value={slideIndex + 1}
        max={(slidesToDisplay?.length || 1) + (item?.isResultPageShown ? 1 : 0)}
        className="absolute bottom-0 left-0 right-0 px-4 mb-4"
      />
    </div>
  );
}
