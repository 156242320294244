import { ColorObj } from "../../../helpers/ColorHelper";
import { ResourceContentBurislideSpread } from "../../../generated/graphql";
import { Button, Image } from "../../../atoms/components";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import "./ScrollCarouselSlide.css";

export function ScrollCarouselSlide({
  item,
  manageNavDisabled = () => {},
  finalAppearance,
}: {
  item: ResourceContentBurislideSpread;
  manageNavDisabled: (isNavDisabled: boolean) => void;
  finalAppearance?: {
    finalBgColor: ColorObj;
    finalTextStyle: ColorObj;
  };
}) {
  const backgroundColor = finalAppearance?.finalBgColor;
  const loadAll = useInView({
    threshold: 0.5,
  });
  const lastPage = useInView({
    threshold: 0.5,
  });
  const [displayAll, setDisplayAll] = useState<boolean>(false);

  useEffect(() => {
    if (lastPage.inView) {
      manageNavDisabled(false);
    } else {
      manageNavDisabled(true);
    }
  }, [lastPage.inView, item]);

  useEffect(() => {
    if (loadAll.inView) {
      setDisplayAll(true);
    }
  }, [loadAll.inView, item]);
  return (
    <div className=" w-full h-[85vh]  overflow-y-scroll mx-auto flex flex-col py-[5vh] -z-10 carousel-custom-scrollbar scrollbar-color-gray shadow-md shadow-slate-200  ">
      {/* Image */}
      {item.uris?.map((imageUri, index) => {
        if (index + 1 === 5 && (item.uris?.length || 0) > 5)
          return (
            <div
              key={index}
              className={` min-h-[75%] md:min-h-[85%] lg:min-h-[95%] max-h-[95%] w-auto  rounded flex  overflow-clip drop-shadow-lg mx-auto -z-10 py-4 `}
              style={backgroundColor}
              ref={loadAll.ref}
            >
              <Image
                src={imageUri || ""}
                className={`mx-auto rounded-lg h-full  `}
              />
            </div>
          );
        if (index + 1 !== item.uris?.length) {
          return (
            // Image Container
            <div
              key={index}
              className={` min-h-[75%] md:min-h-[85%] lg:min-h-[95%] max-h-[95%] w-auto  rounded flex  overflow-clip drop-shadow-lg mx-auto -z-10 py-4 ${
                index < 5 || displayAll ? "" : "hidden"
              } `}
              style={backgroundColor}
            >
              <Image
                src={imageUri || ""}
                className={`mx-auto rounded-lg h-full  `}
              />
            </div>
          );
        }

        return (
          // Image Container
          <div
            key={index}
            className={` min-h-[50%] md:min-h-[85%] lg:min-h-[95%] max-h-[95%] w-auto  rounded flex  overflow-clip drop-shadow-lg mx-auto -z-10 py-4 `}
            style={backgroundColor}
            ref={lastPage.ref}
          >
            <Image
              src={imageUri || ""}
              className={`mx-auto rounded-lg  md:h-full `}
            />
          </div>
        );
      })}
    </div>
  );
}
