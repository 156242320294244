import { NavBarLayout } from "../../../templates/components";
import { useEffect, useState } from "react";
import { Icon } from "../../../atoms/components";
import { FilterPill, LoadingCentered } from "../../../molecules/components";
import { TypeFilterPill } from "../../../molecules/components/FilterPill";
import { ResultCard } from "../../../organisms/components";
import { useSearchScreenLazyQuery } from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { Resource as ResourceOriginal } from "../../../generated/graphql";
import { EnrollmentModals } from "../../../organisms/components";
import { ScreenModal } from "../../../molecules/components/CastBlocks";
import { CourseResource } from "../../../molecules/components/CastBlocks";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export type Resource = CamelCasedType<ResourceOriginal>;

const ResourceFilterMap = {
  ALL: "",
  Courses: "course",
  Resources: "course",
};

export function SearchResult() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchString, setSearchString] = useState<string>(
    searchParams.get("search_value") || ""
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [previousSearch, setPreviousSearch] = useState<string>("");
  const [typeFilter, setTypeFilter] =
    useState<"ALL" | "Resources" | "Courses">("ALL");
  const [seachScreenLazyQuery, searchScreenResponse] =
    useSearchScreenLazyQuery();

  let filteredResults =
    searchScreenResponse.data?.searchScreen?.resources?.filter((resource) => {
      if (typeFilter !== "ALL") {
        if (typeFilter === "Courses") {
          return resource?.type === "course";
        }

        if (typeFilter === "Resources") {
          return resource?.type !== "course";
        }
      }

      return true;
    });

  const totalMatches = filteredResults?.length || 0;

  let resultsToDisplay = filteredResults?.slice(
    10 * (pageNumber - 1),
    10 * (pageNumber - 1) + 10 || filteredResults.length
  );

  function search() {
    seachScreenLazyQuery({
      variables: {
        q: searchString,
      },
    });

    setPageNumber(1);
    setPreviousSearch(searchString);
    searchParams.set("search_value", searchString);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (!!searchParams.get("search_value")) {
      search();
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [typeFilter]);

  const [courseCardModal, setCourseCardModal] = useState<boolean>(false);
  const [batchModal, setBatchModal] = useState<boolean>(false);
  const [courseResource, setCourseResource] = useState<CourseResource>();
  const [resourceUuid, setResourceUuid] = useState<string>("");
  const navigate = useNavigate();

  const screenModal: ScreenModal = {
    objectFunction: {
      setCourseCardModal: setCourseCardModal,
      setBatchModal: setBatchModal,
      setCourseResource: setCourseResource as React.Dispatch<
        React.SetStateAction<CourseResource>
      >,
      setResourceUuid: setResourceUuid,
    },
    state: courseCardModal,
  };

  function onClickResourceCard({ resource }: { resource: Resource }) {
    const isCourse = resource.type === "course";

    if (isCourse) {
      setCourseResource({
        name: resource?.name || "",
        description: resource.description || "",
        image: resource.coverUri || "",
        uuid: resource.uuid || "",
      });
      setCourseCardModal(true);
    } else {
      navigate(
        `/view?resource_uuid=${
          resource?.uuid
        }&from_screen=search&search_value=${searchParams.get("search_value")}`
      );
    }
  }

  return (
    <NavBarLayout>
      <EnrollmentModals
        courseCardModal={courseCardModal}
        setCourseCardModal={setCourseCardModal}
        batchModal={batchModal}
        setBatchModal={setBatchModal}
        courseResource={courseResource}
        screenModal={screenModal}
        resourceUuid={resourceUuid}
      ></EnrollmentModals>
      <header className="w-full px-4 py-8 mb-8 bg-primary-700 h-28 md:px-16 md:py-8">
        <section
          aria-label="Search section"
          className="flex gap-4 mx-auto my-auto md:gap-8 "
        >
          <label className="flex flex-1 gap-0 text-2xl text-gray-500 border border-gray-500 rounded-lg overflow-clip">
            <Icon
              name="search"
              className="box-content flex items-center p-2 px-4 text-gray-400 bg-white h-9 "
            />

            <input
              type="text"
              name="search-input"
              className="w-full h-full text-2xl border-0 placeholder:text-2xl"
              value={searchString}
              placeholder="Search"
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
            ></input>
          </label>

          <button
            onClick={() => {
              search();
            }}
            className="flex items-center justify-center w-24 gap-1 px-3 py-2 text-lg text-white border border-white rounded-md"
          >
            <Icon name="filter_list" /> <p>FILTER</p>
          </button>
        </section>
      </header>
      {/* Current Filter Section */}
      <section
        aria-label="Filter section"
        className="p-2 mx-4 mb-8 bg-gray-100 rounded-md md:mx-16"
      >
        <span className="flex justify-between mb-2 text-sm text-gray-500">
          <div className="flex gap-2 ">
            <Icon name="filter_list" />
            <p>Filter</p>
          </div>
        </span>

        <span aria-label="Current Filters" className="flex gap-2">
          {previousSearch && (
            <FilterPill filterName="Name" filterValue={previousSearch} />
          )}
          <FilterPill filterName="Type" filterValue={typeFilter} />
        </span>
      </section>
      {/* Resource Type Filter Section */}
      <section
        aria-label="Resource type options section"
        className="flex gap-2 mx-4 mb-8 md:mx-16"
      >
        <TypeFilterPill
          isSelected={typeFilter === "ALL"}
          name="ALL"
          onClick={() => {
            setTypeFilter("ALL");
          }}
        />
        <TypeFilterPill
          isSelected={typeFilter === "Resources"}
          name="Resources"
          onClick={() => {
            setTypeFilter("Resources");
          }}
        />
        <TypeFilterPill
          isSelected={typeFilter === "Courses"}
          name="Courses"
          onClick={() => {
            setTypeFilter("Courses");
          }}
        />
      </section>
      {searchScreenResponse.data && (
        <span className="flex mx-4 mb-8 text-secondary-500 md:mx-16">
          <p className="mr-1 font-bold">{totalMatches}</p> result(s) found
        </span>
      )}
      <main className="mx-4 space-y-8 md:mx-16">
        {searchScreenResponse.data && (
          <h2 className="text-xl font-bold text-gray-700">Results</h2>
        )}
        <section className="grid gap-8 lg:grid-cols-2 ">
          {!searchScreenResponse.loading &&
            filteredResults &&
            resultsToDisplay?.map((resource) => {
              return (
                <ResultCard
                  key={resource?.uuid}
                  resource={resource || {}}
                  searchString={previousSearch}
                  typeFilter={typeFilter}
                  onClickFunction={() => {
                    onClickResourceCard({
                      resource: resource || {},
                    });
                  }}
                />
              );
            })}

          {searchScreenResponse.loading && !filteredResults && (
            <LoadingCentered />
          )}
        </section>
      </main>
      <PaginationNavbar
        numberOfPages={Math.ceil((filteredResults?.length || 0) / 10)}
        currentPage={pageNumber}
        onClickFunction={(number: number) => {
          setPageNumber(number);
          window.scrollTo(0, 0);
        }}
      />
    </NavBarLayout>
  );
}

function PaginationNavbar({
  numberOfPages,
  currentPage,
  onClickFunction,
}: {
  numberOfPages: number;
  currentPage: number;
  onClickFunction: (number: number) => void;
}) {
  const [pagesToDisplay, setPagesToDisplay] = useState([
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ]);

  useEffect(() => {
    setPagesToDisplay([
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ]);
  }, [currentPage, numberOfPages, onClickFunction]);

  const getPaddedArray = () => {
    const postPadding = pagesToDisplay.filter(
      (number) => number > numberOfPages
    ).length;
    const prePadding = pagesToDisplay.filter((number) => number < 1).length;
    const validPages = pagesToDisplay.filter(
      (number) => number >= 1 && number <= numberOfPages
    );

    let newPaddedArray = [];

    if (postPadding > 0) {
      for (let i = postPadding; i > 0; i--) {
        const newPage = validPages[0] - i;
        if (newPage >= 1) {
          newPaddedArray.push(newPage);
        }
      }
    }

    newPaddedArray = [...newPaddedArray, ...validPages];

    if (prePadding > 0) {
      for (let i = 1; i <= prePadding; i++) {
        const newPage = validPages[validPages.length - 1] + i;
        if (newPage <= numberOfPages) {
          newPaddedArray.push(newPage);
        }
      }
    }
    return newPaddedArray;
  };

  if (numberOfPages <= 1) {
    return <></>;
  }

  return (
    <div className="flex gap-4 text-lg max-w-[50vw] w-fit overflow-clip mx-auto my-8 text-center">
      {currentPage > 1 && (
        <div>
          <Icon
            name="
        keyboard_double_arrow_left"
            onClick={() => {
              onClickFunction(1);
            }}
            role="button"
          />
          <Icon
            name="chevron_left"
            onClick={() => {
              onClickFunction(currentPage - 1);
            }}
            role="button"
          />
        </div>
      )}

      {getPaddedArray()?.map((pageNumber: number) => (
        <button
          key={pageNumber}
          className={`${pageNumber === currentPage ? "font-bold" : ""}`}
          onClick={() => {
            onClickFunction(pageNumber);
          }}
        >
          {pageNumber}
        </button>
      ))}

      {currentPage < numberOfPages && (
        <div>
          <Icon
            name="chevron_right"
            onClick={() => {
              onClickFunction(currentPage + 1);
            }}
            role="button"
          />
          <Icon
            name="
          keyboard_double_arrow_right"
            onClick={() => {
              onClickFunction(numberOfPages);
            }}
            role="button"
          />
        </div>
      )}
    </div>
  );
}
