import { Iframe } from "../../../atoms/components/Iframe";

export interface ViewResourceHtmlProps {
  src?: string;
}

export function ViewResourceHtml({
  src = "https://d1g96a0e9vp024.cloudfront.net/sample/cd9d8309-f2d7-43aa-a345-b60ea7b31681/index.html#/",
}: ViewResourceHtmlProps) {
  return <Iframe src={src} />;
}
