import { useErrorHandler } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { ColorObj } from "../../../helpers/ColorHelper";
import { LoadingCentered } from "../../../molecules/components";
import { createContext } from "react";
import {
  ForumViewerBlock,
  ResourceNavbar,
  ResourceNotAccessible,
} from "../../../organisms/components";
import { useEffect, useState } from "react";
import {
  useForumTopicsQuery,
  useForumTopicCreateMutation,
  useDeleteForumTopicMutation,
  CourseForumTopicInput,
  ForumTopicCreateMutationOptions,
  DeleteForumTopicMutationOptions,
} from "../../../generated/graphql";
import { NetworkStatus } from "@apollo/client";

export const ForumContext = createContext<{
  createForumTopic: (options: ForumTopicCreateMutationOptions) => void;
  createForumTopicLoading: boolean;

  deleteForumTopic: (options: DeleteForumTopicMutationOptions) => void;
  deleteForumTopicLoading: boolean;

  refetchForum: (variables: { courseUuid: string }) => void;
}>({
  createForumTopic: (options) => {},
  createForumTopicLoading: false,

  deleteForumTopic: (options) => {},
  deleteForumTopicLoading: false,

  refetchForum: (variables) => {},
});

export interface Style {
  base: {
    style: ColorObj;
    fontFamily: string;
  };
  cardHeader: {
    style: ColorObj;
    fontFamily: string;
  };
}

export interface ForumViewerProps {}
export function ForumViewer({}: ForumViewerProps) {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid");

  const { data, loading, error, refetch, networkStatus } = useForumTopicsQuery({
    variables: {
      courseUuid: courseUuid || "",
    },
    notifyOnNetworkStatusChange: true,
  });

  useErrorHandler(error);

  const [createForumTopic, useForumTopicCreateResponse] =
    useForumTopicCreateMutation();

  const [deleteForumTopic, useForumTopicDeleteResponse] =
    useDeleteForumTopicMutation({});

  const courseViewScreen = data?.courseViewScreen;

  if (data && !loading && !data?.courseViewScreen?.course?.isEnrolled) {
    return <ResourceNotAccessible />;
  }

  const courseTitle = data?.courseViewScreen?.course?.title || "Course";

  if (loading && networkStatus === NetworkStatus.loading) {
    return (
      <div data-testid="loading">
        <LoadingCentered />
      </div>
    );
  }

  return (
    <>
      <ResourceNavbar resourceName={`Forum for ${courseTitle}` || ""} />
      <pre>{JSON.stringify(networkStatus.toLocaleString, null, 4)}</pre>

      {loading && networkStatus === NetworkStatus.refetch && (
        <LoadingCentered />
      )}
      <div>
        <ForumContext.Provider
          value={{
            createForumTopic: createForumTopic,
            createForumTopicLoading: useForumTopicCreateResponse.loading,

            deleteForumTopic: deleteForumTopic,
            deleteForumTopicLoading: useForumTopicDeleteResponse.loading,

            refetchForum: refetch,
          }}
        >
          <ForumViewerBlock courseViewScreen={courseViewScreen} />
        </ForumContext.Provider>
      </div>
    </>
  );
}
