import { gql } from "@apollo/client";
import {
  ChangeEventHandler,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import {
  // useResourceStartMutation,
  ResourceContentAssessment,
  ExamSection,
  ExamItem,
  ExamChoice,
  ExamSubmissionInputV2,
  AssessmentViewScreenAssessmentSubmitResultV2,
  ExamSubmission,
  ExamSubmissionResponse,
  CourseBlockUuidUserStatusSubmission,
  CourseBlockUuidUserStatusSubmissionResponse,
  PollResult,
  useGetNextCourseBlocksLazyQuery,
  useScheduleBlockStatusTrainingInfoQuery,
} from "../../../generated/graphql";
import {
  LoadingCentered,
  SubmissionFeedbackV2,
  Timer,
  Toast,
} from "../../../molecules/components";
import { IconButton } from "../../../molecules/components/IconButton";
import {
  AssessmentSectionV2,
  CantNextAssessment,
  CourseBlockOpenModals,
  Modal,
  ResourceCompleteModal,
  SubmitAssessmentV2,
} from "../../../organisms/components";
import { ResourceNavbar } from "../../../organisms/components/ResourceNavbar";
import { CamelCasedType } from "../../../helpers/components/CamelCaseType";
import { CourseBlockV2 } from "../ViewResourceV2";
import { ExamItemType } from "../../../molecules/components/AssessmentItemFeedback";
import { CourseBlock } from "../CourseViewer";
import { Button, Image } from "../../../atoms/components";
import indicatorTrophy from "../../../assets/trophyindicator.png"; // Training Info

export interface AnswerRequiredObject {
  isAnswered: boolean;
  itemUuid: string;
}

// New types

export type ResourceContentAssessmentV2 =
  CamelCasedType<ResourceContentAssessment>;

export type ExamSectionV2 = CamelCasedType<ExamSection>;

export type ExamItemV2 = CamelCasedType<ExamItem>;

export type ExamChoiceV2 = CamelCasedType<ExamChoice>;

export type ExamSubmitInputV2 = CamelCasedType<ExamSubmissionInputV2>;

export type AssessmentResultV2 =
  CamelCasedType<AssessmentViewScreenAssessmentSubmitResultV2>;

export type OutsideCourseResult = CamelCasedType<ExamSubmission>;
export type OutsideCourseResponses =
  CamelCasedType<ExamSubmissionResponse> | null;

export type InsideCourseResult =
  CamelCasedType<CourseBlockUuidUserStatusSubmission>;
export type InsideCourseResponses =
  CamelCasedType<CourseBlockUuidUserStatusSubmissionResponse> | null;

export type PollResultV2 = CamelCasedType<PollResult>;

export interface AssessmentViewerV2Props {
  // Add props and type of the props ng content
  content?: ResourceContentAssessmentV2;
  courseBlock?: CourseBlockV2;
}

export function AssessmentViewerV2({
  content,
  courseBlock,
}: AssessmentViewerV2Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Might not these to find the resources for assessment.
  const blockUuid = searchParams.get("course_block_uuid");
  const scheduleUuid = searchParams.get("course_schedule_uuid");
  const resourceUuid = searchParams.get("resource_uuid") || "";
  const examUuid = searchParams.get("exam_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const parentScreenName = searchParams.get("from_screen");

  const examTimeslotUuid =
    courseBlock?.scheduleReferenceInfo?.referenceId ||
    content?.examDetails?.examTimeslotUuid ||
    "";
  //

  // States, kailangan padin for inside assessment functionalities. eg (modals)
  const [passingMark, setPassingMark] = useState(50);
  const [submit, setSubmit] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [isRetakeOpen, setIsRetakeOpen] = useState(false);
  const [passOnSubmit, setPassOnSubmit] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [formData, setFormData] = useState({});

  const [isRequiredToastOpen, setIsRequiredToastOpen] = useState(false);
  const [isDraftToastOpen, setIsDraftToastOpen] = useState(true);
  const [score, setScore] = useState<{
    maxScore: number;
    totalScore: number;
    resultStatus: "passed" | "failed" | "pending" | undefined;
    canRetake: boolean;
    canGoNext: boolean;
  }>({
    maxScore: 0,
    totalScore: 0,
    resultStatus: undefined,
    canGoNext: false,
    canRetake: true,
  });

  const [results, setResults] = useState<AssessmentResultV2>();
  const [resourceCompleteModalOpen, setResourceCompleteModalOpen] =
    useState(false);
  const [cantGoNextModal, setCantGoNextModal] = useState(false);

  // Training Indicators Earned modal  // Training Info
  const [indicatorsEarnedModalOpen, setIndicatorsEarnedModalOpen] = // Training Info
    useState(false); // Training Info

  const [
    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
  ] = useState(false);
  const [nextCourseBlockUuid, setNextCourseBlockUuid] = useState<string>();
  const [nextResourceUuid, setNextResourceUuid] = useState<string>();

  const chances =
    courseBlock?.scheduleReferenceInfo?.maxAllowedSubmissions ||
    content?.examDetails?.maxAllowedSubmissions;

  const name = courseBlock?.title;
  const timeLimit = content?.examDetails?.examItemSet?.outline?.exam?.timeLimit;
  const sections: (ExamSectionV2 | null)[] | null | undefined =
    content?.examDetails?.examItemSet?.outline?.exam?.sections;

  const [currentSection, setCurrentSection] = useState(
    sections ? sections[0] : null
  );

  // Burecipes

  // Removed Resource Start

  // Training Info from Course Block Status  // Training Info
  const {
    // Training Info
    data: trainingInfoData, // Training Info
    loading: trainingInfoLoading, // Training Info
  } = useScheduleBlockStatusTrainingInfoQuery({
    // Training Info
    variables: {
      // Training Info
      courseBlockUuid: blockUuid || "", // Training Info
      courseUuid: courseUuid || "",
      scheduleUuid: scheduleUuid || "",
    }, // Training Info
  }); // Training Info
  const trainingInfo =
    trainingInfoData?.courseViewScreen?.scheduleBlockStatus?.trainingInfo; // Training Info
  const isTrainingInfo = trainingInfo !== null; // Training Info

  function handleTrainingIndicatorsEarned() {
    // Training Info
    if (isTrainingInfo) {
      // Training Info
      setIndicatorsEarnedModalOpen(true); // Training Info
    } else {
      // Training Info
      setResourceCompleteModalOpen(true); // Training Info
    }
  }

  const longUnitName =
    trainingInfo?.domains?.length &&
    trainingInfo?.domains[0]?.indicators &&
    trainingInfo?.domains[0]?.indicators?.length &&
    trainingInfo?.domains[0].indicators[0]?.displayUnitName
      ? trainingInfo.domains[0].indicators[0]?.displayUnitName
      : "point(s)";

  // onNext
  function courseBlockEnd() {
    if (
      score.canGoNext ||
      (passOnSubmit &&
        !(
          results?.courseBlockSubmissionResult?.status === "draft" ||
          results?.submissionResult?.status === "draft"
        ))
    ) {
      handleTrainingIndicatorsEarned(); // Training Info
    } else {
      setCantGoNextModal(true);
    }
  }
  //

  const changeSection = (event: any) => {
    sections?.forEach((section) => {
      if (section?.uuid === event.target.value) {
        setCurrentSection(section);
      }
    });
  };
  useEffect(() => {
    setCurrentSection(sections ? sections[0] : null);
  }, [sections]);
  // useEffect(() => {
  //   // resourceStartMutateFunction();
  // }, [resourceUuid, blockUuid, scheduleUuid]);

  // Cast navigation inside assessment.
  const navigateHome = () => {
    if (!courseUuid) {
      if (searchParams.get("search_value")) {
        navigate(
          `/screen/search?search_value=${searchParams.get("search_value")}`
        );
      } else {
        navigate(`/screen/${parentScreenName}`);
      }
    } else {
      navigate(
        `/courseviewer?course_uuid=${searchParams.get(
          "course_uuid"
        )}&course_schedule_uuid=${searchParams.get(
          "course_schedule_uuid"
        )}&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`
      );
    }
  };

  // Used to check if all the required items is answered

  const [requiredState, setRequiredState] =
    useState<AnswerRequiredObject[] | null>(null);

  // Initialize values for requiredState

  const [autoPending, setAutoPending] = useState<boolean>(false);

  useEffect(() => {
    if (currentSection?.items) {
      setRequiredState(
        (currentSection?.items || []).map((item): AnswerRequiredObject => {
          return item?.required
            ? (item.draftValue?.choices?.length || 0) > 0 ||
              item.draftValue?.longInput ||
              item.subtype === "DD"
              ? { isAnswered: true, itemUuid: item?.uuid || "" }
              : { isAnswered: false, itemUuid: item?.uuid || "" }
            : { isAnswered: true, itemUuid: "itemNotRequired" };
        })
      );
      // Setting Type State
      setAutoPending(
        currentSection.items.some(
          (item) => item?.type === ExamItemType.LONG_ANSWER && item?.isGraded
        )
      );
    }
  }, [currentSection]);

  const updateRequiredValue = (requiredStateEntry: AnswerRequiredObject) => {
    // Switch isAnswered value true to false and vice versa
    const updatedState = requiredState?.map((stateItem) => {
      // Check for a change in isAnswered value
      if (
        stateItem.itemUuid === requiredStateEntry.itemUuid &&
        stateItem.isAnswered !== requiredStateEntry.isAnswered
      ) {
        return requiredStateEntry;
      } else return stateItem;
    });

    if (updatedState) setRequiredState(updatedState);
  };

  if (!content) {
    return <LoadingCentered />;
  }

  return (
    <div className="relative">
      <ResourceNavbar
        resourceName={name || ""}
        isNextEnabled={!!blockUuid || courseUuid !== null}
        onNext={() => {
          courseBlockEnd();
        }}
        className="h-fit"
      />
      <CourseBlockOpenModals
        allowOpenModal={courseBlockResourceModalsAllowOpen}
        onModalClose={() => setCourseBlockResourceModalsAllowOpen(false)}
        courseBlockUuid={nextCourseBlockUuid}
        courseUuid={courseUuid as string}
        resourceUuid={nextResourceUuid}
        scheduleUuid={scheduleUuid || ""}
      ></CourseBlockOpenModals>

      <form
        aria-label="assessment"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="relative"
      >
        {isRequiredToastOpen ? (
          <Toast
            message="Please complete the required items to proceed with your submission"
            toastStatus="error"
            onClose={() => setIsRequiredToastOpen(false)}
            closeDelay={3000}
          ></Toast>
        ) : (
          <></>
        )}
        <ResourceCompleteModal
          nextBlocks={courseBlock?.nextBlocksJoined as CourseBlock[]}
          isOpen={resourceCompleteModalOpen}
          onRequestClose={() => setResourceCompleteModalOpen(false)}
          onBackToMainPage={() => {
            setResourceCompleteModalOpen(false);
            navigateHome();
          }}
          onBlockClick={(block) => {
            setNextCourseBlockUuid(block.uuid || "");
            setNextResourceUuid(block.resource?.id || "");
            setCourseBlockResourceModalsAllowOpen(true);

            setResourceCompleteModalOpen(false);
          }}
        />
        {/* Training Indicators Earned Modal */}
        <Modal
          className={"w-5/6 md:w-2/5 max-h-5/6 overflow-auto"}
          isOpen={indicatorsEarnedModalOpen}
          onRequestClose={() => setIndicatorsEarnedModalOpen(false)}
        >
          <div className="p-2">
            <div className="w-full">
              <Image
                src={indicatorTrophy}
                height={300}
                alt="Indicator Trophy"
                className="w-full mx-auto"
              />
            </div>
            <div className="my-6 text-2xl font-bold text-center text-gray-700">
              <p>Well done!</p>
              <p className="w-full break-words hyphens-auto">
                You earned{" "}
                <span className="mr-2 text-secondary-500 ">
                  {trainingInfo?.totalPoints} {longUnitName}
                </span>
                for the following Indicators:
              </p>
            </div>

            <div className="flex flex-col w-full ">
              {/* Loop trainingInfo domains */}
              {trainingInfo?.domains?.map((domain) => (
                <div
                  className="items-center justify-between mb-6 space-x-4 "
                  key={domain?.uuid}
                >
                  <div className="flex justify-between w-full space-x-4 text-lg font-bold text-gray-700 align-middle">
                    <p className="w-full">{domain?.name}</p>
                    <p className="w-full font-bold text-right truncate text-secondary-500">
                      {domain?.totalPoints}{" "}
                      {domain?.indicators &&
                      domain?.indicators.length &&
                      domain?.indicators[0]?.displayUnitName
                        ? domain?.indicators[0]?.displayUnitName
                        : "pt(s)"}
                    </p>
                  </div>

                  {/* SUBDOMAINS */}
                  <div className="text-gray-500">
                    {domain?.children?.map((subdomain) => (
                      <ul
                        key={subdomain?.uuid}
                        className="w-full my-3 list-disc "
                      >
                        <li className="w-full ">
                          <div className="flex justify-between w-full space-x-4 text-right">
                            <p className="w-full font-bold text-left">
                              {subdomain?.name}
                            </p>
                            <p className="w-full font-bold text-right truncate">
                              {subdomain?.totalPoints}{" "}
                              {subdomain?.indicators &&
                              subdomain?.indicators.length &&
                              subdomain?.indicators[0]?.displayUnitName
                                ? subdomain?.indicators[0]?.displayUnitName
                                : "pt(s)"}
                            </p>
                          </div>
                          {/* Indicator */}
                          {subdomain?.indicators?.map((indicator) => (
                            <div key={indicator?.uuid} className="">
                              {indicator?.name}
                            </div>
                          ))}
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="w-1/2 mx-auto"
            color="secondary"
            onClick={() => {
              setIndicatorsEarnedModalOpen(false);
              setResourceCompleteModalOpen(true);
            }}
          >
            Continue
          </Button>
        </Modal>
        <Modal
          isOpen={cantGoNextModal}
          onRequestClose={() => setCantGoNextModal(false)}
        >
          <CantNextAssessment
            closeModal={() => setCantGoNextModal(false)}
            hasSubmitted={!!score.resultStatus}
            canRetake={score.canRetake}
            navigateHome={navigateHome}
            submissionStatus={
              results?.submissionResult?.status ||
              results?.courseBlockSubmissionResult?.status ||
              ""
            }
          />
        </Modal>
        {/* {results?.examResults || results?.courseExamResults ? ( */}
        {results?.submissionResult || results?.courseBlockSubmissionResult ? (
          results.courseBlockSubmissionResult?.status === "final" ||
          results.submissionResult?.status === "final" ||
          results.courseBlockSubmissionResult?.status === "in_progress" ||
          results.submissionResult?.status === "in_progress" ? (
            <div className="sticky top-0 z-20">
              <SubmissionFeedbackV2
                passOnSubmit={passOnSubmit}
                results={score.resultStatus}
                totalScore={score.totalScore}
                maxScore={score.maxScore}
                retake={() => setIsRetakeOpen(true)}
                chances={chances}
                passingMark={passingMark}
                submissions={
                  results?.submissionResult?.submissionCount ||
                  results?.courseBlockSubmissionResult?.submissionCount ||
                  undefined
                }
              />
            </div>
          ) : isDraftToastOpen ? (
            <Toast
              title="Draft Saved"
              message="Your answers will still be here when you revisit this assessment"
              toastStatus="success"
              closeDelay={3000}
              onClose={() => setIsDraftToastOpen(false)}
            />
          ) : (
            ""
          )
        ) : timeLimit ? (
          <div className="sticky top-0 flex justify-center py-2 bg-info-100 ">
            <Timer
              timeLimit={timeLimit}
              action={() => setSubmit(true)}
              stopTimer={stopTimer}
            ></Timer>
          </div>
        ) : (
          <></>
        )}
        <AssessmentSectionV2
          section={currentSection ? currentSection : {}}
          formData={setFormData}
          responses={
            (results?.submissionResult
              ?.responses as OutsideCourseResponses[]) ||
            (results?.courseBlockSubmissionResult
              ?.responses as InsideCourseResponses[])
          }
          submissionStatus={
            results?.courseBlockSubmissionResult?.status ||
            results?.submissionResult?.status ||
            ""
          }
          updateRequiredValue={updateRequiredValue}
        />
        <SubmitAssessmentV2
          setPassOnSubmit={setPassOnSubmit}
          setPassingMark={setPassingMark}
          maxScore={score.maxScore}
          totalScore={score.totalScore}
          courseUuid={courseUuid || ""}
          scheduleUuid={scheduleUuid || ""}
          timeLimit={timeLimit}
          blockUuid={blockUuid || ""}
          resourceUuid={resourceUuid || ""}
          formData={formData}
          examTimeslotUuid={examTimeslotUuid}
          setStopTimer={setStopTimer}
          setScore={setScore}
          setResults={setResults}
          score={score}
          results={results || {}}
          isRetakeOpen={isRetakeOpen}
          setIsRetakeOpen={setIsRetakeOpen}
          submitExam={submit}
          setSubmit={setSubmit}
          chances={chances}
          submissions={
            results?.submissionResult?.submissionCount ||
            results?.courseBlockSubmissionResult?.submissionCount ||
            undefined
          }
          submissionUuid={content.examDetails?.examItemSet?.submission?.uuid}
          courseBlock={courseBlock || {}}
          autoPending={autoPending}
          requiredState={requiredState}
          setIsRequiredToastOpen={setIsRequiredToastOpen}
          submissionStatus={
            results?.courseBlockSubmissionResult?.status ||
            results?.submissionResult?.status ||
            ""
          }
        />
      </form>
    </div>
  );
}

export function SectionChooser({
  changeSection,
  sections,
}: {
  changeSection: ChangeEventHandler<HTMLSelectElement>;
  sections: any[];
}) {
  return (
    <div className="flex justify-between py-8 mx-2 border-t-2 border-gray-400 sm:mx-32">
      <IconButton
        type="button"
        icon="chevron_left"
        text="previous"
        color="mutedInverted"
      />
      <select
        onChange={changeSection}
        name="sections"
        id="sections"
        className="justify-center w-2/3 p-2 align-middle border border-gray-400 rounded"
      >
        {sections?.map((section, index) => {
          return (
            <option key={index} value={section?.uuid as string}>
              {section?.title}
            </option>
          );
        })}
      </select>
      <IconButton
        type="button"
        iconPosition="right"
        icon="chevron_right"
        color="mutedInverted"
        text="next"
      />
    </div>
  );
}
