import React from "react";
import { Icon, Button } from "../../../atoms/components";

export interface CantNextXapiProps {
  prop?: string;
  closeModal?: () => any;
}

export function CantNextXapi({
  prop = "default value",
  closeModal,
}: CantNextXapiProps) {
  return (
    <div className="text-center">
      <span className="text-[60px] text-error-500">
        <Icon name="dangerous"></Icon>
      </span>
      <h1 className="text-2xl font-bold my-5">
        You Can&apos;t Go Past This Resource
      </h1>
      <p className="">Please complete and pass this xApi resource to proceed</p>
      <Button
        color="secondaryOutline"
        onClick={closeModal}
        className="w-full my-5 hover:bg-secondary-500 hover:text-white"
      >
        Back to Resource
      </Button>
    </div>
  );
}
