import { useNavigate } from "react-router";
import { Button, Image } from "../../../atoms/components";
import { randomBuriDog } from "../../../helpers/components";
export interface ResourceNotAccessibleProps {
  prop?: string;
}

export function ResourceNotAccessible({
  prop = "default value",
}: ResourceNotAccessibleProps) {
  const navigate = useNavigate();
  return (
    <div className="h-screen flex items-center">
      <div className="flex flex-col items-center mx-auto">
        <Image
          src={randomBuriDog()}
          className="w-2/3 h-[50vh] max-h-[500px] "
        />
        {/* Instead of using &#  */}
        <h1 className="text-3xl text-primary-500 font-bold">Sorry :&#40;</h1>
        <h1 className="text-3xl text-primary-500 font-bold mb-4">
          You Don&apos;t Have Access to This Resource
        </h1>
        <p className="w-2/3 mx-auto mb-6 text-center">
          This may be due to direct link, try looking for this resource inside
          the course you&apos;re trying to finish
        </p>
        <Button
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          Back to Home Page
        </Button>
      </div>
    </div>
  );
}
