import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useState,
  useContext,
} from "react";
import { Icon, InputText } from "../../../atoms/components";

import { ProfileIconFromText } from "../../../organisms/components/Navbar";
import { useCreateCommentMutation } from "../../../generated/graphql";
import { gql } from "@apollo/client";
import { LoadingCentered } from "../LoadingCentered";

export const CreateCommentMutation = gql`
  mutation CreateComment($forumTopicUuid: ID!, $text: String!) {
    courseForumCommentCreate: course_forum_comment_create(
      forum_topic_uuid: $forumTopicUuid
      text: $text
    ) {
      comment {
        uuid
      }
    }
  }
`;

export interface CommentInputProps {
  topicUuid: string;
  lastName: string;
  firstName: string;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
  refetchComments: () => void;
}

export function CommentInput({
  topicUuid,
  lastName,
  firstName,
  setToastOpen,
  setToastAction,
  refetchComments,
}: CommentInputProps) {
  const [text, setText] = useState<string>("");

  const handleTextChange = (e: SyntheticEvent) => {
    const newText = (e.target as HTMLInputElement).value;
    setText(newText);
  };

  const [createComment, { data, loading, error }] = useCreateCommentMutation({
    variables: {
      text: text || "",
      forumTopicUuid: topicUuid || "",
    },
  });

  const post = () => {
    createComment({
      variables: {
        text: text || "",
        forumTopicUuid: topicUuid || "",
      },
    }).then(({ data }) => {
      setText("");
      setToastAction("create");
      refetchComments();
      setToastOpen(true);
    });
  };

  return (
    <div className="flex items-center pb-6 space-x-2 border-b-2">
      {loading && <LoadingCentered />}
      {/*Profile pic*/}
      <div className="shrink-0">
        <ProfileIconFromText
          text={
            (firstName !== "DEPRECATED - use traits.given_name"
              ? firstName[0]
              : "Guest" || "") +
            " " +
            (lastName !== "DEPRECATED - use traits.family_name"
              ? lastName[0]
              : "Guest" || "")
          }
        ></ProfileIconFromText>
      </div>
      <InputText
        onChange={handleTextChange}
        value={text}
        data-testid="comment-input"
      ></InputText>
      <button
        type="button"
        onClick={post}
        disabled={text === ""}
        className="bg-primary-500 text-white hover:bg-primary-600 focus:bg-primary-600 py-1.5 px-2.5 rounded-md"
      >
        <Icon name="edit" className="text-md"></Icon>
      </button>
    </div>
  );
}
