import React, { useState } from "react";

export const useTimeElapsed = () => {
  const [startDate, setStartDate] = useState(Date.now());

  const reset = () => {
    setStartDate(Date.now());
  };

  const getTimeElapsedMs = () => {
    return Date.now() - startDate;
  };

  return { reset, getTimeElapsedMs };
};
