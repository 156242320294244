import {
  ConfirmationForm,
  Footer,
  NeedHelp,
} from "../../../molecules/components";
import { SignupLayout } from "../../../templates/components";

export interface ConfirmationProps {
  prop?: string;
}

export function Confirmation({ prop = "default value" }: ConfirmationProps) {
  return (
    <SignupLayout
      _type="confirmation"
      footer={<Footer _type="signup" />}
      signupform={<ConfirmationForm />}
      help={<NeedHelp _type="signup" />}
    ></SignupLayout>
  );
}
