import React from "react";
import "./ProgressBar.css";
import { twMerge } from "tailwind-merge";

export interface ProgressBarProps {
  value: number;
  max: number;
  className?: string;
}

export function ProgressBar({ value, max, className }: ProgressBarProps) {
  return (
    // <div className="h-2 bg-white rounded-md will-change-auto "></div>
    <progress
      className={twMerge(`w-full h-2 ${className}`)}
      value={value}
      max={max}
    ></progress>
  );
}
