import React from "react";

const PillStatus = new Map([
  // Types
  ["passed", "success"],
  ["on_going", "success"],
  ["failed", "error"],
  ["in_progress", "info"],
  ["info", "info"],
  ["info_inverted", "info_inverted"],
  ["needs_attention", "warning"],
  ["scheduled", "warning"],
  ["done", "neutral"],
  ["not_started", "invisible"],
  ["tag", "primary"],
  ["schedule_on_going", "info"],
  ["DONE", "neutral"],
  ["SCHEDULED", "warning"],
  ["ONGOING", "info"],
  ["neutral", "neutral"],
]);

export interface PillLabelProps {
  children?: React.ReactNode;
  className?: string;
  status?: string | boolean;
}

const handlePill = (status: string) => {
  let classText = "inline-block py-1 px-2 w-fit ";
  switch (status) {
    case "success":
      classText += "bg-success-100 text-success-700 ";
      break;
    case "error":
      classText += "bg-error-100 text-error-700 ";
      break;
    case "info":
      classText += "bg-info-100 text-info-700 ";
      break;
    case "info_inverted":
      classText += "bg-info-600 text-info-100 ";
      break;
    case "invisible":
      classText += "invisible ";
      break;
    case "warning":
      classText += "bg-warning-100 text-warning-700 ";
      break;
    case "primary":
      classText +=
        "bg-primary-200 font-semibold text-primary-500 rounded !rounded-md ";
      break;
    case "neutral":
      classText += "bg-slate-200 font-semibold text-slate-500 rounded-md ";
      break;
  }
  return classText;
};

export function PillLabel({
  children,
  className = "",
  status = "",
}: PillLabelProps) {
  const statusValue = PillStatus.get(status as string);
  return (
    <p
      className={
        handlePill(statusValue as string) +
        className +
        "text-sm py-0.5 px-2 rounded-xl"
      }
    >
      {children}
    </p>
  );
}
