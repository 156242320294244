import { useState, useEffect } from "react";
import { ExamResponseInput } from "../../../generated/graphql";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
} from "../../../pages/components/GbfSlide";
import {
  Image,
  Icon,
  ProgressBar,
  InputTextArea,
} from "../../../atoms/components";
import { parseColorToStyle } from "../../../helpers/ColorHelper";
import { twMerge } from "tailwind-merge";

export function PollSlideActivity({
  examItem,
  handleResponse,
  savedResponses,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  const [selected, setSelected] = useState(
    getDefaultValue({
      examItem,
      savedResponses,
    })
  );

  const [textToDisplay, setTextToDisplay] = useState<string>(
    examItem.choices?.[0]?.longInput || ""
  );
  const [showDropdown, setShowDropdown] = useState(false);

  // Survey States
  const previousChoiceUuid = savedResponses.find(
    (response) => response.item === examItem.uuid
  )?.choices?.[0];
  const [selectedIndex, setSelectedIndex] = useState<number>(
    (examItem.choices?.findIndex(
      (choice) => choice?.uuid === previousChoiceUuid
    ) || 0) + 1 || 0
  );
  const [comment, setComment] = useState(
    rightPageFeedback?.longInput ||
      savedResponses.find((response) => response.item === examItem.uuid)
        ?.long_input ||
      ""
  );

  // Color

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    const response: ExamResponseInput = {
      item: examItem.uuid,
      choices: selected ? [selected as string] : [],
    };

    const surveyResponse: ExamResponseInput = {
      item: examItem.uuid,
      choices: selected ? [selected as string] : [],
      long_input: comment,
    };

    handleResponse(examItem.subtype !== "SU" ? response : surveyResponse);
  }, [selected, comment]);

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );
  return (
    <div className="flex w-full h-fit">
      {examItem.subtype !== "SU" ? (
        <div className="w-full">
          <QuestionHeader text={examItem.text || ""} style={textStyle} />
          {!hasAnswered ? (
            examItem.subtype !== "DD" ? (
              examItem.choices?.map((choice) => {
                return (
                  <button
                    key={choice?.uuid}
                    onClick={() => setSelected(choice?.uuid || "")}
                    className={twMerge(
                      `block w-full text-left text-lg p-4 mb-4 rounded-3xl  border-gray-700 border-2 transition-all  ${
                        selected === choice?.uuid
                          ? "bg-gray-200 active:bg-gray-300 text-gray-700"
                          : "bg-white hover:bg-gray-300 active:bg-gray-400 "
                      } ${hasAnswered ? "cursor-not-allowed " : ""}`
                    )}
                    disabled={hasAnswered}
                  >
                    {/* <input type="radio" /> */}
                    <p style={textStyle}>{choice?.longInput} </p>
                    {choice?.imageUrl || choice?.mediaUrl ? (
                      <Image
                        src={choice.imageUrl || choice.mediaUrl || ""}
                        className="mx-auto mb-4 max-h-64"
                      ></Image>
                    ) : (
                      <></>
                    )}
                    {choice?.audioUrl ? (
                      <audio controls className="w-full ">
                        <source src={choice.audioUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    ) : (
                      <></>
                    )}
                  </button>
                );
              })
            ) : (
              <button
                className={twMerge(
                  `bg-white text-lg text-left w-full h-fit p-4 mb-4 rounded-3xl border-gray-700 border-2 ${
                    showDropdown ? "max-h-[9999px]" : "max-h-40 overflow-hidden"
                  } transition-all ${hasAnswered ? "cursor-not-allowed " : ""}`
                )}
                onClick={() => setShowDropdown((prev) => !prev)}
                disabled={hasAnswered}
              >
                <div className="flex justify-between">
                  <span
                    className={` border-black h-fit line-clamp-2 flex-1 ${
                      showDropdown ? "border-b-2 mb-4" : ""
                    }`}
                    style={textStyle}
                  >
                    {textToDisplay}
                  </span>
                  <Icon name="expand_more" />
                </div>

                {showDropdown ? (
                  <div className={`h-fit transition-all duration-300 ease-in `}>
                    {examItem.choices?.map((choice, index) => {
                      const isLastItem =
                        index === (examItem.choices?.length as number) - 1;
                      return (
                        <div
                          key={choice?.uuid}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelected(choice?.uuid || "");
                            setTextToDisplay(choice?.longInput || "");
                            setShowDropdown(false);
                          }}
                          className={`hover:bg-gray-300 p-1 rounded transition-colors ease-in  border-gray-200 ${
                            !isLastItem ? "border-b-2" : " "
                          }`}
                          style={textStyle}
                        >
                          {choice?.longInput}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="transition-transform duration-300 transform -translate-y-2 opacity-0"></div>
                )}
              </button>
            )
          ) : (
            <div>
              {rightPageFeedback?.pollResults?.map((pollResult, index) => {
                return (
                  <div key={index}>
                    <p
                      className={`w-full py-5 px-8 rounded-full text-lg text-gray-700 border-2 border-black ${
                        pollResult?.choice?.uuid ===
                        getDefaultValue({
                          examItem,
                          savedResponses,
                        })
                          ? "bg-gray-300"
                          : "bg-white "
                      }`}
                      style={textStyle}
                    >
                      {pollResult?.choice?.longInput}
                    </p>
                    <ProgressBar
                      value={pollResult?.result?.percent || 0}
                      max={100}
                    />
                    <div className="flex justify-between mb-4 text-xs text-primary-500">
                      <p>
                        {pollResult?.result?.count === 0
                          ? "No"
                          : pollResult?.result?.count}{" "}
                        vote{`(s)`}
                      </p>
                      <p className="font-bold">
                        {pollResult?.result?.percent}%
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        // Survey
        <div
          className={`flex flex-col items-center h-fit w-full m-auto justify-center space-y-10 z-10 ${
            hasAnswered ? "hidden" : ""
          }`}
        >
          <h1
            className="z-10 px-5 py-2 text-2xl font-bold text-gray-700 bg-white rounded-2xl"
            style={textStyle}
          >
            {examItem.text || ""}
          </h1>
          <div className="z-10 space-x-10 text-4xl text-center transition-all w-fit">
            {examItem.choices?.map((choice, index) => {
              if (index < 5)
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index + 1);
                      setSelected(choice?.uuid || "");
                    }}
                    className="disabled:cursor-not-allowed "
                    disabled={hasAnswered}
                  >
                    {(selectedIndex || 0) > index ? (
                      <Icon
                        className="text-4xl animate-fadeIn text-primary-600 lg:h-14 md:text-5xl lg:text-6xl"
                        name="star"
                      />
                    ) : (
                      <Icon
                        className="text-4xl text-primary-600 margin-0 lg:h-14 md:text-5xl lg:text-6xl"
                        name="star_outline"
                      />
                    )}
                  </button>
                );

              return <div key={index}></div>;
            })}
          </div>
          <div className="z-10 w-full text-center">
            <h2
              className="mb-10 text-2xl font-bold text-gray-700"
              style={textStyle}
            >
              {examItem.instructions || "Any Comments?"}
            </h2>
            <InputTextArea
              placeholder="Type in answer..."
              value={comment || ""}
              className="w-full border border-gray-700"
              disabled={hasAnswered}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
