export interface FooterProps {
  _type: "login" | "signup";
}

export function Footer({ _type = "login" }: FooterProps) {
  return (
    <footer
      className={` flex items-center justify-center w-full ${
        _type === "login" ? "sm:justify-start sm:items-center" : "sm:mb-10"
      }`}
    >
      <div>
        <img
          src="assets/default-logo.png"
          alt="footer logo"
          className={`w-20 mx-auto ${_type === "login" ? "sm:mx-0" : ""}`}
        />
        <div className="text-center">
          <span className="text-gray-500">Powered by &nbsp;</span>
          <span className="text-primary-700">Buri.io</span>
        </div>
      </div>
    </footer>
  );
}
