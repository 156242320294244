import { useEffect, useState } from "react";
import {
  Image,
  InputText,
  InputDate,
  Markdown,
} from "../../../atoms/components";
import {
  AnswerRequiredObject,
  InsideCourseResponses,
  OutsideCourseResponses,
  ExamItemV2,
} from "../../../pages/components/AssessmentViewerV2";
import { Icon } from "../../../atoms/components";

export interface FillInTheBlanksItemProps {
  item?: ExamItemV2;
  formData?: (data: any) => void;
  inputDisabled?: boolean;
  updateRequiredValue?: (requiredStateEntry: AnswerRequiredObject) => void;
}

export function FillInTheBlanksItem({
  item,
  formData = () => {},
  inputDisabled,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
}: FillInTheBlanksItemProps) {
  const [inputValue, setInputValue] = useState(
    item?.draftValue?.longInput || null
  );
  useEffect(() => {
    if (item?.draftValue?.longInput)
      formData((values: any) => ({
        ...values,
        [item?.uuid || ""]: inputValue || "",
      }));
    if (item?.required) {
      updateRequiredValue({
        isAnswered: inputValue !== "",
        itemUuid: item.uuid || "",
      });
    }
  }, []);

  useEffect(() => {
    if (item?.required) {
      updateRequiredValue({
        isAnswered: inputValue !== "",
        itemUuid: item.uuid || "",
      });
    }
  }, [inputValue]);

  const onInputChange = (event: any) => {
    setInputValue(event.target.value);
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: event.target.value,
    }));
  };
  return (
    <div className="mb-8">
      <div className="mb-2 text-lg font-bold">
        <span className="text-error-500">{item?.required ? "* " : ""}</span>
        <Markdown text={item?.text || ""} />
      </div>
      <div className={`py-4 ${item?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={item?.mediaUrl || ""}
          alt={item?.mediaUrl || ""}
          className={`w-full min-h-40 max-h-[35rem] object-contain bg-opacity-0`}
        />
      </div>

      {item?.subtype !== "DT" ? (
        <InputText
          value={inputValue || ""}
          onChange={onInputChange}
          disabled={inputDisabled}
        />
      ) : (
        <InputDate
          value={inputValue || ""}
          onChange={onInputChange}
          disabled={inputDisabled}
        />
      )}
    </div>
  );
}
