import { useEffect, useState } from "react";
import { Image, Markdown } from "../../../atoms/components";
import {
  AnswerRequiredObject,
  ExamItemV2,
  InsideCourseResponses,
  OutsideCourseResponses,
} from "../../../pages/components/AssessmentViewerV2";

export interface TallyItemProps {
  item?: ExamItemV2;
  formData?: (data: any) => void;
  inputDisabled?: boolean;
  showResults?: boolean;
  updateRequiredValue?: (requiredStateEntry: AnswerRequiredObject) => void;
}

export function TallyItem({
  item,
  formData = () => {},
  inputDisabled,
  showResults,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
}: TallyItemProps) {
  const [selected, setSelected] = useState(
    item?.draftValue?.choices?.[0]?.uuid
  );

  useEffect(() => {
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: [selected] || [],
    }));
  }, []);

  useEffect(() => {
    if (item?.required) {
      updateRequiredValue({
        isAnswered: selected !== null,
        itemUuid: item?.uuid || "",
      });
    }
  }, [selected]);

  const onValueSelect = (event: any) => {
    setSelected(event.target.value);
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: [event.target.value],
    }));
  };

  return (
    <div className="mb-16">
      <div className="text-lg font-bold">
        <span className="text-error-500">{item?.required ? "* " : ""}</span>
        <Markdown text={item?.text || ""} />
      </div>
      <div className={`py-4 ${item?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={item?.mediaUrl || ""}
          alt={item?.mediaUrl || ""}
          className={`w-full h-40 object-contain bg-opacity-0`}
        />
      </div>

      {item?.choices?.map((choice) => {
        if (choice)
          return (
            <div key={choice.uuid} className="my-8">
              <input
                className=" focus:border-primary-300 focus:ring-primary-300 text-primary-600"
                disabled={inputDisabled}
                type="radio"
                checked={selected === choice.uuid}
                onChange={onValueSelect}
                value={choice.uuid || ""}
                id={choice.uuid || ""}
              />
              <label htmlFor={choice.uuid || ""} className="ml-2">
                {choice.longInput || choice.shortInput}
              </label>
              <Image
                src={choice.imageUrl || choice.mediaUrl || ""}
                alt={choice.imageUrl || choice.mediaUrl || ""}
                className={`w-full object-contain h-24 bg-opacity-0 mt-4 ${
                  choice.imageUrl || choice.mediaUrl ? "" : "hidden"
                }`}
              />
            </div>
          );
      })}
    </div>
  );
}
