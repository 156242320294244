import { Iframe } from "../../../atoms/components";
import { NavBarLayout } from "../../../templates/components";

export function Diksiyonaryo() {
  return (
    <NavBarLayout>
      <Iframe src="https://diksi.buri.io"></Iframe>;
    </NavBarLayout>
  );
}
