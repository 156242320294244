import { useState } from "react";
import { Icon } from "../../../atoms/components";

import { ForumTopic, PassUserProfile } from "../../../generated/graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommentsArea } from "../CommentsArea/CommentsArea";
import { Toast } from "../../../molecules/components";

export interface TopicLayoutProps {
  topic: ForumTopic;
  courseUuid: string;
  userProfile: PassUserProfile;
}

export function TopicLayout({
  topic,
  courseUuid,
  userProfile,
}: TopicLayoutProps) {
  const navigate = useNavigate();
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastAction, setToastAction] = useState<string>("");
  const [searchParams] = useSearchParams();
  const fromScreen = searchParams.get("from_screen");
  const topicUuid = searchParams.get("topic_uuid");
  return (
    <div>
      {toastOpen ? (
        toastAction == "delete" ? (
          <Toast
            title="Comment Deleted"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : toastAction == "create" ? (
          <Toast
            title="Comment Posted"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : toastAction == "flag" ? (
          <Toast
            title="Comment Flagged"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : (
          <Toast
            title="Comment Unflagged"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        )
      ) : (
        <></>
      )}
      <div className="pt-8 space-y-6">
        <button
          type="button"
          onClick={() => {
            navigate(
              `/forumviewer?course_uuid=${courseUuid}&topic_uuid=${topicUuid}&from_screen=${fromScreen}&resource_uuid=na`
            );
          }}
          className="w-5 h-5 text-white bg-gray-400 rounded-md hover:bg-gray-700"
        >
          <Icon name="chevron_left" className="text-sm"></Icon>
        </button>
        {/* <span className="hidden md:block">&nbsp;Back</span> */}
        <h1 className="text-2xl font-black text-gray-600">
          {/* {query.get("course_uuid")} */}
          {topic.title || ""}
        </h1>
        <p>{topic.text || ""}</p>
      </div>
      {/* Comments container */}
      <CommentsArea
        courseUuid={courseUuid}
        topicUuid={topic.uuid || ""}
        setToastOpen={setToastOpen}
        setToastAction={setToastAction}
        userProfile={userProfile}
      />
    </div>
  );
}
