import React from "react";
import { ReactComponent as TrophyLogo } from "../../../assets/trophy.svg";
import { ReactComponent as CertificateLogo } from "../../../assets/certificate.svg";
import castPetKiwi from "../../../assets/castPetDoggerino.png";
import { Icon, Button } from "../../../atoms/components";
import { Link, useSearchParams } from "react-router-dom";
import { gql } from "@apollo/client";
import { useCertificateViewScreenQuery } from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { LoadingCentered } from "../../../molecules/components";
import { Image } from "../../../atoms/components";
// import CertificateView

export interface ViewCertificateProps {
  prop?: string;
}

export function ViewCertificate({
  prop = "default value",
}: ViewCertificateProps) {
  const adminMessage = "Good natapos mo rin nice nice Lorem Lorem Lorem";
  const [searchParams] = useSearchParams();

  const courseUuid = searchParams.get("course_uuid");
  const scheduleUuid = searchParams.get("course_schedule_uuid");

  const { data, loading, error } = useCertificateViewScreenQuery({
    variables: {
      courseUuid: courseUuid || "",
      scheduleUuid: scheduleUuid || "",
    },
  });

  useErrorHandler(error);
  if (loading) {
    return <LoadingCentered />;
  }

  const learnerCourseStatus = data?.courseViewScreen?.learnerCourseStatus;
  const courseCertUri =
    data?.courseViewScreen?.learnerCourseStatus?.courseCertificateUrl;

  return (
    <div className="h-screen">
      {!courseCertUri ? (
        <div className="flex flex-col items-center p-9">
          <Link
            to={{ pathname: "/courseviewer", search: searchParams.toString() }}
            className="self-start"
          >
            <Icon name="close"></Icon>
          </Link>
          <Image
            className="w-full h-full mb-8 md:w-1/3 md:h-64"
            src={castPetKiwi}
          ></Image>

          <h1 className="mb-3 text-3xl font-black text-primary-600">
            Course Was Not Complete!
          </h1>
          <p className="mb-8 text-gray-500">
            Please complete the course to be eligible for this certificate.
          </p>

          <Link
            to={{ pathname: "/courseviewer", search: searchParams.toString() }}
            className="w-full md:w-1/2 lg:w-1/3"
          >
            <Button className="w-full"> Back to Main Page</Button>
          </Link>
        </div>
      ) : (
        <div className="box-border flex flex-col items-center h-full p-9">
          <Link
            to={{ pathname: "/courseviewer", search: searchParams.toString() }}
            className="self-start"
          >
            <Icon name="close"></Icon>
          </Link>
          <TrophyLogo className="flex-1 w-full h-full" />

          <h1 className="mb-3 text-3xl font-black text-primary-600">
            Course Complete!
          </h1>
          <p className="mb-8 text-gray-500">
            Congratulations! You have completed the course!
          </p>

          {learnerCourseStatus?.courseCertificateEndMessage !== "" &&
          learnerCourseStatus?.courseCertificateEndMessage !== null ? (
            <div className="mb-8">
              <p className="mx-auto mb-3 text-gray-500 w-fit">
                Here&apos;s a message from your Admin:{" "}
              </p>

              {/* admin message goes here */}
              <p className="max-w-3xl p-8 mx-auto break-words rounded w-fit bg-secondary-100 text-secondary-700">
                {learnerCourseStatus?.courseCertificateEndMessage}
              </p>
            </div>
          ) : (
            ""
          )}

          {learnerCourseStatus?.courseCertificateUrl ? (
            <div className="flex flex-col items-center w-full ">
              <p className="mb-3 text-gray-500">
                and here&apos;s your certificate!
              </p>
              <CertificateLogo className="w-full h-full max-h-64 " />
              {/* Download link */}
              <a
                href={learnerCourseStatus?.courseCertificateUrl as string}
                className="mb-8 underline text-primary-600"
              >
                <Icon name="download" className="mr-2"></Icon>
                <span>Download Certificate</span>
              </a>{" "}
            </div>
          ) : (
            ""
          )}

          <Link
            to={{ pathname: "/courseviewer", search: searchParams.toString() }}
            className="w-full md:w-1/2 lg:w-1/3"
          >
            <Button className="w-full"> Back to Main Page</Button>
          </Link>
        </div>
      )}
    </div>
  );
}
