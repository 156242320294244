import { Icon, Button } from "../../../atoms/components";

export interface CantNextAssessmentProps {
  prop?: string;
  hasSubmitted?: boolean;
  closeModal?: () => any;
  canRetake?: boolean;
  navigateHome?: () => void;
  submissionStatus?: string;
}

export function CantNextAssessment({
  prop = "default value",
  hasSubmitted = false,
  canRetake = true,
  closeModal = () => {},
  navigateHome = () => {},
  submissionStatus = "",
}: CantNextAssessmentProps) {
  if (hasSubmitted && submissionStatus === "draft") {
    return (
      <div className="text-center">
        <span className="text-[60px] text-error-500">
          <Icon name="incomplete_circle"></Icon>
        </span>
        <h1 className="my-5 text-2xl font-bold">Assessment Saved as Draft</h1>
        <p className="">
          Redo the assessment and finalize your submission to proceed to the
          next resource.
        </p>
        <Button
          color="secondaryOutline"
          onClick={closeModal}
          className="w-full my-5 hover:bg-secondary-500 hover:text-white"
        >
          Back to Assessment
        </Button>
      </div>
    );
  }

  return (
    <div>
      {hasSubmitted ? (
        canRetake ? (
          <div>
            <div className="text-center">
              <span className="text-[60px] text-error-500">
                <Icon name="dangerous"></Icon>
              </span>
              <h1 className="my-5 text-2xl font-bold">
                You Failed This Assessment
              </h1>
              <p className="">
                Please retake and pass this assessment to proceed to the next
                resource.
              </p>
              <Button
                color="secondaryOutline"
                onClick={closeModal}
                className="w-full my-5 hover:bg-secondary-500 hover:text-white"
              >
                Back to Assessment
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-center">
              <span className="text-[60px] text-error-500">
                <Icon name="dangerous"></Icon>
              </span>
              <h1 className="my-5 text-2xl font-bold">Access Denied</h1>
              <p className="max-w-3xl">
                You have reached the maximum number of attempts. Please contact
                your administrator for further assistance.
              </p>
              <Button
                color="secondaryOutline"
                onClick={() => {
                  closeModal();
                  navigateHome();
                }}
                className="w-full my-5 hover:bg-secondary-500 hover:text-white"
              >
                Back to Course
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className="text-center">
          <span className="text-[60px] text-error-500">
            <Icon name="incomplete_circle"></Icon>
          </span>
          <h1 className="my-5 text-2xl font-bold">Assessment Not Completed</h1>
          <p className="">
            Please submit your assessment to proceed to the next resource.
          </p>
          <Button
            color="secondaryOutline"
            onClick={closeModal}
            className="w-full my-5 hover:bg-secondary-500 hover:text-white"
          >
            Back to Assessment
          </Button>
        </div>
      )}
    </div>
  );
}
