import React from "react";
import { Loading } from "../../../atoms/components";
import { twMerge } from "tailwind-merge";

export interface LoadingCenteredProps {
  // Sets opacity value e.g: bg-white/50
  opacity?: number;
  position?: string;
}

export function LoadingCentered({
  opacity = 30,
  position = "absolute",
}: LoadingCenteredProps) {
  // DO NOT REMOVE, TAILWIND PURPOSES
  // bg-white/30

  return (
    <div
      className={twMerge(
        `${position} backdrop-blur-sm inset-0 z-50 flex items-center animation-fade-in justify-center bg-white/${opacity} `
      )}
    >
      <div className="w-40 h-40">
        <Loading></Loading>
      </div>
    </div>
  );
}
