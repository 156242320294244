export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export function Image(props: ImageProps) {
  return (
    <img
      loading="lazy"
      {...props}
      className={"object-contain " + props.className}
    />
  );
}
