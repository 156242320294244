import { useEffect, useState } from "react";
import { Icon, PillLabel, Button, Loading } from "../../../atoms/components";
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
  CourseSchedule as CourseScheduleOriginal,
  useSelfEnrollSchedulesLazyQuery,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";

type CourseSchedule = CamelCasedType<CourseScheduleOriginal>;

const GET_COURSE_UUID = gql`
  query ResourceViewScreenCourseCardChooseBatch($resourceUuid: ID!) {
    resourceViewScreen: resource_view_screen(resource_uuid: $resourceUuid) {
      resource {
        content {
          ... on ResourceContentCourse {
            courseDetails: course_details {
              courseUuid: course_uuid
            }
          }
        }
      }
    }
  }
`;

const SELF_ENROLL_MUTATION = gql`
  mutation Self_enroll($courseScheduleUuid: String!) {
    self_enroll(course_schedule_uuid: $courseScheduleUuid)
  }
`;

export interface CourseCardChooseBatchProps {
  resourceUuid: string;
}

export function CourseCardChooseBatch({
  resourceUuid,
}: CourseCardChooseBatchProps) {
  const [radioIndex, setRadioIndex] = useState(-1);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const pathArray = window.location.pathname.split("/");
  const parentScreenName = pathArray.pop();

  // Get Course uuid
  const { loading, data, error } = useQuery(GET_COURSE_UUID, {
    variables: {
      resourceUuid: resourceUuid as string,
    },
  });

  useErrorHandler(error);

  const [
    loadSchedule,
    { data: scheduleData, error: scheduleError, loading: scheduleLoading },
  ] = useSelfEnrollSchedulesLazyQuery();

  useEffect(() => {
    if (!loading) {
      const courseUuid =
        data?.resourceViewScreen.resource.content.courseDetails.courseUuid;
      loadSchedule({
        variables: {
          courseUuid: courseUuid,
        },
      });
    }
  }, [loading]);

  // Mutation

  const [
    mutateSelfEnroll,
    { data: mutateData, loading: mutateLoading, error: mutateError },
  ] = useMutation(SELF_ENROLL_MUTATION, {
    onCompleted: () => {
      navigate(
        `/view?resource_uuid=${resourceUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`
      );
    },
  });

  useErrorHandler(mutateError);

  const [scheduleUuid, setScheduleUuid] = useState<string>();
  const updateRadioIndex = (i: number) => {
    setRadioIndex(i);
  };

  const updateScheduleUuid = (uuid: string) => {
    setScheduleUuid(uuid);
  };
  return (
    <div className="flex flex-col items-center gap-2 p-4 max-h-[75vh]">
      <h2 className="mb-3 text-xl font-extrabold text-gray-900">
        Choose Batch
      </h2>

      {scheduleLoading && <Loading />}
      <div className="w-full h-full mb-8 space-y-2 overflow-y-auto">
        {scheduleData?.courseViewScreen?.course?.selfEnrollSchedules?.map(
          (batch, i: number) => (
            <EnrollmentCard
              key={batch?.uuid}
              batch={batch}
              radioIndex={radioIndex}
              updateRadioIndex={updateRadioIndex}
              index={i}
              updateScheduleUuid={updateScheduleUuid}
            />
          )
        )}
      </div>

      <Button
        onClick={() => {
          mutateSelfEnroll({
            variables: { courseScheduleUuid: scheduleUuid },
          });
        }}
        disabled={!scheduleUuid}
        color="secondaryDark"
        className="absolute bottom-0 w-full rounded-t-none"
      >
        Confirm
      </Button>
    </div>
  );
}

function EnrollmentCard({
  batch,
  updateRadioIndex,
  radioIndex,
  index,
  updateScheduleUuid,
}: {
  batch: CourseSchedule | null;
  updateRadioIndex: (i: number) => void;
  radioIndex: number;
  index: number;
  updateScheduleUuid: (uuid: string) => void;
}) {
  const updateRadioButton = (i: number) => {
    updateRadioIndex(i);
  };

  return (
    <button
      className="w-full flex items-center rounded bg-gray-100 p-4 [&>*]:text-gray-500 text-xs
  hover:bg-primary-200 [&>*]:hover:text-primary-600
  focus:bg-primary-600 [&>*]:focus:text-gray-50"
      onClick={() => {
        updateScheduleUuid(batch?.uuid || "");
        updateRadioButton(index);
      }}
    >
      {radioIndex === index ? (
        <Icon name="radio_button_checked" className="mr-4 text-sm" />
      ) : (
        <Icon name="radio_button_unchecked" className="mr-4 text-sm" />
      )}

      <div className="flex-col text-sm">
        <PillLabel
          status={batch?.isActive ? "on_going" : "scheduled"}
          className="flex "
        >
          {batch?.isActive ? "ONGOING" : "SCHEDULED"}
        </PillLabel>
        <div className="flex">
          <Icon name="date_range" />
          <div className="text-left">
            <h6>{batch?.name}</h6>
            <h6 className="flex font-bold">
              {dateFormattingSchedules(batch?.start as string) +
                (!!batch?.end ? " - " : "") +
                dateFormattingSchedules(batch?.end as string)}
            </h6>
          </div>
        </div>
      </div>
    </button>
  );
}
