import React, { ButtonHTMLAttributes } from "react";
import { Icon, Image } from "../../../atoms/components";

export interface CategoryCardProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  categoryUuid: string;
  coverUri: string;
}

export function CategoryCard({
  name,
  categoryUuid,
  coverUri,
  onClick,
}: CategoryCardProps) {
  return (
    <button
      className="flex flex-col items-center h-[216px] w-full max-w-[3000px] py-4 px-6 border border-gray-300 rounded-2xl"
      onClick={onClick}
    >
      {!!coverUri ? (
        <Image src={coverUri} className="h-32 w-32 mb-2" />
      ) : (
        <Icon
          name="view_agenda"
          className="text-9xl text-primary-400 text-center"
        />
      )}

      <p className="text-gray-700 text-lg font-bold line-clamp-2">{name}</p>
    </button>
  );
}
