import { CourseListLayoutCard } from "../../../molecules/components";
import { ColorObj } from "../../../helpers/ColorHelper";
import {
  Course,
  CourseViewScreen,
  CourseBlock,
} from "../../../pages/components/CourseViewer";

export interface CourseListLayoutProps {
  courseViewScreen?: CourseViewScreen;
  cardStyle?: ColorObj;
  onCourseBlockClick?: (block: CourseBlock) => void;
}

export function CourseListLayout({
  courseViewScreen,
  cardStyle,
  onCourseBlockClick,
}: CourseListLayoutProps) {
  const course = courseViewScreen?.course;
  return (
    <div>
      {course?.outline?.map((outline, sectionIdx) => {
        return (
          <div className="flex flex-col" key={sectionIdx}>
            <p className="font-black text-gray-900 mb-4 text-2xl">
              {outline?.title}
            </p>

            <div className="flex flex-col">
              {outline?.blocks?.map((block, blockIdx) => {
                return (
                  <button
                    onClick={() => {
                      onCourseBlockClick?.(block as CourseBlock);
                    }}
                    key={blockIdx}
                  >
                    <CourseListLayoutCard
                      block={block as CourseBlock}
                      cardStyle={cardStyle}
                      key={block?.uuid}
                      daysTilExpired={block?.daysTilExpired || 0}
                      expiryDate={block?.expiryDate || ""}
                    />
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
