import castPetKiwi from "../../../assets/castPetKiwi.png";
import castPetDoggerino from "../../../assets/castPetDoggerino.png";
import { Image, Button, Icon } from "../../../atoms/components";
import { Link } from "react-router-dom";

export interface DeactivatedUserProps {}

export function DeactivatedUser({}: DeactivatedUserProps) {
  const petImages = [castPetDoggerino, castPetKiwi];
  const randomImage = () => {
    return petImages[Math.floor(Math.random() * petImages.length)];
  };

  return (
    <div className="flex flex-col items-center my-auto z-50">
      <Image src={randomImage()} className="w-40 max-h-[500px] mb-5" />

      <h1 className="font-bold text-3xl text-primary-500 mb-4">
        Account Deactivated
      </h1>
      <p className=" py-1 px-2 rounded mb-4 bg-error-100 text-error-600">
        <Icon name="error" /> You do not have permission to access your account
      </p>

      <p className="mb-4">Please contact your Administrator</p>

      {/* UI Purposes */}
      <Link to={"/logout"} className="mx-auto">
        <Button>LOG OUT</Button>
      </Link>
    </div>
  );
}
