import { CourseUserCompletedBlockIndicatorDomainIndicator } from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";

export interface TrainingIndicatorProps {
  title?: string;
  indicators: (CamelCasedType<CourseUserCompletedBlockIndicatorDomainIndicator> | null)[];
}
export function TrainingIndicator({
  title,
  indicators,
}: TrainingIndicatorProps) {
  if (indicators.length < 1) {
    return <div data-testid="no-display"></div>;
  }

  return (
    <li>
      {title && indicators ? (
        indicators.map((indicator, i) => (
          <div key={i}>
            <div className="flex flex-row items-center justify-between w-full space-x-4">
              <span className="w-full text-gray-500 ">{title}</span>
              <span className="w-full ml-auto text-sm font-normal text-right text-gray-500 truncate">
                {indicator?.points} {indicator?.displayUnitName || "pt(s)"}
              </span>
            </div>
            {/* description */}
            <div
              className="w-11/12 mt-1 -ml-1 font-normal text-gray-500"
              key={i}
            >
              {indicator?.name}
            </div>
          </div>
        ))
      ) : indicators ? (
        indicators.map((indicator, i) => (
          <div
            className="flex flex-row items-center justify-between w-full space-x-4"
            key={i}
          >
            <span className="w-full text-gray-500 ">{indicator?.name}</span>
            <span className="w-full ml-auto text-sm font-normal text-right text-gray-500 truncate">
              {indicator?.points} {indicator?.displayUnitName || "pt(s)"}
            </span>
          </div>
        ))
      ) : (
        <></>
      )}
    </li>
  );
}
