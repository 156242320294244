import iconCss from "./Icon.css";

export interface IconProps extends React.ImgHTMLAttributes<HTMLSpanElement> {
  name: string;
}

export function Icon({
  name = "home",
  className = "",
  style = {},
  ...props
}: IconProps) {
  return (
    <i
      {...props}
      style={{ ...style, ...iconCss }}
      aria-label={name}
      className={`select-none ${className}`}
    >
      {name}
    </i>
  );
}
