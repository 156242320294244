export function titleCase(str: string) {
  if (str) {
    if (str == "html" || str == "pdf" || str == "epub") {
      return str.toUpperCase();
    }
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else {
    return "";
  }
}

export function snakeCaseToTitleCase(str: string) {
  return titleCase(str.replaceAll("_", " "));
}
