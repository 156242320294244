import { useState } from "react";
import { ReactReader } from "react-reader";
import { useCreateUrlFromBlob } from "../../../helpers/CreateUrlFromBlob/CreateUrlFromBlob";

export interface ViewResourceEPUBProps {
  src?: string;
}

export function ViewResourceEPUB({ src = "" }: ViewResourceEPUBProps) {
  const [location, setLocation] = useState("epubcfi(/6/2[cover]!/6)");
  const epubBlobUrl = useCreateUrlFromBlob(src);

  const locationChanged = (epubcifi: string) => {
    setLocation(epubcifi);
  };

  return (
    // For some reason, putting relative here will not mess up layout in windows browsers
    <div role="document" className="relative h-full">
      <ReactReader
        location={location}
        epubInitOptions={{ openAs: "epub" }}
        locationChanged={locationChanged}
        showToc={true}
        // url={src}
        url={epubBlobUrl ? epubBlobUrl : src}
      />
    </div>
  );
}
