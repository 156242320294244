import React, { createContext, useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Modal } from "../../../organisms/components";
import { DeactivatedUser } from "../../../organisms/components";

export const IsGuestQuery = gql`
  query IsGuestUserQuery {
    accountAndUserDetailsScreen: account_and_user_details_screen {
      userProfile: user_profile {
        isGuestUser: is_guest_user
        isDeactivatedUser: is_deactivated_user
      }
    }
  }
`;

export const isGuestContext = createContext<{
  isGuest: boolean;
}>({ isGuest: false });

export interface IsGuestProps {
  props: any;
}

export function IsGuest(props: any) {
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [isDeactivated, setIsDeactivated] = useState<boolean>(false);

  const [getIsGuest, { data, error, loading }] = useLazyQuery(IsGuestQuery, {
    onCompleted: (data) => {
      const isGuestUser =
        data?.accountAndUserDetailsScreen.userProfile.isGuestUser || false;

      const isDeactivatedUser =
        data?.accountAndUserDetailsScreen.userProfile.isDeactivatedUser ||
        false;

      setIsGuest(isGuestUser);
      setIsDeactivated(isDeactivatedUser);

      // if (isDeactivatedUser) {
      //   navigate("/deactivated-user");
      // }
    },
    onError: (data) => {
      getIsGuest();
    },
  });

  useEffect(() => {
    if (!/login/g.test(window.location.href)) {
      getIsGuest();
    }
  }, []);

  return (
    <isGuestContext.Provider value={{ isGuest: isGuest }}>
      <Modal isOpen={isDeactivated} disableCloseButton={true}>
        <DeactivatedUser />
      </Modal>
      {props.children}
    </isGuestContext.Provider>
  );
}
