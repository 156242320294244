import { Button } from "../../../atoms/components";
import { InputWithLabel } from "../InputWithLabel";

export interface ResetPasswordFormProps {
  prop?: string;
}

export function ResetPasswordForm({
  prop = "default value",
}: ResetPasswordFormProps) {
  return (
    <form className="p-4 sm:p-8">
      <div className="font-bold text-2xl mb-4 text-center">Reset Password</div>
      <div className="mb-4 text-center text-gray-400">
        Please enter email associated to your account and we will send you a
        password reset link to your email.
      </div>
      <div className="mb-8">
        <InputWithLabel _label="Email" placeholder="Email" type="email" />
      </div>
      <div className="w-full">
        <Button type="submit" width="w-full">
          send
        </Button>
      </div>
    </form>
  );
}
