import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Icon, Image } from "../../../atoms/components";

import {
  Profile,
  ProfileIconFromText,
} from "../../../organisms/components/Navbar";
import {
  useDeleteCommentMutation,
  useFlagCommentMutation,
  useNavbarQuery,
} from "../../../generated/graphql";
import { gql } from "@apollo/client";
import { Modal } from "../../../organisms/components";

export interface CommentProps {
  firstName: string | null;
  lastName: string | null;
  isFlagged: boolean;
  text: string;
  datetime: string;
  uuid: string;
  isUserModerator: boolean;
  refetchComments: () => void;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
}

export function Comment({
  firstName,
  lastName,
  isFlagged,
  text,
  datetime,
  uuid,
  isUserModerator,
  refetchComments,
  setToastOpen,
  setToastAction,
}: CommentProps) {
  const [open, setOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const created = new Date(datetime);
  const date = created.toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const time = created.toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });

  const fullName =
    (firstName !== "DEPRECATED - use traits.given_name" && firstName !== null
      ? firstName[0]
      : "Guest" || "") +
    " " +
    (lastName !== "DEPRECATED - use traits.family_name" && lastName !== null
      ? lastName[0]
      : "Account" || "");
  const words = fullName.split(" ");
  const letters = words.map((word) => word.charAt(0));
  const initials = letters.join("").toUpperCase();

  const [
    deleteComment,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useDeleteCommentMutation({
    variables: {
      forumCommentUuid: uuid || "",
    },
  });

  const [
    flagComment,
    { data: flagData, loading: flagLoading, error: flagError },
  ] = useFlagCommentMutation({
    variables: {
      forumCommentUuid: uuid || "",
    },
    onCompleted: () => {
      refetchComments();
    },
  });

  const deleteCall = () => {
    deleteComment({
      variables: {
        forumCommentUuid: uuid || "",
      },
      onCompleted: () => {
        refetchComments();
        setToastAction("delete");
        setIsDeleteModalOpen(false);
        setToastOpen(true);
      },
    });
  };

  const flagCall = () => {
    flagComment({
      variables: {
        forumCommentUuid: uuid || "",
        isFlagged: !isFlagged,
      },
    }).then(({ data }) => {
      if (data?.courseForumCommentUpdate?.comment?.isFlagged) {
        setToastAction("flag");
      } else {
        setToastAction("unflag");
      }
      // setReload(true);
      setToastOpen(true);
    });
  };

  const ref = useRef<any>(null);

  const handleClickOutside = (e: Event) => {
    if (ref.current != null) {
      if (!ref.current.contains(e.target)) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  return (
    <div>
      {/* Modal for Delete */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      >
        <div className="text-center w-80">
          <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
          <h2 className="mb-2">
            This action cannot be undone after{" "}
            <span className="font-bold">
              deleting {firstName} {lastName}&apos;s comment.
            </span>{" "}
          </h2>

          {/* Action Buttons Div */}
          <div className="flex justify-around">
            <Button
              color="error"
              onClick={() => {
                deleteCall();
              }}
            >
              Delete
            </Button>
            <Button
              color="primaryOutline"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className="relative flex items-start inline-block w-full pb-6 space-x-2 border-b-2">
        {/*Profile pic*/}
        <div className="shrink-0">
          <span
            className={`rounded-full  w-8 h-8 flex items-center justify-center font-bold text-white ${
              isFlagged ? "bg-primary-500" : "bg-primary-700"
            } select-none overflow-hidden`}
          >
            {initials}
          </span>
        </div>
        <div className="grow-0">
          <h3
            className={`font-bold text-md ${
              isFlagged ? "text-gray-400" : "text-black"
            }`}
            style={{ marginTop: "-0.15rem" }}
          >
            {firstName} {lastName}
          </h3>
          <p
            className={`text-xs ${
              isFlagged ? "text-gray-300" : "text-gray-500"
            }`}
            style={{ marginTop: "-0.1rem" }}
          >
            {date} - {time}
          </p>
          <p
            className={`text-md mt-1 ${
              isFlagged ? "text-gray-300" : "text-gray-500"
            }`}
          >
            {text}
          </p>
          {isFlagged ? (
            <div className="flex flex-row items-center">
              <Icon name="flag" className="text-red-500"></Icon>
              <p className="mt-1 italic text-red-500 text-md">Flagged</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          ref={ref}
          className={`absolute right-0 ${
            isUserModerator ? "visible" : "invisible"
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setOpen(!open);
            }}
            className="relative right-0 text-gray-400 bg-transparent hover:text-gray-700"
          >
            <Icon name="more_vert" className="text-xl"></Icon>
          </button>

          <ul
            className={`cursor-pointer border-2 border-slate-50 rounded hidden sm:block absolute items-center -top-[1%] right-8 m-1 bg-white w-32 rounded shadow-md ${
              open && !isDeleteModalOpen ? "visible" : "invisible"
            }`}
          >
            <li
              className="px-4 py-2 text-gray-400 bg-transparent rounded-t hover:text-gray-700"
              onClick={flagCall}
            >
              {/* <Icon name={path?.icon || ""} className="mr-1"></Icon> */}
              {isFlagged ? "Unflag" : "Flag"}
            </li>
            <li
              className={
                "bg-error-500 text-white hover:bg-error-600 focus:bg-error-600 px-4 py-2 rounded-b"
              }
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              {/* <Icon name={path?.icon || ""} className="mr-1"></Icon> */}
              {"Delete"}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
