import { useState } from "react";
import { Image } from "../../../atoms/components";
import { Icon } from "../../../atoms/components/Icon/Icon";

export interface CarouselCastBlockProps {
  imageURLs?: ImageURLs[];
}

type ImageURLs = string | null | undefined;

export function CarouselCastBlock({ imageURLs = [] }: CarouselCastBlockProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHover, setHover] = useState(false);

  const showArrows = () => {
    setHover(true);
  };

  const hideArrows = () => {
    setHover(false);
  };

  const goNext = () => {
    const isLast = currentIndex === imageURLs.length - 1;
    const newIndex = isLast ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goBack = () => {
    const isFirst = currentIndex === 0;
    const newIndex = isFirst ? imageURLs.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const jumpImage = (dotIndex: number) => {
    setCurrentIndex(dotIndex);
  };

  let stringCount = 0;
  imageURLs.forEach((el) => {
    if (el !== null && el !== undefined) {
      stringCount++;
    }
  });

  return (
    <div>
      {stringCount > 0 ? (
        <div
          className="flex items-end h-fit w-screen justify-center"
          onMouseOver={showArrows}
          onMouseOut={hideArrows}
          data-testid="imageholder"
        >
          {imageURLs.length > 1 && isHover && (
            <div
              className="absolute top-28 left-10 translate-x-0 translate-y-1/2 z-10 text-white text-4xl animate-pulse cursor-pointer"
              onClick={goBack}
              data-testid="left-arrow"
            >
              <Icon name="navigate_before" />
            </div>
          )}

          <Image
            src={imageURLs[currentIndex] || ""}
            className="h-full w-full "
            data-testid="image"
          />

          {/* <div className="h-full w-full">
            <div
              className="h-full w-full mx-auto bg-cover bg-center duration-500"
              style={{ backgroundImage: `url(${imageURLs[currentIndex]})` }}
              data-testid="image"
            ></div>
          </div> */}

          {imageURLs.length > 1 && isHover && (
            <div
              className="absolute top-28 right-10 translate-x-0 translate-y-1/2 z-10 text-white text-4xl animate-pulse cursor-pointer"
              onClick={goNext}
              data-testid="right-arrow"
            >
              <Icon name="navigate_next" />
            </div>
          )}

          {imageURLs.length > 1 && (
            <div className=" flex absolute mb-5 text-white">
              {imageURLs.map((dot, dotIndex) => (
                <div
                  className={`my-0 mx-1 text-xs cursor-pointer hover:text-white hover:transition duration-500 ${
                    dotIndex === currentIndex ? "text-white" : "text-gray-400"
                  }`}
                  key={dotIndex}
                  onClick={() => jumpImage(dotIndex)}
                  data-testid="counter"
                >
                  <Icon name="circle" />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
