import { Markdown } from "../../../atoms/components";

export interface MarkdownCastBlockProps {
  text: string | null | undefined;
}

export function MarkdownCastBlock({ text }: MarkdownCastBlockProps) {
  return (
    <div className="my-6 mx-20">
      <div className="h-auto min-h-[25vh]" data-testid="markdown-div">
        {text ? <Markdown text={text} /> : ""}
      </div>
    </div>
  );
}
