import React from "react";
import { Button } from "../../../atoms/components";
import { Link } from "react-router-dom";

export interface AccountAndUserDetailsSidePanelProps {
  className?: string;
  currentPage?:
    | "courseAndCertification"
    | "activityLog"
    | "accountAndUserDetails"
    | "purchases"
    | "subscription";
}

export function AccountAndUserDetailsSidePanel({
  className = "",
  currentPage,
}: AccountAndUserDetailsSidePanelProps) {
  return (
    <div className={"space-y-4 " + className}>
      <div>
        <Link
          to={`../../profile/certification`}
          className={` w-fit my-2 px-2 py-1 flex flex-none items-center  font-bold rounded ${
            currentPage === "courseAndCertification"
              ? "bg-primary-600 text-white shadow-md"
              : "text-primary-500 hover:bg-primary-200"
          }`}
        >
          <p>Course and Certification</p>
        </Link>

        <Link
          to={`/profile/activitylog`}
          className={` w-fit my-2 px-2 py-1 flex flex-none items-center  font-bold rounded ${
            currentPage === "activityLog"
              ? "bg-primary-600 text-white shadow-md"
              : "text-primary-500 hover:bg-primary-200"
          }`}
        >
          <p>Activity Log</p>
        </Link>

        <Link
          to={`/profile/account-and-user-details`}
          className={` w-fit my-2 px-2 py-1 flex flex-none items-center  font-bold rounded ${
            currentPage === "accountAndUserDetails"
              ? "bg-primary-600 text-white shadow-md"
              : "text-primary-500 hover:bg-primary-200"
          }`}
        >
          <p>Account and User Details</p>
        </Link>

        {/buribooks/g.test(window.location.href) ? (
          <div>
            <Link
              to={`/profile/purchases`}
              className={` w-fit my-2 px-2 py-1 flex flex-none items-center  font-bold rounded ${
                currentPage === "purchases"
                  ? "bg-primary-600 text-white shadow-md"
                  : "text-primary-500 hover:bg-primary-200"
              }`}
            >
              <p>Purchases</p>
            </Link>

            <Link
              to={`/profile/subscriptions`}
              className={` w-fit my-2 px-2 py-1 flex flex-none items-center  font-bold rounded ${
                currentPage === "subscription"
                  ? "bg-primary-600 text-white shadow-md"
                  : "text-primary-500 hover:bg-primary-200"
              }`}
            >
              <p>Subscriptions</p>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>

      <Button className="text-gray-500 text-sm border rounded-md py-[6px] px-2 border-gray-500 bg-transparent hover:text-white title-case ">
        <Link to="/logout" className="capitalize">
          Log Out
        </Link>
      </Button>
    </div>
  );
}
