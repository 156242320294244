import { useState, useEffect } from "react";
import { ExamResponseInput } from "../../../generated/graphql";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
} from "../../../pages/components/GbfSlide";
import { InputTextArea } from "../../../atoms/components";
import { parseColorToStyle } from "../../../helpers/ColorHelper";

export function LongAnswerSlideActivity({
  examItem,
  savedResponses,
  handleResponse,
  finalAppearance,
}: ActivityProps) {
  const [textInput, setTextInput] = useState<string>(
    getDefaultValue({ examItem, savedResponses }) as string
  );

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    handleResponse({
      item: examItem.uuid,
      long_input: textInput as string,
      short_input: textInput as string,
    });
  }, [textInput]);

  return (
    <div>
      <QuestionHeader text={examItem.text || ""} style={textStyle} />
      <InputTextArea
        value={textInput || ""}
        onChange={(e) => {
          setTextInput(e.target.value);
        }}
        placeholder="Type in answer..."
        disabled={hasAnswered}
        className="min-h-[200px] w-full border-2 border-black "
        style={textStyle}
      />
    </div>
  );
}
