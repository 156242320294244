import { useIdle } from "@uidotdev/usehooks";
import { useEffect, useState, useRef } from "react";
import { Modal } from "../../../organisms/components";
import { Button } from "../../../atoms/components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useIdleFieldsQuery,
  useLogoutMutation,
} from "../../../generated/graphql";
import { getCastlmsDomain } from "../../../pages/components/Login";

export interface IsIdleProps {}

export function IsIdle({}: IsIdleProps) {
  const domain = getCastlmsDomain(window.location.host);
  const [promptSeconds, setPromptSeconds] = useState<number | null>(90);
  const [idleSeconds, setIdleSeconds] = useState<number>(90);
  const { loading, data, error } = useIdleFieldsQuery({
    variables: {
      domain: domain,
    },
    onCompleted: (response) => {
      setPromptSeconds(
        parseInt(
          response.loginScreen?.cast?.attributes?.logoutAfterXSeconds || ""
        ) || null
      );
      setIdleSeconds(
        parseInt(
          response.loginScreen?.cast?.attributes?.logoutPromptAfterXSeconds ||
            ""
        ) || 90
      );
    },
  });

  const ms = (promptSeconds || 90) * 1000;
  const isIdle = useIdle(ms);
  const idleTimeout = useRef<any>(null);

  const [idleModalOpen, setIdleModalOpen] = useState(false);
  const [loggedOutModalOpen, setLoggedOutModalOpen] = useState(false);
  const location = useLocation();

  const resetIdleTimeout = () => {
    if (idleTimeout.current) clearTimeout(idleTimeout.current);
    if (!loggedOutModalOpen) {
      idleTimeout.current = setTimeout(() => {
        if (!location.pathname.includes("login")) setIdleModalOpen(true);
      }, ms);
    }
  };

  useEffect(() => {
    if (isIdle) {
      if (!location.pathname.includes("login")) setIdleModalOpen(true);
    }
  }, [isIdle]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        resetIdleTimeout();
      } else {
        if (idleTimeout.current) clearTimeout(idleTimeout.current);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [loading, data]);

  const closeIdleModal = () => {
    setIdleModalOpen(false);
    resetIdleTimeout();
  };

  const openLoggedOutModal = () => {
    setIdleModalOpen(false);
    setLoggedOutModalOpen(true);
  };

  const navigate = useNavigate();
  const logout = () => {
    navigate("/logout");
  };

  if (
    !data?.loginScreen?.cast?.attributes?.logoutAfterXSeconds ||
    data?.loginScreen?.cast?.attributes?.logoutAfterXSeconds === "0"
  ) {
    return <></>;
  }

  return (
    <div className="">
      {idleModalOpen && !loggedOutModalOpen && (
        <AreYouThereModal
          seconds={idleSeconds}
          idleModalOpen={idleModalOpen}
          openLoggedOutModal={openLoggedOutModal}
          closeIdleModal={closeIdleModal}
          logout={logout}
        />
      )}

      {loggedOutModalOpen && (
        <YouveBeenLoggedOutModal
          loggedOutModalOpen={loggedOutModalOpen}
          logout={logout}
        />
      )}
    </div>
  );
}

interface AreYouThereModalProps {
  idleModalOpen: boolean;
  openLoggedOutModal: () => void;
  closeIdleModal: () => void;
  logout: () => void;
  seconds: number;
}

function AreYouThereModal({
  idleModalOpen,
  closeIdleModal,
  logout,
  openLoggedOutModal,
  seconds,
}: AreYouThereModalProps) {
  const ms = seconds * 1000;
  const remainingTime = useRef(ms);
  const ignoreIntervalId = useRef<any>(null);
  const intervalTime = 1000;

  const [secondsDisplay, setSecondsDisplay] = useState<number>(seconds);

  const clearIgnoreIntervalId = () => {
    if (ignoreIntervalId.current) clearInterval(ignoreIntervalId.current);
    remainingTime.current = ms;
  };

  function ignoreAreYouTherePromptInterval() {
    clearIgnoreIntervalId();
    ignoreIntervalId.current = setInterval(() => {
      remainingTime.current -= intervalTime;
      setSecondsDisplay(remainingTime.current / 1000);
      if (remainingTime.current <= 0) {
        clearInterval(ignoreIntervalId.current);
        ignoreIntervalId.current = null;
        openLoggedOutModal();
      }
    }, intervalTime);
  }

  const continueSession = () => {
    clearIgnoreIntervalId();
    closeIdleModal();
  };

  useEffect(() => {
    if (idleModalOpen) ignoreAreYouTherePromptInterval();
    return () => clearIgnoreIntervalId();
  }, [idleModalOpen]);

  return (
    <Modal isOpen={idleModalOpen} onRequestClose={continueSession}>
      <div className="flex flex-col justify-center mb-10 space-y-8 text-center">
        <h1 className="text-2xl font-black">Are you still there?</h1>
        <p className="w-2/3 mx-auto text-lg">
          You&apos;ve been idle for a while. We&apos;ll log you out soon due to
          inactivity.
        </p>
      </div>

      <div className="flex justify-around w-full space-x-2">
        <Button color="primaryOutline" className="w-full" onClick={logout}>
          LOG OUT
        </Button>
        <Button color="primary" className="w-full" onClick={continueSession}>
          CONTINUE ({secondsDisplay} <span className="lowercase">s</span>)
        </Button>
      </div>
    </Modal>
  );
}

interface YouveBeenLoggedOutModalProps {
  loggedOutModalOpen: boolean;
  logout: () => void;
}

function YouveBeenLoggedOutModal({
  loggedOutModalOpen,
  logout,
}: YouveBeenLoggedOutModalProps) {
  const [logoutMutation, { data, loading, error }] = useLogoutMutation();

  useEffect(() => {
    if (loggedOutModalOpen) {
      logoutMutation();
    }
  }, [loggedOutModalOpen]);

  return (
    <Modal
      isOpen={loggedOutModalOpen && !loading && !!data}
      disableCloseButton={true}
    >
      <div className="flex flex-col justify-center pt-8 mb-10 space-y-8 text-center">
        <h1 className="text-2xl font-black">Logged out</h1>
        <p className="w-2/3 mx-auto text-lg">
          Your account was automatically logged-out due to inactivity. Please
          log in again to continue.
        </p>
      </div>

      <div className="flex justify-around w-full space-x-2">
        <Button color="primary" onClick={logout} className="ml-auto">
          OK
        </Button>
      </div>
    </Modal>
  );
}
