import { useEffect, useRef } from "react";
import { Icon } from "../../../atoms/components";
import { Modal } from "../Modal";
import { useState } from "react";
import { ResourceCard } from "../ResourceCard";
import { useSearchParams } from "react-router-dom";

import {
  ResourceContentBurislideSpread as ResourceContentBurislideSpreadOriginal,
  useResourceDownloadLazyQuery
} from "../../../generated/graphql";

import DownloadAndCache from "../DownloadAndCache/DownloadAndCache";
import { CamelCasedType } from "../../../helpers/components";

export interface InformationButtonProps { }
export type ResourceContentBurislideSpread =
  CamelCasedType<ResourceContentBurislideSpreadOriginal>;

export function InformationButton({ }: InformationButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const courseUuid = searchParams.get("course_uuid");
  const resourceUuid = searchParams.get("resource_uuid");
  const [resource, setResource] = useState<any>();
  const [urlsToCache, setUrlsToCache] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [alreadyCached, setAlreadyCached] = useState(false);
  const ref = useRef<any>(null);

  const [
    getResourceDownload,
    { data: resourceData, error: resourceError, loading: resourceLoading },
  ] = useResourceDownloadLazyQuery();

  useEffect(() => {
    if (resourceUuid && process.env.REACT_APP_PWA_ENABLED === 'true') {
      getResourceDownload({
        variables: {
          resourceUuid: resourceUuid,
        },
        onCompleted(data) {
          setResource(data.resourceViewScreen?.resource);
          const urls = []
          const resourceTypesToCheck = ["video", "pdf", "epub"];
          const resourceContent = data.resourceViewScreen?.resource?.content;

          if (resourceTypesToCheck.includes(data.resourceViewScreen?.resource?.type!) && resourceContent) {
            const uri = (resourceContent as { uri?: string }).uri;
            if (uri) {
              urls.push(uri);
            }
          }

          if (data.resourceViewScreen?.resource?.type === "burislides" && resourceContent) {
            const spreads = (resourceContent as { spreads?: ResourceContentBurislideSpread[] }).spreads

            spreads?.forEach(item => {
              if (item.spreadType === "slide") {
                urls.push(item.uri);
              }
            });
          }

          if (data.resourceViewScreen?.resource?.type === "buribook-with-activity" && resourceContent) {
            const spreads = (resourceContent as { spreads?: ResourceContentBurislideSpread[] }).spreads

            spreads?.forEach(item => {
              if (item.spreadType === "image-spread") {
                urls.push(item.leftUri, item.rightUri);
              }
            });
          }

          setUrlsToCache(urls)
        },
      });
    }
  }, []);

  const handleClick = () => {
    if (process.env.REACT_APP_PWA_ENABLED === 'true') {
      if (resource?.type === "pdf" || resource?.type === "video" || resource?.type === "burislides" ||
        resource?.type === "buribook-with-activity" || resource?.type === "epub"
      ) {
        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  };

  const handleDescriptionClick = () => {
    setIsOpen(true);
    setShowDropdown(false);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        className="w-1/2 bg-white mx-auto mt-20 py-4 rounded-xl"
        testId="resourceModal"
      >
        {resourceUuid ? (
          <ResourceCard
            resourceUuid={resourceUuid as string}
            isInfoCard={true}
          ></ResourceCard>
        ) : (
          <ResourceCard
            courseUuid={courseUuid as string}
            isInfoCard={true}
          ></ResourceCard>
        )}
      </Modal>

      <button>
        <Icon name="info" onClick={handleClick}></Icon>
      </button>

      {showDropdown && (
        <div
          className="fixed text-sm  z-10 p-4 bg-white shadow-lg  w-fit h-fit right-2 top-16 left-auto rounded-lg"
          ref={ref}
        >
          <div className="px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200">
            <button onClick={handleDescriptionClick}>Description</button>
          </div>
          <div className="px-2 py-1 mx-2 my-2 ml-1 rounded text-primary-500 hover:bg-primary-200">
            <DownloadAndCache
              urlsToCache={urlsToCache}
              loading={loading}
              setLoading={setLoading}
              alreadyCached={alreadyCached}
              setAlreadyCached={setAlreadyCached} />
          </div>
        </div>
      )}
    </div>
  );
}
