import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { CatalogListItem } from "../../../molecules/components";
import { Loading } from "../../../atoms/components";

const GetPreReq = gql`
  query ResourceViewScreenPrerequisites(
    $resourceUuid: ID!
    $courseBlockUuid: String
  ) {
    resourceViewScreen: resource_view_screen(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
    ) {
      courseBlock: course_block {
        prerequisitesCourseBlocks: prerequisites_course_blocks {
          uuid
          title
          coverUri: cover_uri
          resource {
            uuid
            name
            type
            subtype
            coverUri: cover_uri
          }
        }
      }
    }
  }
`;

export interface PrerequisitesProps {
  resourceUuid?: string;
  courseBlockUuid?: string;

  onBlockResourceClick?: (result: {
    resourceUuid: string;
    courseBlockUuid: string;
  }) => void;
}

export function Prerequisites({
  resourceUuid,
  courseBlockUuid,
  onBlockResourceClick,
}: PrerequisitesProps) {
  const { loading, data, error } = useQuery(GetPreReq, {
    variables: {
      courseBlockUuid: courseBlockUuid,
      resourceUuid: resourceUuid,
    },

    skip: !resourceUuid || !courseBlockUuid,
  });

  useErrorHandler(error);

  const prereqBlocks =
    data?.resourceViewScreen.courseBlock.prerequisitesCourseBlocks;

  return (
    <>
      <div className="pb-4">
        {/* PRE REQ HEADER */}
        <div className="text-center text-gray-700 mb-4">
          <h1 className="text-2xl font-bold ">Prerequisites</h1>
          <h2 className="text-xs">
            Please complete the following to unlock this resource
          </h2>
        </div>

        {/* PRE REQS GOES HERE */}
        <div>
          {loading ? (
            <div className="mx-auto w-16 h-16">
              <Loading></Loading>
            </div>
          ) : (
            <ul>
              {prereqBlocks?.map((preBlock: any, index: number) => {
                return (
                  <li key={index}>
                    <button
                      onClick={() => {
                        onBlockResourceClick?.({
                          resourceUuid: preBlock.resource.uuid,
                          courseBlockUuid: preBlock.uuid,
                        });
                      }}
                      className="border-b text-left w-full"
                    >
                      <CatalogListItem
                        name={preBlock.resource.name}
                        locked={false}
                        coverUri={preBlock.resource.coverUri}
                        type={preBlock.resource.type}
                        subtype={preBlock.resource.subtype}
                      ></CatalogListItem>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
