import { useEffect, useState } from "react";

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pingGoogle = async () => {
      await fetch("https://www.google.com/", {
        method: "HEAD",
        mode: "no-cors",
      })
        .then(function (response) {
          setIsOnline(true);
          setLoading(false);
        })
        .catch(function (err) {
          setIsOnline(false);
          setLoading(false);
        });
    };

    const handleOnlineStatusChange = () => {
      pingGoogle();
    };

    const initialPing = () => {
      pingGoogle(); // Initial ping when the component mounts
      setInterval(() => {
        pingGoogle(); // Ping every 5 minutes
      }, 30000);
    };

    initialPing();

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return { isOnline, loading };
};
