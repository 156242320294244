import { useContext, useEffect, useMemo } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import camelcaseKeys from "camelcase-keys";
import { getEndSlideTemplate } from "./template/endSlideTemplate";
import { useSearchParams } from "react-router-dom";

export interface CasticulateEndSlideProps {
  node?: CasticulateNode;
  cookRecipes?: {
    cookAsCompletedInsideCourse: () => void;
    cookAttemptInsideCourse: () => void;
    cookAsCompletedOutsideCourse: () => void;
    cookAttemptOutsideCourse: () => void;
  };
}

export function CasticulateEndSlide({
  node,
  cookRecipes,
}: CasticulateEndSlideProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const [searchParams] = useSearchParams();
  const isInCourse = !!searchParams.get("course_uuid");

  const END_SLIDE_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      companyNameHeader: "GBF Class Builder",
      title: nodeData.title,
      header: nodeData.header || "Congratulations! You're ready.",
      subtext:
        nodeData.subText ||
        // "You reviewed your upcoming lesson. You can now proceed!",
        "",
      imageUri: nodeData.imageUri,
      isHidden: node?.slideType === "START" ? "hidden" : "flex",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext]
  );

  useEffect(() => {
    if (cookRecipes)
      isInCourse
        ? cookRecipes?.cookAsCompletedInsideCourse()
        : cookRecipes?.cookAsCompletedOutsideCourse();
  }, []);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getEndSlideTemplate(nodeData.theme)}
        view={END_SLIDE_TEMPLATE_VIEW}
      />
    </div>
  );
}
