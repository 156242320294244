import { twMerge } from "tailwind-merge";

export interface AudioProps {
  src: string;
  className?: string;
}

export function Audio({ src = "", className = "" }: AudioProps) {
  if (!src) {
    return <></>;
  }

  return (
    <audio
      controls
      className={twMerge("w-full " + className)}
      data-testid="audio"
    >
      <source data-testid="source" src={src} type="audio/ogg" />
    </audio>
  );
}
