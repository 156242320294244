import { useEffect } from "react";
import { Image, Loading, Markdown } from "../../../atoms/components";
import { useResultSlideLazyQuery } from "../../../generated/graphql";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import resultImage from "../../../assets/resultsPageImage.png";

export interface ResultSlideProps {
  examSectionUuid?: string;
  submissionUuid?: string;
  id: number;
}

export function ResultSlide({
  examSectionUuid,
  submissionUuid,
  id,
}: ResultSlideProps) {
  const { ref, inView } = useInView();
  const [searchParams] = useSearchParams();
  const [getResults, { data, loading, error }] = useResultSlideLazyQuery({
    variables: {
      examSectionUuid: examSectionUuid || "",
      examSubmissionUuid: submissionUuid || "",
      resourceUuid: searchParams.get("resource_uuid") || "",
      resourceSpreadIndex: id,
    },
  });

  useEffect(() => {
    if (inView === true) {
      getResults();
    }
  }, [inView]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-w-full min-h-full">
        <div className="max-h-[150px] md:max-h-[200px] md:max-w-[300px] lg:max-h-[600px] flex flex-col items-center ">
          <Loading />
          <p className="mt-2 text-primary-500">Loading Results</p>
        </div>
      </div>
    );
  }

  if (data?.resourceViewScreen?.sectionResult?.scoreMax === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="px-8 mb-4 text-2xl font-black text-gray-700">
          Thank you
        </h1>
        <h1 className="px-8 mb-4 text-2xl font-black text-gray-700">
          Please Continue
        </h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-full" ref={ref}>
      <div className="flex flex-col my-auto text-center ">
        <h1 className="mb-4 text-2xl font-black text-gray-700">
          You got {data?.resourceViewScreen?.sectionResult?.scoreRaw || 0} /{" "}
          {data?.resourceViewScreen?.sectionResult?.scoreMax || 0}
        </h1>
        <Markdown
          text={
            data?.resourceViewScreen?.sectionResult?.spreadResultDetails
              ?.resultPageText || "Keep up the good work!"
          }
          className="mx-auto mb-8 text-center text-gray-700 "
        />

        <Image
          src={
            data?.resourceViewScreen?.sectionResult?.spreadResultDetails
              ?.resultPageImageUri || resultImage
          }
          alt=""
          className="max-h-[150px] md:max-h-[200px] md:max-w-[300px] lg:max-h-[600px] pb-[20%]"
        />
      </div>
    </div>
  );
}
