import { useState, useEffect } from 'react';
import { getFile } from '../../organisms/components/DownloadAndCache/DownloadAndCache';
import { Maybe } from 'graphql/jsutils/Maybe';

export const useCreateUrlFromBlob = (uri: Maybe<string> | undefined) => {
    const [blob, setBlob] = useState<Blob | null>(null)
    const [url, setUrl] = useState<string | null>(null);

    useEffect(() => {
        const fetchFileAndSetBlob = async (uri: Maybe<string> | undefined) => {
            if (uri) {
                const fetchedBlob = await getFile(uri);
                if (fetchedBlob) {
                    setBlob(fetchedBlob);
                }
            }
        };

        fetchFileAndSetBlob(uri);
    }, [uri]);

    useEffect(() => {
        if (blob) {
            const objectUrl = URL.createObjectURL(blob);
            setUrl(objectUrl);
            return () => {
                URL.revokeObjectURL(objectUrl);
            };
        }
    }, [blob]);

    return url;
};
