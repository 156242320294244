import React, { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Icon } from "../../../atoms/components";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { LoadingCentered } from "../LoadingCentered";
import { resourceTypeIcon } from "../../../helpers";

const GET_COURSE_UUID = gql`
  query ResourceViewScreenCourseOutline($resourceUuid: ID!) {
    resourceViewScreen: resource_view_screen(resource_uuid: $resourceUuid) {
      resource {
        content {
          ... on ResourceContentCourse {
            courseDetails: course_details {
              courseUuid: course_uuid
            }
          }
        }
      }
    }
  }
`;

const GET_COURSE_OUTLINE = gql`
  query Course($courseUuid: String!) {
    course_view_screen(course_uuid: $courseUuid) {
      course {
        id
        outline {
          blocks {
            resource {
              type
              uuid
              name
            }
          }
        }
        is_enrolled
        allowed_actions
      }
    }
  }
`;

export interface CourseOutlineProps {
  resourceUuid: string;
  setEnrolled?: React.Dispatch<React.SetStateAction<boolean>>;
  setFinishedLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CourseOutline({
  resourceUuid,
  setEnrolled,
  setFinishedLoading,
}: CourseOutlineProps) {
  // First Query to get Course UUID
  const { loading, data, error } = useQuery(GET_COURSE_UUID, {
    variables: {
      resourceUuid: resourceUuid as string,
    },
  });
  useErrorHandler(error);

  // Second Query receiving Course UUID as variable.
  const [
    loadoutline,
    { loading: outlineLoading, data: outlineData, error: outlineError },
  ] = useLazyQuery(GET_COURSE_OUTLINE);

  useErrorHandler(outlineError);

  // Run when first query stops loading
  useEffect(() => {
    if (!loading) {
      const courseUuid = data?.resourceViewScreen.resource.content.courseDetails
        .courseUuid as string;
      loadoutline({
        variables: {
          courseUuid: courseUuid,
        },
      });
    }
  }, [loading]);

  if (outlineData && setEnrolled && setFinishedLoading) {
    setEnrolled(outlineData?.course_view_screen.course.is_enrolled);
    setFinishedLoading(true);
  }

  return (
    <div data-testid={"loading"}>
      {outlineLoading || !data ? (
        <div className="relative h-48">
          <LoadingCentered />
        </div>
      ) : (
        <ul className="list-disc ml-5">
          {outlineData?.course_view_screen?.course?.outline?.map(
            (section: any) => {
              return section.blocks.map((block: any) => {
                return (
                  <li className="my-2 text-gray-500" key={block.uuid}>
                    <Icon
                      name={resourceTypeIcon(block.resource.type)}
                      className="mr-4"
                    />
                    {block.resource.name}
                  </li>
                );
              });
            }
          )}
        </ul>
      )}
    </div>
  );
}
