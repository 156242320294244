export interface SignupLayoutProps {
  headerLogo?: React.ReactNode;
  signupform: React.ReactNode;
  help?: React.ReactNode;
  footer?: React.ReactNode;
  _type?: "signup" | "reset" | "confirmation";
}

export function SignupLayout({
  headerLogo,
  signupform,
  help,
  footer,
  _type = "signup",
}: SignupLayoutProps) {
  return (
    <div className="sm:flex sm:bg-primary-600">
      <div className="h-screen sm:mx-auto rounded sm:bg-white sm:mt-16 sm:mb-16 sm:h-auto flex flex-col">
        <div className="flex-1">
          {headerLogo ? (
            <div
              className={` ${
                _type === "signup" ? "sm:h-40 h-24" : "h-64"
              } object-contain p-4 sm:p-8`}
            >
              {headerLogo}
            </div>
          ) : (
            <div className="pt-4"></div>
          )}

          <div className="">{signupform}</div>
          <div className="p-4 sm:p-8 mx-auto text-center">{help}</div>
        </div>

        <div className="shrink-0">{footer}</div>
      </div>
    </div>
  );
}
