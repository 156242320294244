import { useNavigate, useSearchParams } from "react-router-dom";
import { Navbar } from "../../../organisms/components";
import { Icon, Button } from "../../../atoms/components";

export interface SubscriptionResultProps {}

export function SubscriptionResult() {
  const [searchParams] = useSearchParams();
  const result = searchParams.get("buricommerce");
  const navigate = useNavigate();
  return (
    <div>
      <Navbar></Navbar>

      <div className="bg-gray-200 md:w-4/5 lg:w-3/5 p-5 md:p-10 rounded-md mx-5 md:mx-auto mt-40">
        {result === "success" ? (
          <div className="text-center mb-6">
            <h1 className="text-2xl md:text-4xl font-bold">
              Your Subscription is Successful
            </h1>
            <Icon
              name="verified"
              className="text-8xl text-success-500 pt-2"
            ></Icon>
          </div>
        ) : (
          <div className="text-center mb-6">
            <h1 className="text-2xl md:text-4xl font-bold">
              Your Subscription Failed
            </h1>
            <Icon name="error" className="text-8xl text-error-500 pt-2"></Icon>
          </div>
        )}
        <Button
          className="mx-auto md:text-xl"
          onClick={() => {
            navigate("../");
          }}
        >
          Go Back To Subscriptions
        </Button>
      </div>
    </div>
  );
}
