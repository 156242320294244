import { Icon } from "../../../atoms/components";

export interface FilterPillProps {
  filterName: string;
  filterValue: string;
  className?: string;
  onClose?: () => {};
}

export function FilterPill({
  filterName,
  filterValue,
  className,
  onClose,
}: FilterPillProps) {
  return (
    <div
      className={`rounded-lg w-fit px-4 py-1 flex bg-gray-200 text-gray-600 gap-2 ${className}`}
    >
      <p className="flex">
        Resource {filterName}: {filterValue}
      </p>
      {onClose && <Icon name="close" role="button" onClick={onClose} />}
    </div>
  );
}

export function TypeFilterPill({
  name,
  isSelected,
  onClick = () => {},
}: {
  name: string;
  isSelected: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={`bg-primary-200 hover:bg-primary-300 whitespace-nowrap ${
        isSelected ? "bg-primary-300" : ""
      } text-primary-700 py-1 px-2 rounded-md shadow-md transition-colors duration-300`}
    >
      {name}
    </button>
  );
}
