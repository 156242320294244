import React, { useEffect, useLayoutEffect, useContext, useMemo } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getVideoTemplate } from "./template/videoTemplate";

export interface CasticulateVideoResourceProps {
  prop?: string;
  node?: CasticulateNode;
}

export function CasticulateVideoResource({
  prop = "default value",
  node = {},
}: CasticulateVideoResourceProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const RESOURCE_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      uri: nodeData.uri,
      title: nodeData.title,
      buttonText: "Continue",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext.progressBar, slideContext.lastVisitedTitlePageId, node]
  );

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getVideoTemplate(nodeData.theme)}
        view={RESOURCE_TEMPLATE_VIEW}
      />
    </div>
  );
}
