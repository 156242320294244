import React, { useEffect, useState } from "react";
import { Button } from "../../../atoms/components";

export interface PolicyProps {
  prop?: string;
}

export function Policy({ prop = "default value" }: PolicyProps) {
  const [acceptPolicy, setAcceptPolicy] = useState(
    localStorage.getItem("policy") === "accepted"
  );

  return (
    <div
      className={`fixed bottom-0 left-0 bg-gray-100 px-6 py-4 w-full transition transform duration-300 z-[100] ${
        acceptPolicy ? "translate-y-full" : ""
      }`}
    >
      <div className="mb-2 text-sm">
        We use cookies in this website to give you the best experience on our
        site. To find out more, read our{" "}
        <a
          className="underline text-primary-600"
          href="https://castlms.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          cookie and privacy policy
        </a>
      </div>
      <Button
        onClick={() => {
          setAcceptPolicy(true);
          localStorage.setItem("policy", "accepted");
        }}
        className="px-4 py-2 text-center text-white uppercase rounded cursor-pointer bg-primary-600 hover:bg-primary-800 focus:bg-primary-800"
      >
        okay
      </Button>
    </div>
  );
}
