import React, { useState, useContext } from "react";

import { useForumTopicCreateMutation } from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { Button, Icon } from "../../../atoms/components";
import { Modal } from "../Modal";
import { ForumContext } from "../../../pages/components/ForumViewer";
import { LoadingCentered } from "../../../molecules/components";

export interface ForumTopicCreateViewerProps {
  courseUuid: string;
  setIsTopicCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
}
export function ForumTopicCreateViewer({
  courseUuid,
  setIsTopicCreateModalOpen,
  setToastOpen,
  setToastAction,
}: ForumTopicCreateViewerProps) {
  const forumContext = useContext(ForumContext);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [isTitleInvalid, setIsTitleInvalid] = useState<boolean>(false);

  const [newTopicTitle, setNewTopicTitle] = useState<string>("");
  const [newTopicText, setNewTopicText] = useState<string>("");
  function handleTitleInput(event: any) {
    setNewTopicTitle(event.target.value);
    if (event.target.value.length > 0) {
      setIsTitleInvalid(false);
    }
  }
  function handleTextInput(event: any) {
    setNewTopicText(event.target.value);
  }

  const formWarningClass = isTitleInvalid ? "text-error-500 mx-2" : "hidden";
  const titleInputClass = isTitleInvalid
    ? "block flex-1 border-error-500 bg-error-50 rounded py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
    : "block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6";
  function handleSubmitValidation() {
    if (newTopicTitle.length > 0) {
      setIsTitleInvalid(false);
      setIsSubmitModalOpen(true);
    } else {
      setIsTitleInvalid(true);
    }
  }

  // Create a Topic Mutation
  function handleSave() {
    forumContext.createForumTopic({
      variables: {
        input: {
          course_uuid: courseUuid || "",
          title: newTopicTitle,
          text: newTopicText || "",
          is_active: true,
        },
      },
      onCompleted: () => {
        setIsTopicCreateModalOpen(false);
        setNewTopicText("");
        setNewTopicTitle("");
        setToastAction("create");
        setToastOpen(true);
        forumContext.refetchForum({
          courseUuid: courseUuid,
        });
      },
    });
  }

  return (
    <div className="mx-6 md:mx-20">
      <button
        className="bg-gray-500 rounded-sm px-0.5"
        onClick={() => {
          setIsTopicCreateModalOpen(false);
        }}
      >
        <Icon name="chevron_left" className="font-bold text-white"></Icon>
      </button>
      <div className="my-6 text-2xl font-bold">Create Topic</div>

      <form className="w-full">
        <div className="space-y-12">
          <div className="pb-12">
            <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="title"
                  className="block font-normal leading-6 text-gray-900"
                >
                  Title
                  <span className={formWarningClass}>
                    <Icon name="error"></Icon>Cannot be empty
                  </span>
                </label>
                <div className="mt-2">
                  <div className="flex max-w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="title"
                      className={titleInputClass}
                      placeholder=""
                      onChange={handleTitleInput}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="description"
                  className="block font-normal leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleTextInput}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6">
          <Button
            className="my-2"
            width="w-full"
            onClick={handleSubmitValidation}
          >
            SAVE
          </Button>

          {/* Modal for Submit */}
          <Modal
            isOpen={isSubmitModalOpen}
            onRequestClose={() => setIsSubmitModalOpen(false)}
          >
            <div className="text-center w-80">
              {forumContext.createForumTopicLoading && <LoadingCentered />}
              <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
              <h2 className="mb-2">
                This action will create{" "}
                <span className="font-bold">
                  this new topic &quot;{newTopicTitle}&quot;.
                </span>{" "}
              </h2>

              {/* Action Buttons Div */}
              <div className="flex justify-around">
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Confirm
                </Button>
                <Button
                  color="primaryOutline"
                  onClick={() => {
                    setIsSubmitModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            {/* End of Modal */}
          </Modal>

          <Button
            color="primaryOutline"
            className="my-2"
            width="w-full"
            onClick={() => {
              setIsTopicCreateModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}
