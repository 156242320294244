import React from "react";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { Icon } from "../../../atoms/components";
export interface ExpiredResourceProps {
  expiryDate?: string;
}

export function ExpiredResource({ expiryDate }: ExpiredResourceProps) {
  return (
    <div className="text-center">
      <h1 className="text-2xl font-black text-gray-700 mb-4">
        Resource Expired
      </h1>
      <p>
        The resource you are attempting to open is no longer accessible as of
        the specified date below
      </p>
      <div className="bg-error-100 font-bold rounded p-4 mt-4 ">
        <p className="text-error-500">
          <Icon
            name="timer_off"
            className="select-none mr-1"
            style={{ fontFamily: "Material icons Outlined" }}
          >
            timer_off
          </Icon>
          Expired On
        </p>
        <p className="text-error-500 text-2xl">
          {dateFormattingSchedules(expiryDate as string)}
        </p>
      </div>
    </div>
  );
}
