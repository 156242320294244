import { UserSubscriptionQuery } from "../Subscriptions";
import { useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { Navbar } from "../../../organisms/components";
import { Button } from "../../../atoms/components";
import { Link } from "react-router-dom";
import {
  AccountAndUserDetailsSidePanel,
  LoadingCentered,
} from "../../../molecules/components";
import { NavBarLayout } from "../../../templates/components";
export interface PurchaseHistoryProps {}

export function PurchaseHistory() {
  const tableHeads = [
    "Plan Name",
    "Active",
    "Subscription ID",
    "Plan ID",
    "Payment Method",
  ];
  const { data, error } = useQuery(UserSubscriptionQuery);
  useErrorHandler(error);
  const subscriptions = data?.subscriptionScreen.userSubscriptions;

  return (
    <NavBarLayout className="h-full min-h-screen max-w-screen overflow-clip bg-gray-200">
      <div className="flex">
        <AccountAndUserDetailsSidePanel
          className="w-1/4 py-6 px-4 hidden lg:block"
          currentPage="purchases"
        />
        {data ? (
          <main className="flex-1">
            <h1 className="mt-8 mb-5 text-center text-3xl ">Purchases</h1>
            <table className="table-auto w-3/4 bg-white border-2 border-black  mx-auto">
              <thead>
                <tr className="text-left">
                  {tableHeads.map((columnTitle) => {
                    return (
                      <th className="p-4 border border-black" key={columnTitle}>
                        {columnTitle}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((receipt: any) => {
                  return (
                    <tr className="" key={receipt.orderNumber}>
                      <td className="p-4 border border-black break-words w-1/3">
                        {receipt.plan.name}
                      </td>
                      <td className="p-4 border border-black break-words">
                        {receipt.isActive ? "Active" : "Expired"}
                      </td>
                      <td className="p-4 border border-black break-words ">
                        {receipt.uuid}
                      </td>
                      <td className="p-4 border border-black break-words">
                        {receipt.plan.uuid}
                      </td>
                      <td className="p-4 border border-black break-words">
                        {receipt.plan.isRecurring ? "Recurring" : "Prepaid"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Link to={"../subscriptions"} className="w-full my-5 block">
              <Button
                className="mx-auto border-2 border-black"
                color="primaryInverted"
              >
                Show Subscriptions
              </Button>
            </Link>
          </main>
        ) : (
          <LoadingCentered></LoadingCentered>
        )}
      </div>
    </NavBarLayout>
  );
}
