import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Navbar } from "../../../organisms/components";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { useErrorHandler } from "react-error-boundary";
import { titleCase } from "../../../helpers/TitleCase";
import {
  AccountAndUserDetailsSidePanel,
  LoadingCentered,
} from "../../../molecules/components";
import { useHumanizeResourceType } from "../../../helpers/ResourceType";
import { NavBarLayout } from "../../../templates/components";

// Flags for Deped

const isDepedProd = false;

// To be changed, ideally to be fetched by Block Id not the whole block

interface Activity {
  block: Block;
  date: Date;
  status: "Completed" | "Opened";
}

interface Block {
  image: string;
  title: string;
  type: string;
}

export const GetUserActivityQuery = gql`
  query Log_groups {
    activityLogScreen: activity_log_screen {
      logGroups: log_groups {
        name
        logItems: log_items {
          timestamp
          resource {
            name
            coverUri: cover_uri
            type
            subtype
          }
          verb
        }
      }
    }
  }
`;

export interface ActivityLogProps {
  activities?: Activity[];
}

export function ActivityLog({ activities }: ActivityLogProps) {
  const { loading, data, error } = useQuery(GetUserActivityQuery);
  const { humanize } = useHumanizeResourceType();

  useErrorHandler(error);

  return (
    <NavBarLayout className="h-full min-h-screen max-w-screen overflow-clip bg-gray-200">
      <div className="flex">
        {isDepedProd && (
          <AccountAndUserDetailsSidePanel
            className="w-1/4 py-6 px-4 hidden lg:block "
            currentPage="activityLog"
          />
        )}
        <main className="py-6 px-4 flex-1 ">
          <h1 className="text-2xl font-black text-gray-700 ">Activity Log</h1>

          {/* Given the fact that dates are already activities is chronologically arranged from BE */}
          {data ? (
            <div>
              {data?.activityLogScreen.logGroups.map(
                (logGroup: any, i: number) => {
                  return (
                    <div key={i}>
                      <h2 className="text-xl font-gray-700 font-bold mt-6 mb-4">
                        {logGroup.name}
                      </h2>

                      {logGroup.logItems.map((logItem: any, i: number) => {
                        return (
                          <div className="bg-white p-4 mb-4 rounded-lg" key={i}>
                            <div className="flex items-center mb-6">
                              <img
                                className="object-contain w-16 h-16"
                                src={logItem.resource?.coverUri}
                                alt="Course preview"
                              />
                              <div className="p-2 text-base text-gray-700 font-bold ">
                                <p>{humanize(logItem.resource)}</p>
                                <p>{logItem.resource?.name}</p>
                              </div>
                            </div>

                            <div className="text-gray-500">
                              <p>
                                {dateFormattingSchedules(logItem.timestamp)}
                              </p>
                              <p className=" font-bold">
                                {titleCase(logItem.verb)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <LoadingCentered />
          )}
        </main>
      </div>
    </NavBarLayout>
  );
}
