import { useEffect } from 'react';
import { Icon } from '../../../atoms/components';
import ClipLoader from "react-spinners/ClipLoader";
import { openDB, DBSchema } from 'idb';

const DB_NAME = 'fileStore';
const STORE_NAME = 'files';

interface FileStoreDB extends DBSchema {
    [STORE_NAME]: {
        key: string;
        value: Blob;
    };
}

const dbPromise = openDB<FileStoreDB>(DB_NAME, 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME);
        }
    },
});

export async function storeFile(key: string, videoBlob: Blob): Promise<void> {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put(videoBlob, key);
    await tx.done;
}

export async function getFile(key: string): Promise<Blob | undefined> {
    const db = await dbPromise;
    return db.get(STORE_NAME, key);
}

export interface DownloadAndCacheProps {
    urlsToCache: string[];
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    alreadyCached: boolean;
    setAlreadyCached: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DownloadAndCache = ({ urlsToCache, loading, setLoading, alreadyCached, setAlreadyCached }: DownloadAndCacheProps) => {

    useEffect(() => {
        const checkCacheStatus = async () => {
            if (urlsToCache.length > 0) {
                try {
                    const alreadyCachedUrls = await Promise.all(
                        urlsToCache.map(async (url) => {
                            const cachedBlob = await getFile(url);
                            return cachedBlob ? url : null;
                        })
                    );

                    if (alreadyCachedUrls.every((url) => url !== null)) {
                        setAlreadyCached(true);
                    }
                } catch (error) {
                    console.error('Failed to check cache status', error);
                }
            }
        };

        checkCacheStatus();
    }, [setAlreadyCached, urlsToCache]);

    const cacheUrls = async () => {

        if (urlsToCache.length > 0) {
            setLoading(true);
            setAlreadyCached(false);

            try {
                await Promise.all(
                    urlsToCache.map(async (url) => {
                        const bypassUrl = new URL(url);
                        bypassUrl.searchParams.append('bypass-sw', 'true');
                        const response = await fetch(bypassUrl);
                        const blob = await response.blob();
                        await storeFile(url, blob);
                    })
                );

                setLoading(false);
                setAlreadyCached(true);
            } catch (error) {
                console.error('Error downloading and storing files:', error);
                setLoading(false);
            }
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '8px' }}>
                <button onClick={cacheUrls} disabled={loading || alreadyCached}>
                    Download
                </button>
            </div>

            {loading ? (
                <div style={{ marginLeft: '8px' }}>
                    <ClipLoader
                        color="#A78BFA"
                        loading={true}
                        size={16}
                    />
                </div>
            ) : (
                alreadyCached && <Icon name="check_circle" className="text-sm" />
            )}
        </div>
    );
};

export default DownloadAndCache;


