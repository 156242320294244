import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getTitleSlideTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const TITLE_SLIDE_TEMPLATE = `
<div class="w-full flex flex-col items-center ${colorTheme.base} h-full rounded-lg  ">

  <div class="flex font-titillium bg-white w-full px-8 py-4 text-4xl font-black shadow-md justify-between items-center " > 

    <div class="flex w-fit whitespace-nowrap mr-8">
      {{#isGbf}}
        <p class=" text-teal-600 mr-1">GBF</p>  
        <p class=" text-teal-800 " >Class Builder</p>
      {{/isGbf}}
    </div>

    <!-- Progress Bar -->
    <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
      {{#progressBar}}
        <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
          <i class="material-icons " >
            {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
          </i> 
        </section>
        <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
        </div>
      {{/progressBar}}
    </div>
  </div>

  <div id="content-section" class="flex-1  flex flex-col space-y-4 overflow-auto pb-16 h-full  w-full ">
    <div class="flex flex-col items-center justify-center my-8 px-4 object-contain overflow-auto">
      <p class="text-lg mb-3">This lesson topic is all about</p>
      <p class="font-extrabold px-8 text-center text-2xl md:text-4xl break-words">{{title}}</p>
      <button id="nextButton" class="w-fit px-12 py-2 mt-8 rounded-full font-titillium font-semibold text-center ${colorTheme.gradientButton} text-white relative active:opacity-70 hover:shadow-lg hover:opacity-80 transition-all duration-150 mb-16">Get Started</button>
      {{#imageUri}}
        <img class="flex-1 object-contain h-full w-full overflow-auto " src="{{imageUri}}" alt="image" />
      {{/imageUri}}
    </div>
  </div>


</div>
`;

  if (theme === null || theme === undefined) {
    return TITLE_SLIDE_TEMPLATE;
  }

  return TITLE_SLIDE_TEMPLATE;
}
