import { useState, useEffect } from "react";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
} from "../../../pages/components/GbfSlide";
import { Image, InputTextArea } from "../../../atoms/components";
import profilePicAnonymous from "../../../assets/profilePicAnonymous.png";
import { parseColorToStyle } from "../../../helpers/ColorHelper";

export function FreedomWallSlideActivity({
  examItem,
  savedResponses,
  handleResponse,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  const [textInput, setTextInput] = useState<string>(
    getDefaultValue({ examItem, savedResponses }) as string
  );

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    handleResponse({
      item: examItem.uuid,
      long_input: textInput as string,
      short_input: textInput as string,
    });
  }, [textInput]);

  return (
    <div className="">
      <QuestionHeader text={examItem.text || ""} />

      {!hasAnswered ? (
        <InputTextArea
          value={textInput || ""}
          onChange={(e) => {
            setTextInput(e.target.value);
          }}
          disabled={hasAnswered}
          className="min-h-[200px] w-full border-2 border-black disabled:bg-primary-500 disabled:text-white"
        />
      ) : (
        <div className="animate-fadeIn">
          <h1 className="mb-4 font-bold text-gray-700">Responses</h1>
          <div>
            {rightPageFeedback?.freedomWallResponses?.map(
              (freedomWallResponse, index) => {
                if (
                  freedomWallResponse?.longInput &&
                  freedomWallResponse?.longInput?.length > 0
                )
                  return (
                    <div
                      key={freedomWallResponse?.uuid}
                      className={`w-full h-fit p-4  rounded-lg border-2 text-gray-700  bg-white mb-4 border-black ${
                        index === 0 ? "bg-gray-200  " : "text-gray-700 "
                      }`}
                    >
                      <div
                        className={`flex items-center pb-2 border-b border-black mb-2`}
                      >
                        <Image
                          src={profilePicAnonymous}
                          className="w-8 h-8 mr-2 rounded-full bg-primary-100"
                        />
                        <p className="font-bold">Anonymous</p>
                      </div>
                      <p>
                        {freedomWallResponse?.longInput ||
                          freedomWallResponse?.shortInput}
                      </p>
                    </div>
                  );

                return <div key={freedomWallResponse?.uuid}></div>;
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
}
