import {
  InputCheckbox,
  InputDate,
  InputDateTimeLocal,
  InputNumber,
  InputRadio,
  Label,
  Select,
} from "../../../atoms/components";
import { InputText, InputTextProps } from "../../../atoms/components/InputText";
import { monthList } from "../../../helpers/DateFormatting";
import { InputPassword } from "../InputPassword";
import { useState } from "react";

export interface InputWithLabelProps extends InputTextProps {
  _label: string;
}

export function InputWithLabel({
  _label = "label here",
  ...props
}: InputWithLabelProps) {
  return (
    <div className={props.className}>
      <Label htmlFor={props.id} className="block text-gray-400 mt-3 mb-2">
        {props.required ? <span className="text-error-400 mr-1">*</span> : ""}
        {_label}
      </Label>

      <InputWithLabelTypeSwitch {...props} />
    </div>
  );
}

interface InputWithLabelTypeSwitchProps extends InputTextProps {}

function InputWithLabelTypeSwitch(props: InputWithLabelTypeSwitchProps) {
  const [isChecked, setIsChecked] = useState(
    props.type === "checkbox" && props.value !== null
      ? String(props.value).toLowerCase() === "true"
      : false
  );

  switch (props.type) {
    case "text":
    case "email":
      return <InputText aria-required={props.required} {...props} />;
    case "password":
      return <InputPassword aria-required={props.required} {...props} />;
    case "checkbox":
      return (
        <InputCheckbox
          aria-required={props.required}
          {...props}
          value={(!isChecked).toString()}
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(!isChecked);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
        ></InputCheckbox>
      );
    case "number":
      return (
        <InputNumber aria-required={props.required} {...props}></InputNumber>
      );
    case "date":
      return <InputDate {...props} />;
    case "datetime-local":
      return <InputDateTimeLocal {...props} />;
    case "file":
      return <input type="file" {...props} />;
    case "monthDropdown":
      return (
        <Select className={props.className} value={"monthValue"}>
          {monthList.map((month) => {
            return (
              <option key={month} value={month}>
                {month}
              </option>
            );
          })}
        </Select>
      );
    default:
      return <input type="" hidden />;
  }
}
