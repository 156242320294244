import React from "react";
import { Link } from "react-router-dom";

export interface ClaimAccountReminderProps {
  prop?: string;
}

export function ClaimAccountReminder({
  prop = "default value",
}: ClaimAccountReminderProps) {
  return (
    <div className="bg-primary-100 text-primary-600 text-center px-10 py-2">
      Your progress is temporary{" "}
      <Link
        to={"/profile/account-and-user-details"}
        className="text-secondary-600 underline"
      >
        Claim your account
      </Link>{" "}
      or{" "}
      <Link
        to={"/logout?logged-out=true"}
        className="text-secondary-600 underline"
      >
        Login here
      </Link>
    </div>
  );
}
