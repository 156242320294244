import ReactModal from "react-modal";
import { Icon } from "../../../atoms/components";

import style from "./Modal.css";

export interface ModalProps extends ReactModal.Props {
  isOpen: boolean;
  disableCloseButton?: boolean;
  children?: React.ReactNode;

  /* Boolean describing if the modal should be shown or not. Defaults to false. */
}

ReactModal.setAppElement("body");

export function Modal({
  overlayClassName = "bg-gray-900/25 backdrop-blur-sm inset-0 fixed overflow-y-scroll flex flex-col items-center z-50 ",
  // className = "box-content absolute p-5 my-20 overflow-auto bg-white outline-none inset-x-10 md:inset-x-20 lg:inset-x-80 xl:inset-x-1/4 rounded-2xl ",
  // className = "box-content absolute p-5 my-20 overflow-auto bg-white outline-none inset-x-10 md:inset-x-20 lg:inset-x-80 xl:inset-x-1/4 rounded-2xl ",
  className = "",

  ...props
}: ModalProps) {
  const defaultClassName =
    "my-auto box-content bg-white p-5 outline-none rounded-md mx-10";

  return (
    <ReactModal
      style={style}
      closeTimeoutMS={150}
      overlayClassName={overlayClassName}
      className={defaultClassName + " " + className}
      bodyOpenClassName="overflow-hidden"
      {...props}
    >
      <div>
        {!props.disableCloseButton ? (
          <button
            className="p-3 text-xl text-gray-500"
            aria-label="Close Modal"
            onClick={props.onRequestClose}
          >
            <Icon name="close"></Icon>
          </button>
        ) : null}
      </div>

      {props.children}
    </ReactModal>
  );
}
