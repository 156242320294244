import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import {
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  useRecipeCookMutation,
  useResourceEndMutation,
  // useResourceEndMutation,
  useResourceStartMutation,
  useResourceViewScreenQuery,
  useCheckIfXapiCompleteLazyQuery,
  useCheckIfXapiCompleteV1LazyQuery,
} from "../../../generated/graphql";
import { useTimeElapsed } from "../../../helpers/Timer";
import { LoadingCentered } from "../../../molecules/components";
import {
  CourseBlockOpenModals,
  ResourceNavbar,
  ViewResourceBuriBooks,
  ViewResourceHtml,
  ViewResourceMarkdown,
  Modal,
  CantNextXapi,
} from "../../../organisms/components";
import { ResourceCompleteModal } from "../../../organisms/components/ResourceCompleteModal";
import { ViewResourceEmbed } from "../../../organisms/components/ViewResourceEmbed";
import { ViewResourceEPUB } from "../../../organisms/components/ViewResourceEPUB";
import { ViewResourceLink } from "../../../organisms/components/ViewResourceLink";
import { ViewResourcePdf } from "../../../organisms/components/ViewResourcePdf";
import { ViewResourceVideo } from "../../../organisms/components/ViewResourceVideo.tsx";
import { ViewResourceLayout } from "../../../templates/components/ViewResourceLayout";
import { ViewResourceXApi } from "../../../organisms/components";
import { CourseBlock } from "../CourseViewer";

export interface ViewResourceProps {
  resourceUuid?: string;
}

export interface ViewResourceTypeSpecificPropsBase {
  onResourceStart?: () => any;
  onResourceEnd?: () => any;
}

export const QUERY_RESOURCE_VIEW = gql`
  query resourceViewScreen($resourceUuid: ID!) {
    resourceViewScreen: resource_view_screen(resource_uuid: $resourceUuid) {
      resource_uuid
      resource {
        uuid
        type
        subtype
        name
        content {
          ... on ResourceContentXApi {
            uri
          }
          ... on ResourceContentLink {
            uri
          }
          ... on ResourceContentText {
            text
          }
          ... on ResourceContentEmbed {
            uri
          }
          ... on ResourceContentVideo {
            uri
            hlsUri: hls_uri
            dashUri: dash_uri
            tracks {
              uri
            }
            contentType: content_type
            videoDescription: video_description
            subtitleClass: subtitle_class
          }
          ... on ResourceContentAssessment {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
          }
          ... on ResourceContentCourse {
            courseDetails: course_details {
              courseUuid: course_uuid
            }
          }
          ... on ResourceContentBurislide {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
          }
          ... on ResourceContentBuribookWithActivity {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
          }
          ... on ResourceContentYoutube {
            uri
            videoId: video_id
          }
          ... on ResourceContentAudio {
            uri
            contentType: content_type
          }
          ... on ResourceContentEpub {
            uri
          }
          ... on ResourceContentFile {
            uri
            previewGraphicUri: preview_graphic_uri
          }
          ... on ResourceContentPdf {
            uri
            contentType: content_type
            previewGraphicUri: preview_graphic_uri
          }
        }
      }
    }
  }
`;

export const MUTATION_RESOURCE_START = gql`
  mutation ResourceStart(
    $resourceUuid: ID!
    $courseBlockUuid: String
    $courseScheduleUuid: String
  ) {
    resource_start(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
      course_schedule_uuid: $courseScheduleUuid
    )
  }
`;

export const MUTATION_RESOURCE_END = gql`
  mutation ResourceEnd(
    $resourceUuid: ID!
    $courseBlockUuid: String
    $courseScheduleUuid: String
  ) {
    resource_end(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
      course_schedule_uuid: $courseScheduleUuid
    ) {
      next_course_blocks {
        uuid
        title
        resource {
          uuid
        }
        coverUri: cover_uri
      }
    }
  }
`;

export const MUTATION_RECIPE_COOK = gql`
  mutation RecipeCook($ingredient: RecipeIngredients) {
    recipe_cook(ingredient: $ingredient)
  }
`;
const MUTATION_RECIPE_COOK_MULTIPLE = gql`
  mutation RecipeCookMultiple($ingredients: [RecipeIngredients]) {
    recipe_cook_multiple(ingredients: $ingredients)
  }
`;

const QUERY_RESOURCE_VIEW_WITH_REFERENCE_INFO = gql`
  query resourceViewScreenWithReferenceInfoAndUserStatus(
    $resourceUuid: ID!
    $courseBlockUuid: String
  ) {
    resourceViewScreen: resource_view_screen(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
    ) {
      resourceUuid: resource_uuid
      resource {
        uuid
        type
        subtype
        name
        content {
          ... on ResourceContentXApi {
            uri
          }
          ... on ResourceContentLink {
            uri
          }
          ... on ResourceContentText {
            text
          }
          ... on ResourceContentEmbed {
            uri
          }
          ... on ResourceContentVideo {
            uri
            hlsUri: hls_uri
            dashUri: dash_uri
            tracks {
              uri
            }
            contentType: content_type
            videoDescription: video_description
            subtitleClass: subtitle_class
          }
          ... on ResourceContentAssessment {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
            examTimeslot: exam_timeslot {
              outline {
                exam {
                  timeLimit: time_limit
                }
              }
              maxAllowedSubmissions: max_allowed_submissions
              userSubmissions: user_submissions {
                totalCount: total_count
              }
            }
          }
          ... on ResourceContentCourse {
            courseDetails: course_details {
              courseUuid: course_uuid
            }
          }
          ... on ResourceContentBurislide {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
          }
          ... on ResourceContentBuribookWithActivity {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
          }
          ... on ResourceContentYoutube {
            uri
            videoId: video_id
          }
          ... on ResourceContentAudio {
            uri
            contentType: content_type
          }
          ... on ResourceContentEpub {
            uri
          }
          ... on ResourceContentFile {
            uri
            previewGraphicUri: preview_graphic_uri
          }
          ... on ResourceContentPdf {
            uri
            contentType: content_type
            previewGraphicUri: preview_graphic_uri
          }
        }
      }
      courseBlock: course_block {
        referenceInfo: reference_info {
          referenceId: reference_id
          customPassingMark: custom_passing_mark
          maxAllowedSubmissions: max_allowed_submissions
          passOnSubmit: pass_on_submit
        }
        userstatus {
          is_complete
          status
          grade_percent
          score_raw
          score_min
          score_max
        }
        maxSubmissionsReached: max_submissions_reached
        title
      }
    }
  }
`;

export const CHECK_XAPI_COMPLETION_QUERY = gql`
  query CheckIfXapiCompleteV1($resourceUuid: ID!, $courseBlockUuid: String) {
    resource_view_screen(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
    ) {
      course_block {
        has_user_completed
      }
    }
  }
`;
function useViewResourceHooks() {
  const [isResourceDone, setIsResourceDone] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const resourceUuid = searchParams.get("resource_uuid");
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseScheduleUuid = searchParams.get("course_schedule_uuid");
  const courseUuid = searchParams.get("course_uuid");
  // const isInCourse: boolean = !!courseBlockUuid && !!courseScheduleUuid;
  const isInCourse: boolean = !!courseBlockUuid;

  // For navigation
  const navigate = useNavigate();

  // Resource complete modal
  const [resourceCompleteModalOpen, setResourceCompleteModalOpen] =
    useState(false);

  // Course block resource modal
  const [
    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
  ] = useState(false);
  const [nextCourseBlockUuid, setNextCourseBlockUuid] = useState<string>();
  const [nextResourceUuid, setNextResourceUuid] = useState<string>();

  // Loading resource view data
  const {
    loading: resourceViewScreenLoading,
    data: resourceViewScreenData,
    error: resourceViewScreenError,
  } = useResourceViewScreenQuery({
    variables: {
      resourceUuid: resourceUuid || "",
    },
  });

  // Resource Start mutate
  const [
    resourceStartMutateFunction,
    {
      loading: resourceStartLoading,
      data: resourceStartData,
      error: resourceStartError,
    },
  ] = useResourceStartMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: courseBlockUuid,
      courseScheduleUuid: courseScheduleUuid,
    },
  });

  const [
    resourceEndMutateFunction,
    {
      loading: resourceEndMutateLoading,
      data: resourceEndMutateData,
      error: resourceEndMutateError,
    },
  ] = useResourceEndMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: courseBlockUuid,
      courseScheduleUuid: courseScheduleUuid,
    },
  });

  const [checkXapiCompletion, { data: checkXapiCompletionData }] =
    useCheckIfXapiCompleteV1LazyQuery({
      onCompleted: (data) => {
        if (data.resource_view_screen?.course_block?.has_user_completed) {
          setIsResourceDone(true);
        } else {
          setIsXapiCompleteModalOpen(true);
        }
      },
      fetchPolicy: "cache-and-network",
    });

  const [recipeCookMutateFunction] = useRecipeCookMutation();

  const { reset, getTimeElapsedMs } = useTimeElapsed();

  useErrorHandler(
    resourceViewScreenError || resourceStartError || resourceEndMutateError
  );

  // Time Spent Data
  const [watchDuration, setWatchDuration] = useState<number | null>(null);
  // User Activity Variables
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  // On End
  const [videoFinished, setVideoFinished] = useState<boolean>(false);

  // Xapi Complete Modal
  const [isXapiCompleteModalOpen, setIsXapiCompleteModalOpen] =
    useState<boolean>(false);

  return {
    isResourceDone,
    setIsResourceDone,

    resourceUuid,
    courseUuid,
    courseBlockUuid,
    courseScheduleUuid,
    isInCourse,

    navigate,

    resourceCompleteModalOpen,
    setResourceCompleteModalOpen,

    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
    nextCourseBlockUuid,
    setNextCourseBlockUuid,
    nextResourceUuid,
    setNextResourceUuid,

    resourceViewScreenLoading,
    resourceViewScreenData,

    resourceStartMutateFunction,
    resourceEndMutateFunction,
    resourceEndMutateData,

    recipeCookMutateFunction,

    getTimeElapsedMs,
    reset,

    watchDuration,
    setWatchDuration,

    intervalId,
    setIntervalId,

    videoFinished,
    setVideoFinished,

    checkXapiCompletion,

    isXapiCompleteModalOpen,
    setIsXapiCompleteModalOpen,
  };
}

export function ViewResource({}: ViewResourceProps) {
  const {
    isResourceDone,
    setIsResourceDone,

    resourceUuid,
    courseUuid,
    courseBlockUuid,
    courseScheduleUuid,
    isInCourse,

    navigate,

    resourceCompleteModalOpen,
    setResourceCompleteModalOpen,

    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
    nextCourseBlockUuid,
    setNextCourseBlockUuid,
    nextResourceUuid,
    setNextResourceUuid,

    resourceViewScreenLoading,
    resourceViewScreenData,

    resourceStartMutateFunction,
    resourceEndMutateFunction,
    resourceEndMutateData,

    recipeCookMutateFunction,

    getTimeElapsedMs,
    reset,

    watchDuration,
    setWatchDuration,

    intervalId,
    setIntervalId,

    videoFinished,
    setVideoFinished,

    checkXapiCompletion,

    isXapiCompleteModalOpen,
    setIsXapiCompleteModalOpen,
  } = useViewResourceHooks();

  const type = resourceViewScreenData?.resourceViewScreen?.resource?.type;
  const subtype = resourceViewScreenData?.resourceViewScreen?.resource?.subtype;
  const name = resourceViewScreenData?.resourceViewScreen?.resource?.name;
  const uuid = resourceViewScreenData?.resourceViewScreen?.resource?.uuid;
  const content = resourceViewScreenData?.resourceViewScreen?.resource?.content;

  const [searchParams, setSearchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");

  // Behaviour for next button
  const onNext = () => {
    if (type === "xapi") {
      checkXapiCompletion({
        variables: {
          resourceUuid: resourceUuid as string,
          courseBlockUuid: courseBlockUuid,
        },
      });
    } else {
      if (isResourceDone) {
        setResourceCompleteModalOpen(true);
      }
      setIsResourceDone(true);
    }
  };

  const finishOnStart = () => {
    resourceEndMutateFunction();

    recordAttempt();

    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: isInCourse ? "resource_in_course" : "resource",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
              block_id: courseBlockUuid,
              course_id: courseUuid,
            },
            verb: "completed",
            context: { block_id: courseBlockUuid, course_id: courseUuid },
          },
        },
      },
    });
  };

  // Behavior when playing Video
  const startTimer = () => {
    let watchInterval = setInterval(() => {
      setWatchDuration((watchDuration) =>
        watchDuration ? watchDuration + 1 : 1
      );
    }, 1000);
    setIntervalId(watchInterval);

    // Just incase need niyo po ito
    setVideoFinished(false);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
  };

  const onEnd = () => {
    setVideoFinished(true);
    setIsResourceDone(true);
  };

  // Behaviour for close button
  const recordAttempt = () => {
    const duration = getTimeElapsedMs();
    reset();

    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: isInCourse ? "resource_in_course" : "resource",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
              block_id: courseBlockUuid,
              course_id: courseUuid,
            },
            result: {
              seconds: watchDuration || duration / 1000,
            },
            verb: "attempted",
            context: { block_id: courseBlockUuid, course_id: courseUuid },
          },
        },
      },
    });
  };

  // Invoke only on resource done
  useEffect(() => {
    if (isResourceDone) {
      // All types inside this if statement will trigger mutations. All other types has already done this using finishOnStart
      if (
        [
          "video",
          "buribook-with-activity",
          "burislides",
          "assessment",
          "course",
          "xapi",
        ].includes(type as string)
      ) {
        resourceEndMutateFunction();

        recordAttempt();

        recipeCookMutateFunction({
          variables: {
            ingredient: {
              recipe_name: isInCourse ? "resource_in_course" : "resource",
              body: {
                timestamp: new Date().toISOString(),
                object: {
                  resource_id: resourceUuid,
                  block_id: courseBlockUuid,
                  course_id: courseUuid,
                },
                verb: "completed",
                context: { block_id: courseBlockUuid, course_id: courseUuid },
              },
            },
          },
        });
      }

      setResourceCompleteModalOpen(true);
      setIsResourceDone(false);
    }
  }, [isInCourse, isResourceDone]);

  // Invoke at start
  useEffect(() => {
    window.scrollTo(0, 0);
    resourceStartMutateFunction();

    if (content?.__typename !== "ResourceContentCourse" && !isInCourse) {
      recipeCookMutateFunction({
        variables: {
          ingredient: {
            recipe_name: "resource",
            body: {
              timestamp: new Date().toISOString(),
              object: { resource_id: resourceUuid },
              verb: "attempted",
              context: { resource_id: resourceUuid },
            },
          },
        },
      });
      if (
        content?.__typename !== "ResourceContentBuribookWithActivity" &&
        content?.__typename !== "ResourceContentAssessment" &&
        content?.__typename !== "ResourceContentBurislide"
      ) {
        recipeCookMutateFunction({
          variables: {
            ingredient: {
              recipe_name: "resource",
              body: {
                timestamp: new Date().toISOString(),
                object: {
                  resource_id: resourceUuid,
                },
                verb: "completed",
                context: {
                  resource_id: resourceUuid,
                },
              },
            },
          },
        });
      }
    }
    if (isInCourse) {
      //cook statement here for resource inside course
      recipeCookMutateFunction({
        variables: {
          ingredient: {
            recipe_name: "resource_in_course",
            body: {
              timestamp: new Date().toISOString(),
              object: {
                resource_id: resourceUuid,
                block_id: courseBlockUuid,
                course_id: courseUuid,
              },
              verb: "attempted",
              context: {
                block_id: courseBlockUuid,
                course_id: courseUuid,
              },
            },
          },
        },
      });
    }
  }, []);

  return (
    <ViewResourceLayout
      navbar={
        <ResourceNavbar
          resourceName={name || ""}
          isNextEnabled={isInCourse}
          onNext={onNext}
          onClose={recordAttempt}
          courseBlockUuid={courseBlockUuid as string}
        />
      }
    >
      {courseBlockResourceModalsAllowOpen ? (
        <CourseBlockOpenModals
          allowOpenModal={courseBlockResourceModalsAllowOpen}
          onModalClose={() => setCourseBlockResourceModalsAllowOpen(false)}
          courseBlockUuid={nextCourseBlockUuid}
          courseUuid={courseUuid as string}
          resourceUuid={nextResourceUuid}
        ></CourseBlockOpenModals>
      ) : (
        <></>
      )}

      <ResourceCompleteModal
        nextBlocks={
          resourceEndMutateData?.resource_end?.[
            "next_course_blocks"
          ] as CourseBlock[]
        }
        isOpen={resourceCompleteModalOpen}
        onRequestClose={() => setResourceCompleteModalOpen(false)}
        onBackToMainPage={() => {
          setResourceCompleteModalOpen(false);

          if (!courseUuid) {
            if (searchParams.get("search_value")) {
              navigate(
                `/screen/search?search_value=${searchParams.get(
                  "search_value"
                )}`
              );
            } else {
              navigate(`/screen/${parentScreenName}`);
            }
          } else {
            navigate(
              `/courseviewer?course_uuid=${searchParams.get(
                "course_uuid"
              )}&from_screen=${parentScreenName}${
                parentScreenName === "search"
                  ? `&search_value=${searchParams.get("search_value")}`
                  : ""
              }`
            );
          }
        }}
        onBlockClick={(block) => {
          setNextCourseBlockUuid(block.uuid || "");
          setNextResourceUuid(block.resource?.uuid || "");
          setCourseBlockResourceModalsAllowOpen(true);

          setResourceCompleteModalOpen(false);
        }}
      />

      <Modal
        isOpen={isXapiCompleteModalOpen}
        onRequestClose={() => setIsXapiCompleteModalOpen(false)}
      >
        <CantNextXapi closeModal={() => setIsXapiCompleteModalOpen(false)} />
      </Modal>

      {resourceViewScreenLoading ? (
        <LoadingCentered />
      ) : (
        <ResourceTypeSwitch
          resourceType={type || ""}
          resourceSubtype={subtype || ""}
          resourceUuid={resourceUuid || ""}
          src={content}
          uuid={uuid as string}
          blockUuid={courseBlockUuid as string}
          scheduleUuid={courseScheduleUuid as string}
          onEndResource={() => setIsResourceDone(true)}
          parentScreenName={parentScreenName as string}
          startTimer={startTimer}
          stopTimer={stopTimer}
          finishOnStart={finishOnStart}
          onEnd={onEnd}
        ></ResourceTypeSwitch>
      )}
    </ViewResourceLayout>
  );
}

const ResourceTypeSwitch = ({
  resourceUuid,
  resourceType,
  resourceSubtype,
  src,
  uuid,
  blockUuid,
  scheduleUuid,
  parentScreenName,
  onEndResource,
  startTimer,
  stopTimer,
  onEnd,
  finishOnStart,
}: {
  resourceUuid: string;
  resourceType?: string;
  resourceSubtype?: string;
  src?: any;
  uuid?: string;
  blockUuid?: string;
  scheduleUuid?: string;
  onEndResource: () => any;
  parentScreenName?: string;
  startTimer?: () => any;
  stopTimer?: () => any;
  onEnd?: () => any;
  finishOnStart?: () => any;
}) => {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid");
  const location = useLocation();

  // To Auto Finish, Put here to ensure data is received
  useEffect(() => {
    if (
      ![
        "video",
        "buribook-with-activity",
        "burislides",
        "assessment",
        "course",
        "xapi",
      ].includes(resourceType as string)
    ) {
      if (finishOnStart) finishOnStart();
    }
  }, []);

  if (resourceType === "video") {
    return (
      <ViewResourceVideo
        file={src}
        onPlay={startTimer}
        onPause={stopTimer}
        onEnd={onEnd}
      ></ViewResourceVideo>
    );
  } else if (resourceType === "pdf") {
    return <ViewResourcePdf uri={src.uri}></ViewResourcePdf>;
  } else if (resourceType === "html") {
    return <ViewResourceHtml src={src.uri} />;
  } else if (resourceType === "embed" || resourceType === "youtube") {
    return (
      <div className="h-screen">
        <ViewResourceEmbed src={src.uri} />
      </div>
    );
  } else if (
    resourceType === "buribook-with-activity" ||
    resourceType === "burislides"
  ) {
    return (
      <ViewResourceBuriBooks
        resourceUuid={resourceUuid}
        onLastPage={() => onEndResource()}
      />
    );
  } else if (resourceType === "epub") {
    return <ViewResourceEPUB src={src.uri} />;
  } else if (resourceType === "link") {
    return <ViewResourceLink link={src.uri} />;
  } else if (resourceType === "text") {
    return <ViewResourceMarkdown text={src.text} />;
  } else if (resourceType === "xapi") {
    return <ViewResourceXApi uri={src.uri} />;
  } else if (resourceType === "assessment") {
    if (resourceSubtype === "file-upload") {
      return <p>REMOVED FILE UPLOAD COMPONENT FROM V1</p>;
    } else {
      return (
        <Navigate
          to={
            blockUuid && scheduleUuid
              ? `/assessmentviewer?exam_timeslot_uuid=${
                  src.examDetails.examTimeslotUuid
                }&exam_uuid=${
                  src.examDetails.examUuid
                }&course_block_uuid=${blockUuid}&course_schedule_uuid=${scheduleUuid}&resource_uuid=${uuid}&course_uuid=${courseUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`
              : blockUuid
              ? `/assessmentviewer?exam_timeslot_uuid=${
                  src.examDetails.examTimeslotUuid
                }&exam_uuid=${
                  src.examDetails.examUuid
                }&course_block_uuid=${blockUuid}&resource_uuid=${uuid}&course_uuid=${courseUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`
              : `/assessmentviewer?exam_timeslot_uuid=${
                  src.examDetails.examTimeslotUuid
                }&exam_uuid=${
                  src.examDetails.examUuid
                }&resource_uuid=${uuid}&course_uuid=${courseUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`
          }
          state={{
            scrollYValue: location.state ? location.state.scrollYValue : 0,
          }}
          replace={true}
        />
      );
    }
  } else if (resourceType === "course") {
    return (
      <Navigate
        to={`/courseviewer?course_uuid=${
          src.courseDetails.courseUuid
        }&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`}
        replace={true}
      />
    );
  } else return <></>;
};
