import {
  CourseResource,
  ScreenModal,
} from "../../../molecules/components/CastBlocks";
import { Modal, TimeChancesPrompt } from "../../../organisms/components";
import { CourseCard } from "../../../organisms/components/CourseCard";
import { CourseCardChooseBatch } from "../../../organisms/components/CourseCardChooseBatch";
export interface EnrollmentModalsProps {
  courseCardModal?: boolean;
  setCourseCardModal?: React.Dispatch<React.SetStateAction<boolean>>;
  batchModal?: boolean;
  setBatchModal?: React.Dispatch<React.SetStateAction<boolean>>;
  courseResource?: CourseResource;
  screenModal?: ScreenModal;
  resourceUuid?: string;
  timePromptModal?: boolean;
  setTimePromptModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EnrollmentModals({
  courseCardModal,
  setCourseCardModal,
  batchModal,
  setBatchModal,
  courseResource,
  screenModal,
  resourceUuid,
  timePromptModal,
  setTimePromptModal,
}: EnrollmentModalsProps) {
  return (
    <div data-testid="enrollment-modal-container">
      <Modal
        className="w-9/12 md:w-1/2"
        isOpen={courseCardModal as boolean}
        onRequestClose={() => {
          if (setCourseCardModal) setCourseCardModal(false);
        }}
      >
        <CourseCard
          courseResource={courseResource}
          screenModal={screenModal as ScreenModal}
        />
      </Modal>
      {/* Modal Choose batchModal Goes Here */}
      <Modal
        className="relative w-9/12 md:w-1/2"
        isOpen={batchModal as boolean}
        onRequestClose={() => {
          if (setBatchModal) setBatchModal(false);
        }}
      >
        <CourseCardChooseBatch resourceUuid={resourceUuid as string} />
      </Modal>
      {/*Timer and Chances Prompt Modal Goes Here*/}
      <Modal
        isOpen={timePromptModal as boolean}
        onRequestClose={() => {
          if (setTimePromptModal) setTimePromptModal(false);
        }}
        className="w-9/12 sm:w-80"
      >
        <TimeChancesPrompt
          resourceUuid={resourceUuid}
          onCancel={() => {
            if (setTimePromptModal) setTimePromptModal(false);
          }}
        />
      </Modal>
    </div>
  );
}
