import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { onError } from '@apollo/client/link/error';

const uploadLink = createUploadLink({
  uri: "/api/graphql",
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Unauthorized") {
        apolloClient.clearStore()
        apolloClient.resetStore()
      }
    });
  }
});

const link = ApolloLink.from([errorLink, uploadLink]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        account_and_user_details_screen: {
          // Custom merge function, Need gawin pag 2 times gagamitin yung query sa iisang page / component. Cache stuff
          merge(existing, incoming) {
            if (existing) {
              return existing;
            }

            return incoming;
          },
        },
      },
    },
  },
})

if (process.env.REACT_APP_PWA_ENABLED === 'true') {
  async function initializeCache() {
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      debug: true,
      maxSize: false,
    });
  }

  initializeCache();
}

export const apolloClient = new ApolloClient({
  cache: cache,
  link: link,
  connectToDevTools: true,
});




