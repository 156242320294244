import {
  Footer,
  NeedHelp,
  ResetPasswordForm,
} from "../../../molecules/components";
import { SignupLayout } from "../../../templates/components";

export interface ResetPasswordProps {
  prop?: string;
}

export function ResetPassword({ prop = "default value" }: ResetPasswordProps) {
  return (
    <SignupLayout
      _type="reset"
      footer={<Footer _type="signup" />}
      signupform={<ResetPasswordForm />}
      help={<NeedHelp _type="signup" />}
    ></SignupLayout>
  );
}
