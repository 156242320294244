import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Icon, Image, Markdown } from "../../../atoms/components";
import { resourceTypeIcon } from "../../../helpers";
import { CourseBlock } from "../../../pages/components/CourseViewer";
import { gql, useLazyQuery } from "@apollo/client";
import { Loading } from "../../../atoms/components";
import { useEffect, useState } from "react";

export const GetCourseModalQuery = gql`
  query Course_view_screen($courseUuid: String!) {
    courseViewScreen: course_view_screen(course_uuid: $courseUuid) {
      course {
        id
        coverUri: cover_uri
        description
        title
      }
    }
  }
`;

export const GetResourceModalQuery = gql`
  query Resource_view_screen($resourceUuid: ID!) {
    resourceViewScreen: resource_view_screen(resource_uuid: $resourceUuid) {
      resource {
        id
        coverUri: cover_uri
        description
        name
        type
      }
    }
  }
`;

export interface ResourceCardProps {
  block?: CourseBlock;
  description?: string;
  courseUuid?: string;
  resourceUuid?: string;
  isInfoCard?: boolean;
}

export function ResourceCard({
  block,
  description = `Lorem sd ipsum dolor sit amet, consectetur adipisicing elit. Illum, commodi unde quos pariatur quam aliquid exercitationem eum obcaecati quasi quia ex velit soluta error, voluptatem dolorem nihil! Rerum, corporis aspernatur \n\n ------------------- \n Available in small picture book format and as an app from the App Store and Google Play  \n\n **ISBN**: 978-971-588-317-1 (88)   \n **Published**: 2016   \n **Language**: Filipino(Tagalog) with English Translation   \n **Age**: 5+   \npages: 32`,

  courseUuid = "",
  resourceUuid,
  isInfoCard,
}: ResourceCardProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const goToBlock = () =>
    navigate({
      pathname: "../view",
      search: `?course_block_uuid=${block?.uuid}${
        block?.resource?.uuid
          ? `&resource_uuid=${block.resource?.uuid}&course_uuid=${courseUuid}&from_screen=${parentScreenName}`
          : ""
      }`,
    });

  const [blockToLoad, setBlockToLoad] = useState<any>();
  // const { data, error, loading } = useQuery(GetCourseModalQuery, {
  //   variables: { courseUuid: courseUuid },
  // });

  // Course Modal Lazy Query
  const [
    getCourseModal,
    { data: courseData, error: courseError, loading: courseLoading },
  ] = useLazyQuery(GetCourseModalQuery, {
    variables: {
      courseUuid: courseUuid,
    },
    onCompleted(data) {
      setBlockToLoad(data.courseViewScreen.course);
    },
  });

  // Resource Modal Lazy Query
  const [
    getResourceModal,
    { data: resourceData, error: resourceError, loading: resourceLoading },
  ] = useLazyQuery(GetResourceModalQuery, {
    variables: {
      resourceUuid: resourceUuid,
    },
    onCompleted(data) {
      setBlockToLoad(data.resourceViewScreen.resource);
    },
  });

  // Run on first try
  useEffect(() => {
    if (block) {
      setBlockToLoad(block);
    } else if (resourceUuid) {
      getResourceModal();
    } else {
      getCourseModal();
    }
  }, [blockToLoad]);

  return (
    <div>
      {blockToLoad ? (
        <div className="p-4 flex flex-col ">
          <header className="flex flex-col items-center mb-4 w-full">
            <Image
              src={blockToLoad.coverUri}
              alt={blockToLoad.title ? blockToLoad.title : blockToLoad.name}
              className="w-36 h-36"
            ></Image>
            <Icon
              name={resourceTypeIcon(
                blockToLoad.type !== undefined ? blockToLoad.type : "course"
              )}
              className="text-2xl text-gray-500"
            ></Icon>
            <h1 className="text-xl font-black ">
              {blockToLoad.title ? blockToLoad.title : blockToLoad.name}
            </h1>
          </header>

          {blockToLoad.description ? (
            <main className="text-gray-500 flex-1 mb-4">
              <h2 className="text-lg text-gray-700 font-black border-b-2 mb-4">
                Description
              </h2>
              <Markdown
                className="break-words"
                text={blockToLoad.description}
              ></Markdown>
            </main>
          ) : (
            <></>
          )}

          {isInfoCard ? null : <Button onClick={goToBlock}>Continue</Button>}
        </div>
      ) : (
        // Didn't use loading centered here, absolute po yung loading centered e nalabas po siya sa modal.
        <div className="w-1/3 mx-auto">
          <Loading></Loading>
        </div>
      )}
    </div>
  );
}
