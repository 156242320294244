import { Footer, LoginForm, NeedHelp } from "../../../molecules/components";
import { LoginLayout } from "../../../templates/components";
import { gql, useQuery } from "@apollo/client";
import { useGetLoginScreenQuery } from "../../../generated/graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { CastNotFound } from "../../../organisms/components";

export interface LoginProps {
  prop?: string;
}

export const QUERY_LOGIN_SCREEN = gql`
  query GetLoginScreen($domain: String) {
    login_screen(domain: $domain) {
      cast_id
      banner_url
      hero_url
      nav_url
      client_id
      favicon_url
      cast {
        attributes {
          allow_guest_login
          default_login_as_guest
        }
      }
      is_signup_enabled
    }
  }
`;

export function getLoginUrl(
  { clientId }: { clientId: string },
  isGuestLogin: boolean = false
) {
  let loginUrl = new URL(`${process.env.REACT_APP_PASS_URI}/oauth/authorize`);

  loginUrl.searchParams.set("client_id", clientId);

  //Auto change login depending on the domain
  loginUrl.searchParams.set(
    "redirect_uri",
    `${window.location.origin}/auth/new` // For callback backwards compatibility
  );

  //Set response type
  loginUrl.searchParams.set("response_type", "code");

  //Set default scope
  loginUrl.searchParams.set("scope", "openid profile email groups");

  if (isGuestLogin) {
    loginUrl.searchParams.set("skip_prompt", "true");
  }

  // // Set PCKE if provided
  // if (options.codeChallenge) {
  //   loginUrl.searchParams.set("code_challenge", options?.codeChallenge);
  //   loginUrl.searchParams.set("code_challenge_method", "S256");
  // }

  // // Set state if provided
  // if (options.state) {
  //   loginUrl.searchParams.set("state", options?.state);
  // }

  // // Set nonce if provided
  // if (options.nonce) {
  //   loginUrl.searchParams.set("nonce", options?.nonce);
  // }

  return loginUrl;
}

export function getGuestLoginUrl({ clientId }: { clientId: string }) {
  let guestLoginUrl = new URL(
    `${process.env.REACT_APP_PASS_URI}/users/sign_up`
  );

  guestLoginUrl.searchParams.set("client_id", clientId);

  guestLoginUrl.searchParams.set("guest_login", "true");

  guestLoginUrl.searchParams.set(
    "redirect_url",
    getLoginUrl({ clientId: clientId || "" }, true).toString()
  );
  return guestLoginUrl;
}

export const getCastlmsDomain = (host: string) => {
  return host;
};

export function Login({ prop = "default value" }: LoginProps) {
  const domain = getCastlmsDomain(window.location.host);
  // const domain = "bokchoy.cast-learner-react.buri.dev";
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirect = searchParams.get("redirect");
  localStorage.setItem("redirect", redirect || "/");

  // Use data
  const { loading, data, error } = useQuery(QUERY_LOGIN_SCREEN, {
    variables: {
      domain,
    },
    fetchPolicy: "no-cache",
    // onCompleted: (data) => {
    //   const { client_id: clientId, hero_url: heroUrl } =
    //     data?.login_screen || {};
    //   if (
    //     data?.login_screen?.cast.attributes.default_login_as_guest &&
    //     searchParams.get("logged-out") !== "true" &&
    //     searchParams.get("logged-out") === null
    //   ) {
    //     window.location.href = getGuestLoginUrl({
    //       clientId: clientId || "",
    //     }).href;
    //   } else {
    //   }
    // },
  });

  if (loading && !error) {
    return <></>;
  }

  if (!loading && error && error.message === "404: Not Found") {
    return <CastNotFound />;
  }

  const { client_id: clientId, hero_url: heroUrl } = data?.login_screen || {};

  const gotoLoginLink = () => {
    window.location.href = getLoginUrl({ clientId: clientId || "" }).href;
  };

  const goToGuestLoginLink = () => {
    window.location.href = getGuestLoginUrl({ clientId: clientId || "" }).href;
    // window.location.href = getGuestLoginUrl({ clientId }).href;
  };

  return (
    <LoginLayout
      loginform={
        <LoginForm
          heroUrl={heroUrl || ""}
          onLoginButtonClick={gotoLoginLink}
          onGuestLoginButtonClick={goToGuestLoginLink}
          allowGuestLogin={
            data?.login_screen?.cast.attributes.allow_guest_login
          }
          allowSignUp={data?.login_screen?.is_signup_enabled || false}
          clientId={clientId as string}
        />
      }
      headerLogo={data?.login_screen?.nav_url}
      help={<NeedHelp _type="login" clientId={clientId as string} />}
      footer={<Footer _type="login" />}
      banner={data?.login_screen?.banner_url}
    ></LoginLayout>
  );
}
