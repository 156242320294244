import { gql } from "@apollo/client";
import { singletonHook } from "react-singleton-hook";
import {
  HumanizedResourceTypesQuery,
  useHumanizedResourceTypesQuery,
} from "../../generated/graphql";
import { apolloClient } from "../../apollo-client";

const TYPES = new Map([
  ["course", "Course"],
  ["video", "Video"],
  ["pdf", "PDF"],
  ["assessment", "Assessment"],
  ["buribookWithActivity", "BuriBooks w/ Activity"],
  ["burislides", "Slides"],
  ["xapi", "xAPI"],
  ["link", "Link"],
  ["embed", "Embed"],
  ["epub", "Epub"],
  ["googleSlide", "Google Slide"],
  ["googleForm", "Google Form"],
  ["googleDoc", "Google Doc"],
  ["googleSheet", "Google Sheet"],
]);

const SUBTYPES = new Map([
  ["fileUpload", "File Submission"],
  ["freedomWall", "Freedom Wall"],
  ["email", "Email"],
  ["googleSlide", "Google Slide"],
  ["googleForm", "Google Form"],
  ["googleDoc", "Google Doc"],
  ["googleSheet", "Google Sheet"],
  ["youtube", "YouTube"],
  ["articulate", "Articulate"],
  ["markdown", "Markdown"],
  ["canva", "Canva"],
]);

type ResourceTyping =
  | string
  | null
  | undefined
  | Partial<{
      type: string | null | undefined;
      subtype: string | null | undefined;
    }>;

export function resourceType(
  value: ResourceTyping,
  MODIFIED_TYPES: Map<string, string | null | undefined> = TYPES,
  MODIFIED_SUBTYPES: Map<string, string | null | undefined> = SUBTYPES
) {
  let type: string | null | undefined;

  if (!value) {
    return null;
  }

  if (typeof value === "string") {
    type = MODIFIED_SUBTYPES.get(value) || MODIFIED_TYPES.get(value) || value;
  } else {
    type =
      MODIFIED_SUBTYPES.get(value.subtype || "") ||
      MODIFIED_TYPES.get(value.type || "") ||
      value.subtype ||
      value.type ||
      null;
  }

  return type;
}

// Hiniwalay ko for easier testing
export const humanizeMapTransform = ({
  data,
  loading,
}: {
  data: HumanizedResourceTypesQuery | undefined;
  loading: boolean;
}) => {
  const MODIFIED_TYPES = new Map(TYPES);
  const MODIFIED_SUBTYPES = new Map(SUBTYPES);

  // Replace MAP data from server
  if (!loading && data) {
    data.humanizedResourceTypes?.buresource?.resource?.type?.forEach(
      (type: any) => {
        MODIFIED_TYPES.set(type[0], type[1] || TYPES.get(type[0]));
      }
    );

    data.humanizedResourceTypes?.buresource?.resource?.subtype?.forEach(
      (subtype: any) => {
        MODIFIED_SUBTYPES.set(
          subtype[0],
          subtype[1] || SUBTYPES.get(subtype[0])
        );
      }
    );
  }

  const humanize = (value: ResourceTyping) => {
    return resourceType(value, MODIFIED_TYPES, MODIFIED_SUBTYPES);
  };

  return { humanize };
};

export const HUMANIZED_RESOURCE_TYPES = gql`
  query HumanizedResourceTypes {
    humanizedResourceTypes: humanized_resource_types {
      buresource {
        resource {
          type
          subtype
        }
      }
    }
  }
`;

// Use singleton so that we only make one request for the humanized resource types
export const useHumanizeResourceType = singletonHook(
  { humanize: resourceType },
  () => {
    const { data, loading } = useHumanizedResourceTypesQuery({
      client: apolloClient,
    });

    return humanizeMapTransform({
      data,
      loading,
    });
  }
);
