import {
  CourseUserCompletedBlockIndicator,
  CourseUserCompletedBlockIndicatorDomainChild,
  CourseUserCompletedBlockIndicatorDomainIndicator,
  Maybe,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { TrainingIndicator } from "../TrainingIndicator/TrainingIndicator";

export interface TrainingDomainProps {
  title: string;
  points: number;
  childList: (CamelCasedType<CourseUserCompletedBlockIndicatorDomainChild> | null)[];
  indicators: (CamelCasedType<CourseUserCompletedBlockIndicatorDomainIndicator> | null)[];
}

export function TrainingDomain({
  title,
  points,
  childList,
  indicators,
}: TrainingDomainProps) {
  return (
    <div className="inline-block w-full p-4 space-y-3 bg-white rounded-lg">
      <div className="flex flex-row items-center justify-between w-full space-x-4">
        <h2 className="w-full text-lg font-bold text-gray-700">{title}</h2>
        <span className="w-full ml-auto text-sm font-bold text-right truncate text-secondary-500">
          {points}{" "}
          {indicators.length > 0 && indicators[0]?.displayUnitName
            ? indicators[0]?.displayUnitName
            : "pt(s)"}
        </span>
      </div>
      {indicators ? (
        <ul className="space-y-3 font-bold text-gray-500 list-disc text-md ml-7">
          <TrainingIndicator indicators={indicators || []} />
          {childList.map((child, i) => (
            <TrainingIndicator
              title={child?.name || ""}
              indicators={child?.indicators || []}
              key={i}
            />
          ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}
