import { gql, useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { colorHelperBase, ColorObj } from "../../../helpers/ColorHelper";
import { LoadingCentered } from "../../../molecules/components";
import {
  CourseViewerBlock,
  ResourceNavbar,
  ResourceNotAccessible,
} from "../../../organisms/components";
import { useEffect, useLayoutEffect, useRef } from "react";
import {
  useCourseViewerQuery,
  Course as CourseOriginal,
  CourseViewScreen as CourseViewScreenOriginal,
  CourseBlock as CourseBlockOriginal,
  useCourseViewerLazyQuery,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { useOnlineStatus } from "../../../helpers/OnlineStatus/OnlineStatus";

export type Course = CamelCasedType<CourseOriginal>;
export type CourseViewScreen = CamelCasedType<CourseViewScreenOriginal>;
export type CourseBlock = CamelCasedType<CourseBlockOriginal>;

export interface Style {
  base: {
    style: ColorObj;
    fontFamily: string;
  };
  cardHeader: {
    style: ColorObj;
    fontFamily: string;
  };
}

export interface ModalObject {
  state: boolean;
  timerState?: boolean;
}

export interface CourseViewerProps {}

export function CourseViewer({}: CourseViewerProps) {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid");
  const scheduleUuid = searchParams.get("course_schedule_uuid");

  // For checking if online
  const { isOnline, loading: onlineStatusLoading } = useOnlineStatus();

  // const { loading, error, data, refetch } = useCourseViewerQuery({
  //   variables: {
  //     courseUuid: searchParams.get("course_uuid") || "",
  //   },
  //   fetchPolicy: "cache-and-network",
  // });
  // useErrorHandler(error);

  // change to lazyQuery for offline
  const [getCourseView, { loading, data, error, refetch }] =
    useCourseViewerLazyQuery();
  useErrorHandler(error);

  useLayoutEffect(() => {
    getCourseView({
      variables: {
        courseUuid: searchParams.get("course_uuid") || "",
        scheduleUuid: scheduleUuid || "",
      },
      fetchPolicy: isOnline ? "cache-and-network" : "cache-first",
    });
  }, [isOnline, getCourseView, searchParams]);

  // let course = handleCourseShape(data);

  // Scroll Retention

  const scrollPositionRef = useRef(0);

  useLayoutEffect(() => {
    return () => {
      scrollPositionRef.current = window.pageYOffset;
    };
  }, []);

  // Restore the scroll position when mounting
  useLayoutEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, []);

  useEffect(() => {
    if (isOnline) {
      refetch();
    }
  }, [isOnline, refetch]);

  // Shortcuts for interacting with object

  const courseViewScreen = data?.courseViewScreen;

  if (loading || onlineStatusLoading || isOnline === null) {
    return (
      <div data-testid="loading">
        <LoadingCentered />
      </div>
    );
  }

  if (
    (data && !loading && !data?.courseViewScreen?.course?.isEnrolled) ||
    data?.courseViewScreen?.learnerCourseStatus?.scheduleDetails?.status !==
      "ONGOING"
  ) {
    return <ResourceNotAccessible />;
  }

  return (
    <>
      <ResourceNavbar
        resourceName={data?.courseViewScreen?.course?.title || ""}
      />
      <div>
        <CourseViewerBlock
          courseViewScreen={courseViewScreen || {}}
          courseUuid={courseUuid as string}
          scheduleUuid={scheduleUuid as string}
        />
      </div>
    </>
  );
}
