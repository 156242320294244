import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CatalogListItem } from "../../../molecules/components";
import {
  CourseResource,
  ScreenModal,
  TimerChancesPromptModal,
} from "../../../molecules/components/CastBlocks";
import { EnrollmentModals } from "../EnrollmentModals";
import { Icon } from "../../../atoms/components";
import { Resource } from "../../../pages/components/CatalogList";

export interface CatalogListLayoutProps {
  title: string;
  resources?: Resource[];
}

export function CatalogListLayout({
  title = "Web Catalog Layout Sample on Large Screen Test",
  resources = [],
}: CatalogListLayoutProps) {
  const navigate = useNavigate();
  const [courseCardModal, setCourseCardModal] = useState<boolean>(false);
  const [batchModal, setBatchModal] = useState<boolean>(false);
  const [courseResource, setCourseResource] = useState<CourseResource>();
  const [resourceUuid, setResourceUuid] = useState<string>("");
  const [timePromptModal, setTimePromptModal] = useState<boolean>(false);
  const screenModal: ScreenModal = {
    objectFunction: {
      setCourseCardModal: setCourseCardModal,
      setBatchModal: setBatchModal,
      setCourseResource: setCourseResource as React.Dispatch<
        React.SetStateAction<CourseResource>
      >,
      setResourceUuid: setResourceUuid,
    },
    state: courseCardModal,
  };
  const timerChancesModal: TimerChancesPromptModal = {
    objectFunction: {
      setTimePromptModal: setTimePromptModal,
      setResourceUuid: setResourceUuid,
    },
    state: timePromptModal,
  };

  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");

  const allowedResources = resources?.filter((resource) => {
    if (resource.content?.__typename === "ResourceContentCourse") {
      const allowedActions =
        resource.content.courseDetails?.course?.allowedActions;
      return (
        allowedActions?.includes("take") || allowedActions?.includes("enroll")
      );
    }

    return true;
  });

  if (!resources) {
    return <></>;
  }

  return (
    <div className="mx-10 md:mx-20 py-6 ">
      {batchModal || courseCardModal || timePromptModal ? (
        <EnrollmentModals
          courseCardModal={courseCardModal}
          setCourseCardModal={setCourseCardModal}
          batchModal={batchModal}
          setBatchModal={setBatchModal}
          courseResource={courseResource}
          screenModal={screenModal}
          resourceUuid={resourceUuid}
          setTimePromptModal={setTimePromptModal}
          timePromptModal={timePromptModal}
        ></EnrollmentModals>
      ) : (
        <></>
      )}
      <div className="w-full mb-4">
        <button
          onClick={() => {
            navigate(`/screen/${parentScreenName}`);
          }}
        >
          <Icon
            className="bg-gray-400 w-4 text-sm flex flex-col rounded-sm text-white text-center"
            name="chevron_left"
          ></Icon>
        </button>
      </div>
      <p className="font-black text-gray-700 text-2xl">{title}</p>

      <ul className="grid gap-2 grid-cols-1 md:grid-cols-2">
        {allowedResources?.map((resource: Resource) => {
          let timeLimit = null;
          let userSubmissions = null;
          if (resource.content?.__typename === "ResourceContentAssessment") {
            timeLimit =
              resource.content?.examTimeslot?.outline?.exam?.timeLimit;
            userSubmissions = resource.content?.examTimeslot?.userSubmissions;
          }
          return (
            <li
              key={resource.uuid}
              className="border-b flex font-semibold line-clamp-2 text-gray-700 w-full break-words"
            >
              {resource.content?.__typename === "ResourceContentCourse" &&
              !resource.content.courseDetails?.course?.isEnrolled ? (
                <button
                  onClick={() => {
                    setCourseCardModal(true);
                    setCourseResource({
                      description: resource.description || "",
                      image: resource.coverUri || "",
                      name: resource.name || "",
                      uuid: resource.uuid || "",
                    });
                  }}
                  className="text-left mb-4"
                >
                  <CatalogListItem
                    name={resource.name}
                    locked={false}
                    coverUri={resource.coverUri}
                    type={resource.type}
                    subtype={resource.subtype}
                  ></CatalogListItem>
                </button>
              ) : (resource.type === "assessment" && resource) ||
                timeLimit ||
                userSubmissions ? (
                <button
                  onClick={() => {
                    setTimePromptModal(true);
                    setResourceUuid(resource?.uuid || "");
                  }}
                >
                  <CatalogListItem
                    name={resource.name}
                    locked={false}
                    coverUri={resource.coverUri}
                    type={resource.type}
                    subtype={resource.subtype}
                  />
                </button>
              ) : (
                <Link
                  to={`/view?resource_uuid=${resource.uuid}&from_screen=${parentScreenName}`}
                >
                  <CatalogListItem
                    name={resource.name}
                    locked={false}
                    coverUri={resource.coverUri}
                    type={resource.type}
                    subtype={resource.subtype}
                  ></CatalogListItem>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
