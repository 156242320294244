import { Markdown, Video, Button } from "../../../atoms/components";
import { VideoProps } from "../../../atoms/components/Video";
export interface ViewResourceVideoProps extends VideoProps {}

export function ViewResourceVideo({
  file = {
    uri: "https://d1g96a0e9vp024.cloudfront.net/sample/37fc2983-8dd4-4594-a088-a6780f26b93a",
    videoDescription: "",
  },
  onPlay,
  onPause,
  onEnd,
  includeTranscriptButton = true,
  ...props
}: ViewResourceVideoProps) {
  return (
    <div className={`p-4 flex flex-col ${props.className}`}>
      <Video
        file={file}
        onPlay={onPlay}
        onPause={onPause}
        onEnd={onEnd}
        className="flex items-center w-full h-full "
        includeTranscriptButton={includeTranscriptButton}
      />

      <div className="w-full mt-16">
        <Markdown
          text={
            file.videoDescription ? file.videoDescription : "No Description"
          }
          className="max-w-full"
        />
      </div>
    </div>
  );
}
