import React, { useEffect, useState } from "react";
import { Image, InputTextArea, Markdown } from "../../../atoms/components";
import {
  AnswerRequiredObject,
  ExamItemV2,
  InsideCourseResponses,
  OutsideCourseResponses,
} from "../../../pages/components/AssessmentViewerV2";

export interface FreedomWallItemProps {
  item?: ExamItemV2;
  formData?: (data: any) => void;
  inputDisabled?: boolean;
  updateRequiredValue?: (requiredStateEntry: AnswerRequiredObject) => void;
}

export function FreedomWallItem({
  item,
  formData = () => {},
  inputDisabled,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
}: FreedomWallItemProps) {
  const [inputValue, setInputValue] = useState(
    item?.draftValue?.longInput || ""
  );

  useEffect(() => {
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: inputValue || "",
    }));
  }, []);
  useEffect(() => {
    if (item?.required) {
      updateRequiredValue({
        isAnswered: inputValue !== "",
        itemUuid: item.uuid || "",
      });
    }
  }, [inputValue]);
  const onValueChange = (event: any) => {
    setInputValue(event.target.value);
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: event.target.value,
    }));
  };
  return (
    <div className="">
      <div className="text-lg font-bold">
        <span className="text-error-500">{item?.required ? "* " : ""}</span>
        <Markdown text={item?.text || ""} />
      </div>
      <div className={`py-4 ${item?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={item?.mediaUrl || ""}
          alt={item?.mediaUrl || ""}
          className={`w-full min-h-40 max-h-[35rem] object-contain bg-opacity-0`}
        />
      </div>

      <InputTextArea
        disabled={inputDisabled}
        onChange={onValueChange}
        rows={4}
        name={item?.uuid || ""}
      />

      {/* Wala pa po atang ganitong functionality */}
      {/* <label className="text-gray-500 ">
        <input
          type="checkbox"
          className="rounded focus:border-primary-300 focus:ring-primary-300 text-primary-600"
        />
        Submit answer anonymously
      </label> */}
    </div>
  );
}
