import React, { useRef, ChangeEvent, useState } from "react";
import ReactPlayer from "react-player";
import useSubtitles from "react-subtitles";
import { Button } from "../Button";
import { TrackProps } from "react-player/file";
import { useCreateUrlFromBlob } from "../../../helpers/CreateUrlFromBlob/CreateUrlFromBlob";

type HlsInternalPlayer = {
  currentLevel: number;
  levels: Array<{ bitrate: number }>;
};

export interface VideoProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  file?: VideoObject;
  onPlay?: () => any;
  onPause?: () => any;
  onEnd?: () => any;
  includeTranscriptButton?: boolean;
}

export interface VideoObject {
  uri?: string;
  videoDescription?: string;
  tracks?: [
    {
      uri: string;
    }
  ];
  dashUri?: string;
  hlsUri?: string;
  subtitleClass?: string;
}

export interface Subtitle extends React.TrackHTMLAttributes<HTMLTrackElement> {}

export function Video({
  file,
  onPlay,
  onPause,
  onEnd,
  includeTranscriptButton = true,
  ...props
}: VideoProps) {
  const videoBlobUrl = useCreateUrlFromBlob(file?.uri);

  const [subtitle] = useSubtitles({
    subtitles: file?.tracks ? file?.tracks[0]?.uri : " ",
  });

  const [openTranscript, setOpenTranscript] = useState<boolean>(false);

  // let scrollSpeed = 0.3; // set the speed of scrolling (in pixels per frame)
  // let scrollPos = 0; // initial position of the scrollbar
  // let animationFrameId: any;

  // const transcriptWindow = document.getElementById("transcript");
  // function autoScroll() {
  //   scrollPos += scrollSpeed; // increment the position
  //   transcriptWindow?.scrollTo(0, scrollPos); // scroll the page to the new position
  //   animationFrameId = requestAnimationFrame(autoScroll); // call the function again on the next animation frame
  // }

  // function stopScroll() {
  //   cancelAnimationFrame(animationFrameId);
  // }

  let allTracks: TrackProps[];
  let isTracks = false;

  allTracks = file?.tracks
    ? file?.tracks?.map((track, i) => {
        return {
          kind: "subtitles",
          src: track.uri ? track.uri : "",
          srcLang: "en",
          label: `subtitle ${i + 1}`,
        } as TrackProps;
      })
    : [
        {
          kind: "subtitles",
          src: "",
          srcLang: "en",
          label: ``,
        },
      ];

  const player = useRef<ReactPlayer>(null);

  const onChangeBitrate = (event: ChangeEvent<HTMLSelectElement>) => {
    const internalPlayer = player.current?.getInternalPlayer(
      "hls"
    ) as HlsInternalPlayer | null;

    if (internalPlayer) {
      // currentLevel expects to receive an index of the levels array
      internalPlayer.currentLevel = parseInt(event.target.value, 10);
    }
  };

  return (
    <div className={props.className + " flex flex-col"}>
      <div className="flex w-full h-full">
        <ReactPlayer
          ref={player}
          url={
            // "https://customer-meanrqxzyn9t39ry.cloudflarestream.com/bd0cabbe376e02c190975b064953ee13/manifest/video.m3u8"
            videoBlobUrl
              ? videoBlobUrl
              : file?.hlsUri || file?.dashUri || file?.uri
          }
          height="100%"
          width="100%"
          style={{ backgroundColor: "black" }}
          controls={true}
          className={`cue:${file?.subtitleClass}`}
          onPlay={() => {
            if (onPlay) onPlay();
          }}
          onPause={() => {
            if (onPause) onPause();
          }}
          onEnded={() => {
            if (onEnd) onEnd();
          }}
          config={{
            file: {
              tracks: allTracks,
              attributes: {
                crossOrigin: "true",
              },
            },
          }}
        />
        <div
          className={`overflow-scroll h-full w-1/3 ml-4 ${
            openTranscript ? "" : "hidden"
          }`}
          id="transcript"
        >
          {subtitle.map((s, i) => (
            <h1 key={i} className="mb-4">
              {s.text.replace(/&nbsp;/gi, " ").replace(/&amp;/gi, " ")}
            </h1>
          ))}
        </div>
      </div>
      <div className="flex w-full my-4">
        {includeTranscriptButton && (
          <div className="self-start justify-between hidden w-full text-xs md:flex md:text-base">
            <Button
              onClick={() => {
                if (
                  (file?.tracks?.length as number) > 0 &&
                  file?.tracks !== null &&
                  file?.tracks !== undefined
                )
                  setOpenTranscript(!openTranscript);
                else {
                  alert("No Transcript for this video");
                }
              }}
            >
              {openTranscript ? "Close Transcript" : "Show Transcript"}
            </Button>
          </div>
        )}

        {player.current?.getInternalPlayer("hls")?.levels.length > 1 && (
          <div className="flex items-center ml-auto space-x-2">
            <p className="text-lg">Quality:</p>
            <select onChange={onChangeBitrate} className="py-2 rounded-lg">
              {player.current
                ?.getInternalPlayer("hls")
                ?.levels.map((level: any, id: any) => (
                  <option key={id} value={id}>
                    {level.attrs.RESOLUTION.split("x")[1]}p
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
