import { useState } from "react";
import { Icon, Button, PillLabel, Image } from "../../../atoms/components";
import { gql, useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { ChooseBatchModal, Navbar } from "../../../organisms/components";
import {
  AccountAndUserDetailsSidePanel,
  LoadingCentered,
} from "../../../molecules/components";
import { NetworkStatus } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import { NavBarLayout } from "../../../templates/components";
import {
  StatisticsLearnerCourseStatus as StatisticsLearnerCourseStatusOriginal,
  useCardBatchStatusQuery,
  useLearnerCourseStatusQuery,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { snakeCaseToTitleCase } from "../../../helpers/TitleCase";

// Flags for Deped

const isDepedProd = false;

type StatisticsLearnerCourseStatus =
  CamelCasedType<StatisticsLearnerCourseStatusOriginal>;

interface Course {
  title: string;
  type: string;
  image: string;
  status: "Complete" | "In Progress" | "Failed";
  isOngoing: boolean;
  batch: Batch;
  certificate?: "Purchase" | "Download" | "Revoked";
}

interface Batch {
  batchName: string;
  start: Date;
  end: Date;
}

export interface ProfileCoursesCertificationProps {
  courses?: Course[];
}

export function ProfileCoursesCertification({}: ProfileCoursesCertificationProps) {
  const [isFilterOptionOpen, setIsFilterOptionOpen] = useState<boolean>(false);
  // const [isBatchModalOpen, setIsBatchModalOpen] = useState<boolean>(false);
  // const [courseBatch, setCourseBatch] = useState<string>("");
  const [searchParams] = useSearchParams();
  const { loading, data, error, refetch, networkStatus } =
    useLearnerCourseStatusQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        status: searchParams.get("status") || "",
      },
    });
  useErrorHandler(error);
  const [currentSortOption, setCurrentFilterOption] = useState<string | null>(
    searchParams.get("status")
      ? `Filtered by: ${snakeCaseToTitleCase(searchParams.get("status") || "")}`
      : ""
  );
  const courseStatuses =
    data?.coursesAndCertificationScreen?.learnerCourseStatuses;

  const onFilterClick = ({
    status,
    currentFilterOption,
  }: {
    status: string;
    currentFilterOption: string;
  }) => {
    refetch({ status: status });
    setIsFilterOptionOpen(false);
    if (currentFilterOption !== "All")
      setCurrentFilterOption(`Filtered by: ${currentFilterOption}`);
    else setCurrentFilterOption("");
  };
  return (
    <NavBarLayout className="h-full min-h-screen bg-gray-200 max-w-screen overflow-clip">
      <div className="flex">
        {isDepedProd && (
          <AccountAndUserDetailsSidePanel
            className="hidden w-1/4 px-4 py-6 lg:block "
            currentPage="courseAndCertification"
          />
        )}
        <main className="flex-1 p-4 ">
          <h1 className="my-4 text-2xl font-black text-gray-700 ">
            Courses and Certification
          </h1>
          <div className="relative w-fit">
            <Button
              onClick={() => {
                setIsFilterOptionOpen(!isFilterOptionOpen);
              }}
              className={"transition-all duration-500 active:animate-pulse "}
            >
              <Icon name="filter_list" className="mr-2" />
              Filter
            </Button>
            {isFilterOptionOpen ? (
              <div
                className={`bg-white text-lg flex flex-col w-fit mt-2 rounded transition-all duration-100 ${
                  isFilterOptionOpen ? "" : "hidden "
                } `}
              >
                {" "}
                <FilterDropdownItem
                  text="All"
                  filterValue=""
                  onClick={onFilterClick}
                />
                <FilterDropdownItem
                  text="Not Started"
                  filterValue="not_started"
                  onClick={onFilterClick}
                />
                <FilterDropdownItem
                  text="In Progress"
                  filterValue="in_progress"
                  onClick={onFilterClick}
                />
                <FilterDropdownItem
                  text="Needs Attention"
                  filterValue="needs_attention"
                  onClick={onFilterClick}
                />
                <FilterDropdownItem
                  text="Passed"
                  filterValue="passed"
                  onClick={onFilterClick}
                />
                <FilterDropdownItem
                  text="Failed"
                  filterValue="failed"
                  onClick={onFilterClick}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <h1 className="my-4 text-lg font-black text-gray-700 ">
            {currentSortOption}
          </h1>

          {!loading && !(networkStatus === NetworkStatus.refetch) ? (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 ">
              {courseStatuses?.map((courseStatus, index: number) => {
                return (
                  <CourseCertificateCard
                    courseStatus={courseStatus || {}}
                    filterStatus={searchParams.get("status") || ""}
                    key={index}
                  />
                );
              })}
            </div>
          ) : (
            <LoadingCentered></LoadingCentered>
          )}
        </main>
      </div>
    </NavBarLayout>
  );
}

function CourseCertificateCard({
  courseStatus,
  filterStatus = "",
}: {
  courseStatus: StatisticsLearnerCourseStatus;
  filterStatus: string;
}) {
  // const [batchStatus, setBatchStatus] = useState<string>(
  //   "checking batch status..."
  // );
  // const { data, error, loading } = useCardBatchStatusQuery({
  //   variables: {
  //     courseUuid: courseStatus.courseDetails?.uuid || "",
  //   },
  //   onCompleted: (response) => {
  //     setBatchStatus(
  //       response?.courseProgressScreen?.course?.enrolledSchedules?.find(
  //         (enrolledSchedule) =>
  //           enrolledSchedule?.uuid === courseStatus.scheduleDetails?.uuid
  //       )?.status || ""
  //     );
  //   },
  // });

  return (
    <div className="flex flex-col h-full p-4 space-y-4 bg-white rounded-3xl drop-shadow-lg">
      <Link
        to={`courseactivity?course_uuid=${courseStatus.courseDetails?.uuid}&course_schedule_uuid=${courseStatus.scheduleDetails?.uuid}&status=${filterStatus}`}
        onClick={() => {
          // setIsBatchModalOpen(true);
          // setCourseBatch(course?.courseDetails?.uuid);
        }}
        className="space-y-4"
      >
        <PillLabel
          className="flex text-sm "
          status={courseStatus?.status as string}
        >
          {courseStatus?.status?.toUpperCase().replace("_", " ")}
        </PillLabel>

        <div className="flex flex-col items-center pb-4 border-b-2">
          <Image
            src={courseStatus?.courseDetails?.coverUri as string}
            className="object-contain w-32 h-32 bg-gray-200"
            alt="course image"
          ></Image>

          <Icon name={"school"} className="text-xl text-gray-500"></Icon>
          <p className="font-bold line-clamp-1 text-slate-700">
            {courseStatus?.courseDetails?.title}
          </p>
        </div>
      </Link>

      {/* Batch Description */}
      <div>
        <PillLabel status={courseStatus.scheduleDetails?.status || ""}>
          {courseStatus.scheduleDetails?.status}
        </PillLabel>

        <div className="flex items-start w-full text-slate-500">
          <Icon name="date_range" className="w-5 h-5" />
          <div className="flex flex-col items-start flex-1 m">
            <p className="line-clamp-2">{courseStatus.scheduleDetails?.name}</p>
            {courseStatus.scheduleDetails?.start && (
              <p className="font-semibold">
                {dateFormattingSchedules(courseStatus.scheduleDetails.start)}
              </p>
            )}

            {courseStatus.scheduleDetails?.end && (
              <p className="font-semibold">
                - {dateFormattingSchedules(courseStatus.scheduleDetails.end)}
              </p>
            )}
          </div>
        </div>
      </div>

      {courseStatus?.courseCertificateUrl && (
        <div className="flex flex-col h-full ">
          <a
            href={courseStatus?.courseCertificateUrl || ""}
            target="_blank"
            className="flex justify-center mt-auto text-sm text-primary-600"
            rel="noreferrer"
          >
            <Icon name="download"></Icon>
            <p className="ml-2 underline">Download Certificate</p>
          </a>
        </div>
      )}
    </div>
  );
}

function FilterDropdownItem({
  text,
  filterValue,
  onClick,
}: {
  text: string;
  filterValue:
    | "not_started"
    | "in_progress"
    | "needs_attention"
    | "passed"
    | "failed"
    | "";
  onClick: ({
    status,
    currentFilterOption,
  }: {
    status: string;
    currentFilterOption: string;
  }) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <button
      className="px-4 py-2 hover:bg-gray-300"
      onClick={() => {
        onClick({ status: filterValue, currentFilterOption: text });

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("status", filterValue);

        setSearchParams(newSearchParams);
      }}
    >
      {text}
    </button>
  );
}
