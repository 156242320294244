import { Icon } from "../../../atoms/components";

export interface CatalogCardLockProps {
  lockIcon?: string;
}

export function CatalogCardLock({ lockIcon = "lock" }: CatalogCardLockProps) {
  return (
    <div className="h-48 w-48 rounded-2xl bg-gray-300 opacity-70">
      <div className="flex justify-center items-center text-gray-500 h-full w-full">
        <Icon name={lockIcon} className="text-6xl"></Icon>
      </div>
    </div>
  );
}
