import { Image } from "../../../atoms/components";
import { CatalogCardLabel, CatalogCardLabelProps } from "../CatalogCardLabel";
import { CatalogCardLock } from "../CatalogCardLock";
import { CatalogCardExpireButton } from "../CatalogCardExpireButton";
import { daysFromTodayWithTime } from "../../../helpers/DateFormatting";
import { useEffect, useState } from "react";

export interface CatalogCardProps extends CatalogCardLabelProps {
  name: string | null | undefined;
  locked?: boolean | null;
  lockIcon?: string;
  coverUri?: string | null | undefined;
  success?: boolean | null | undefined;
  isCourse?: boolean;
  type?: string | null | undefined;
  subtype?: string | null | undefined;
  key?: string;
  resourceIconStyle?: any;
  expiryDate?: string;
  daysTilExpired?: number;
  isInProgress?: boolean;
}

export function CatalogCard({
  name,
  locked = false,
  isInProgress = false,
  lockIcon,
  success,
  isCourse = false,
  coverUri,
  type,
  subtype,
  key,
  resourceIconStyle,
  expiryDate = "",
  daysTilExpired = 0,
}: CatalogCardProps) {
  const [screenThird, setScreenThird] =
    useState<"" | "1st-part" | "2nd-part" | "3rd-part">(""); // Store which third the mouse is in
  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const { clientX } = event;

    // Get the width of the entire window
    const screenWidth = window.innerWidth;

    // Check in which third of the screen the mouse is
    if (clientX < screenWidth / 3) {
      setScreenThird("1st-part");
    } else if (clientX >= screenWidth / 3 && clientX < (screenWidth * 2) / 3) {
      setScreenThird("2nd-part");
    } else {
      setScreenThird("3rd-part");
    }
  };

  function tooltipPostion(
    position: "1st-part" | "2nd-part" | "3rd-part" | "",
    catalogName: string
  ) {
    if (catalogName.length >= 32)
      switch (position) {
        case "1st-part":
          return "-left-8";
        case "3rd-part":
          return "-right-8";
        default:
          return "";
      }

    return "";
  }

  return (
    <div
      key={key}
      className="relative flex flex-col items-center w-48 h-48 px-4 pt-4 mb-2 bg-gray-100 rounded-2xl md:mr-2"
      onMouseEnter={() => setMouseOver(true)} // Start tracking on hover
      onMouseLeave={() => {
        setMouseOver(false); // Stop tracking on mouse leave
        setScreenThird(""); // Reset state
      }}
      onMouseMove={mouseOver ? handleMouseMove : undefined} // Only handle mouse move when hovering
    >
      <div className="relative flex flex-col items-center group/image-group">
        <Image
          src={coverUri || ""}
          alt={name || ""}
          className="truncate h-28 w-28"
        />
        <p
          className={` ${tooltipPostion(
            screenThird,
            name || ""
          )} absolute bottom-0 z-[50] rounded-full text-sm hidden px-2 py-1 bg-gray-200 group-hover/image-group:block animate-fadeIn whitespace-nowrap text-gray-500 drop-shadow-md duration-100`}
        >
          {name}
        </p>
      </div>

      <p className="flex items-center justify-center w-full mx-auto my-auto font-semibold text-center text-gray-700 break-words">
        <div className="line-clamp-2">{name}</div>
      </p>

      <div className="absolute top-0 left-0 z-10 m-2">
        <CatalogCardLabel
          type={type}
          subtype={subtype}
          isCourse={isCourse}
          success={success}
          resourceIconStyle={resourceIconStyle}
          isInProgress={isInProgress}
        />
      </div>

      {daysTilExpired !== 0 &&
      daysFromTodayWithTime(expiryDate || "") !== "" &&
      daysFromTodayWithTime(expiryDate || "") !== "expired" ? (
        <div className="absolute top-0 right-0 z-10 m-2 rounded-lg shadow-sm bg-error-200">
          <CatalogCardExpireButton
            expiryDate={expiryDate || ""}
            daysTilExpired={daysTilExpired || 0}
          />
        </div>
      ) : null}

      {locked ? (
        <div className="absolute top-0 left-0 z-20">
          {daysFromTodayWithTime(expiryDate || "") === "expired" ? (
            <CatalogCardLock lockIcon={"timer_off"} />
          ) : (
            <CatalogCardLock lockIcon={lockIcon} />
          )}
        </div>
      ) : null}
    </div>
  );
}
