import { Image } from "../../../atoms/components";

export interface LoginLayoutProps {
  headerLogo?: string;
  loginform: React.ReactNode;
  help?: React.ReactNode;
  footer?: React.ReactNode;
  banner?: string;
}
export function LoginLayout({
  headerLogo,
  loginform,
  help,
  footer,
  banner,
}: LoginLayoutProps) {
  return (
    <div className=" h-screen w-screen flex box-border">
      <section className="w-full md:w-[30vw] box-border flex flex-col justify-between p-4 overflow-y-auto">
        <div className=" flex-1 flex flex-col justify-evenly   bg-white">
          {loginform}

          <div className="">{help}</div>
        </div>

        {footer}
      </section>

      <Image
        className="max-w-[70vw] max-h-[100vh] bg-primary-700 hidden md:block object-cover flex-1"
        src={
          banner ||
          "https://d1g96a0e9vp024.cloudfront.net/sample/d117b5e8-8754-4e9e-b997-abcbf30471be"
        }
        alt="Login Banner"
      />
    </div>
  );
}

{
  /* <div className="flex flex-col">

<div className="mb-2">{loginform}</div>
<div className="p-4 sm:p-8">{help}</div>
</div>
<div className="shrink-0">{footer}</div> */
}
