import { MouseEventHandler, useState } from "react";
import castLogo from "../../../assets/default-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, Image } from "../../../atoms/components";

export interface LoginFormProps {
  heroUrl?: string;
  onLoginButtonClick?: MouseEventHandler<HTMLButtonElement>;
  onGuestLoginButtonClick?: MouseEventHandler<HTMLButtonElement>;
  clientId?: string;
  allowGuestLogin?: boolean;
  allowSignUp?: boolean;
}

export function LoginForm({
  heroUrl = "",

  clientId,
  ...props
}: LoginFormProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <Image
        src={heroUrl || castLogo}
        className=" md:max-h-[150px] md:max-w-[20vw]"
      ></Image>

      <Button
        type="button"
        className="mt-10 mb-2"
        width="w-full"
        onClick={props.onLoginButtonClick}
      >
        Login
      </Button>

      {props.allowSignUp ? (
        <div className="text-gray-400 flex flex-wrap">
          <p>No account yet? &nbsp;</p>
          <a
            href={`${process.env.REACT_APP_PASS_URI}/users/sign_up?client_id=${clientId}`}
            className="text-secondary-400"
          >
            Sign up here
          </a>
        </div>
      ) : (
        <></>
      )}

      {props.allowGuestLogin ? (
        <div>
          <div className="w-full my-4 flex items-center">
            <hr className="w-full border-1 border-gray-200" />
            <p className="mx-2 text-gray-400"> or </p>
            <hr className="w-full" />
          </div>
          <button
            onClick={props.onGuestLoginButtonClick}
            className="text-secondary-500 font-bold"
          >
            Login as Guest
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
  // return (
  //   <form className="p-4 sm:p-8">
  //     <div className="font-bold text-xl mb-4 text-center sm:text-left">
  //       Log In
  //     </div>
  //     <div className="mb-4">
  //       <InputWithLabel _label="Email" placeholder="Email" />
  //     </div>
  //     <div className="mb-4">
  //       <InputWithLabel
  //         _label="Password"
  //         placeholder="Password"
  //         type="password"
  //       />
  //     </div>
  //     <div className="mb-2">
  //       <input type="checkbox" value="login" id="login" />
  //       <label htmlFor="login" className="text-gray-400 ml-1 text-sm">
  //         Keep me logged in
  //       </label>
  //     </div>
  //     <div className="sm:w-full">
  //       <Button type="submit" width="w-full sm:w-24">
  //         log in
  //       </Button>
  //     </div>
  //     <div className="text-gray-400">
  //       No account yet? &nbsp;
  //       <Link to="/signup" className="text-secondary-400">
  //         Sign up here
  //       </Link>
  //     </div>
  //   </form>
  // );
}
