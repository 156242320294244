import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import { useLogoutMutation } from "../../../generated/graphql";

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

export function Logout() {
  const [mutateFunction, { data, loading, error }] = useLogoutMutation();
  let navigate = useNavigate();

  useErrorHandler(error);

  useEffect(() => {
    mutateFunction().then(() => {
      localStorage.clear();
      navigate("/login?logged-out=true");
      window.location.reload();
    });
  }, []);

  return <div>Loading</div>;
}
