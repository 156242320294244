import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import * as animationData from "../../../assets/castloadanimation.json";

export interface LoadingProps {
  prop?: string;
}

export function Loading({ prop = "default value" }: LoadingProps) {
  return (
    <div aria-label="loading">
      <Lottie
        loop
        play
        animationData={animationData}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid meet",
        }}
      />
    </div>
  );
}
