import React from "react";
import { Navbar } from "../../../organisms/components";
import { Button } from "../../../atoms/components";
import { Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { useState } from "react";
import {
  LoadingCentered,
  AccountAndUserDetailsSidePanel,
} from "../../../molecules/components";
import { NavBarLayout } from "../../../templates/components";
// import {
//   PayUserSubscription,
//   SubscriptionScreenPlan,
//   SubscriptionScreen,
//   Maybe,
// } from "../../../generated/graphql";

export interface SubscriptionsProps {
  prop?: string;
}

export const UserSubscriptionQuery = gql`
  query Subscription_screen {
    subscriptionScreen: subscription_screen {
      subscriptionPlans: subscription_plans {
        name
        price
        planPeriod: plan_period
        planUnit: plan_unit
        productId: product_id
        subscriptionPlanId: subscription_plan_id
      }
      userSubscriptions: user_subscriptions {
        uuid
        onTrial: on_trial
        isActive: is_active
        expires
        cancelled
        plan {
          uuid
          name
          trialPeriod: trial_period
          trialUnit: trial_unit
          planPeriod: plan_period
          planUnit: plan_unit
          isRecurring: is_recurring
          isActive: is_active
          product {
            id
            title
            slug
            description
          }
        }
      }
    }
  }
`;

export function Subscriptions({ prop = "default value" }: SubscriptionsProps) {
  const tableHeads = ["Name", "Active", "End Date", " "];

  // Subscription Screen Data
  const { loading, data, error } = useQuery(UserSubscriptionQuery);
  useErrorHandler(error);

  return (
    <NavBarLayout className="h-full min-h-screen max-w-screen overflow-clip bg-gray-200">
      <div className="flex">
        <AccountAndUserDetailsSidePanel
          className="w-1/4 py-6 px-4 hidden lg:block "
          currentPage="subscription"
        />
        {data ? (
          <div className="flex-1">
            {/* Table Titles */}
            <h1 className="mt-8 mb-5 text-center text-3xl ">Subscriptions</h1>
            {/* Subscription Table */}
            <div className="w-3/4 mx-auto">
              <table className="table-auto w-full bg-white border-2 border-black">
                <thead className="border ">
                  <tr className="text-left ">
                    {tableHeads.map((columnHeader) => {
                      return (
                        <th
                          className="p-4 border border-black"
                          key={columnHeader}
                        >
                          {columnHeader}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.subscriptionScreen.userSubscriptions.map(
                    (subscription: any) => {
                      return (
                        <tr key={subscription?.plan?.name}>
                          <td className="p-4 border border-black">
                            {subscription?.plan?.name}
                          </td>
                          <td className="p-4 border border-black">
                            {!subscription?.cancelled
                              ? "Active"
                              : "Until End Date"}
                          </td>
                          <td className="p-4 border border-black">
                            {dateFormattingSchedules(
                              subscription?.expires as string
                            )}
                          </td>
                          <td className="p-4 border border-black text-secondary-500">
                            <Link
                              to={`manage?userSubscriptionId=${subscription.uuid}`}
                            >
                              manage
                            </Link>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            {/* Switch Table Button */}
            <Link to={"../purchases"} className="w-full my-5 block">
              <Button
                className="mx-auto border-2 border-black"
                color="primaryInverted"
              >
                Show Purchase History
              </Button>
            </Link>

            <h1 className="mb-4 text-center text-3xl ">New Subscription</h1>
            <Link to="new-subscription">
              <Button className="mx-auto w-3/4" color="success">
                Create a new subscription
              </Button>
            </Link>
          </div>
        ) : (
          <LoadingCentered />
        )}
      </div>
    </NavBarLayout>
  );
}
