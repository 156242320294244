import React from "react";

import { Icon } from "../../../atoms/components";
import { resourceTypeIcon } from "../../../helpers";
import { Image, Markdown } from "../../../atoms/components";
import {
  FilterPill,
  TypeFilterPill,
} from "../../../molecules/components/FilterPill";
import { Resource } from "../../../pages/components/SearchResult";
import castLogo from "../../../assets/default-logo.png";
import { useNavigate } from "react-router-dom";

export interface ResultCardProps {
  resource?: Resource;
  typeFilter?: string;
  searchString?: string;
  onClickFunction?: () => void;
}

export function ResultCard({
  resource,
  typeFilter,
  searchString,
  onClickFunction,
}: ResultCardProps) {
  return (
    <div className="flex max-w-[100vw] gap-6 pb-6 border-b border-gray-200">
      <Image
        src={resource?.coverUri || castLogo}
        className="h-28 w-28"
        onClick={onClickFunction}
        role="button"
      />

      <section
        aria-label="resource description section"
        className="flex flex-col"
      >
        <span className="flex gap-1 text-sm text-gray-500">
          <Icon
            name={resourceTypeIcon(resource?.type || resource?.subtype || "")}
          />{" "}
          <p className="">{resource?.type}</p>{" "}
        </span>

        <h3
          className="font-bold text-lg text-gray-700 line-clamp-4"
          onClick={onClickFunction}
          role="button"
        >
          {resource?.name}
        </h3>
        <p className="text-xs text-secondary-500 mb-4">{resource?.tags}</p>

        {resource?.description && (
          <Markdown
            className="text-sm text-gray-500 line-clamp-6 w-full no-"
            text={resource?.description || ""}
          />
        )}

        <span className="text-xs text-gray-500 flex gap-2 mb-4">
          <p className="text-primary-700">description match:</p> {searchString}
        </span>

        <section className="flex gap-2">
          <TypeFilterPill
            isSelected={true}
            name={resource?.type === "course" ? "Course" : "Resource"}
          />
          <FilterPill filterName="Type" filterValue={typeFilter || ""} />
        </section>
      </section>
    </div>
  );
}
