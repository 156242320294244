import { useState, useEffect } from "react";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
} from "../../../pages/components/GbfSlide";
import { InputText, InputDate } from "../../../atoms/components";
import { parseColorToStyle } from "../../../helpers/ColorHelper";

export function ShortAnswerSlideActivity({
  examItem,
  savedResponses,
  handleResponse,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  const [textInput, setTextInput] = useState<string>(
    getDefaultValue({ examItem, savedResponses }) as string
  );

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    handleResponse({
      item: examItem.uuid,
      long_input: textInput as string,
      short_input: textInput as string,
    });
  }, [textInput]);

  return (
    <div className="">
      <QuestionHeader text={examItem.text || ""} style={textStyle} />

      {examItem.subtype !== "DT" ? (
        <InputText
          value={textInput || ""}
          onChange={(e) => {
            setTextInput(e.target.value);
          }}
          placeholder="Type in answer..."
          disabled={hasAnswered}
          className={`w-full border-2 border-black disabled:text-gray-700 ${
            hasAnswered && examItem.showResults
              ? rightPageFeedback?.isResponseCorrect
                ? "disabled:bg-success-200 "
                : "disabled:bg-error-200"
              : "bg-white disabled:bg-white"
          }`}
          style={textStyle}
        />
      ) : (
        // Date UI
        <InputDate
          onChange={(e) => {
            setTextInput(e.target.value);
          }}
          value={textInput || ""}
          disabled={hasAnswered}
          className={`w-full border-2 border-black disabled:text-gray-700 ${
            hasAnswered
              ? rightPageFeedback?.isResponseCorrect
                ? "disabled:bg-success-200 "
                : "disabled:bg-error-200"
              : "bg-white disabled:bg-white"
          }`}
          style={textStyle}
        />
      )}

      {rightPageFeedback?.shortAnswerKey &&
      !rightPageFeedback.isResponseCorrect &&
      examItem.showResults ? (
        <div className="text-gray-700 " style={textStyle}>
          Correct Answer:{" "}
          <span className="font-bold" style={textStyle}>
            {rightPageFeedback.shortAnswerKey}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
