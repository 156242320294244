import { Link } from "react-router-dom";
import { Icon } from "../../../atoms/components";

export interface DropdownProps {
  open?: boolean;
  paths?: {
    icon?: string | null;
    title?: string | null;
  }[];
}

export function Dropdown({ open, paths }: DropdownProps) {
  return (
    <ul
      className={`hidden md:block absolute items-center top-20 right-8 px-4 py-2 m-4 bg-white w-64 rounded shadow-md   ${
        open ? "top-14" : "top-[-200%]"
      }`}
    >
      {paths?.map((path, index) => (
        <li key={index}>
          <Link
            to={"/login"}
            className="ml-1 items-center text-lg px-2 py-2 mb-2 text-left w-full flex flex-none text-primary-500 hover:bg-primary-200 rounded"
          >
            <Icon name={path?.icon || ""} className="mr-1"></Icon>
            {path?.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
