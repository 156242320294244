import React, { createContext, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ViewResourceLayout } from "../../../templates/components/ViewResourceLayout";
import { ResourceNavbar } from "../../../organisms/components/ResourceNavbar";
import {
  CourseUserCompletedBlockIndicator,
  useTopicViewQuery,
  useUserUuidQuery,
} from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { TopicLayout } from "../../../organisms/components/TopicLayout/TopicLayout";
import { LoadingCentered } from "../../../molecules/components";
import { NavBarLayout } from "../../../templates/components";
import { TrainingDomain } from "../../../molecules/components/TrainingDomain/TrainingDomain";
import { CamelCasedType } from "../../../helpers/components";

export interface TrainingInfoLayoutProps {
  completedBlockIndicator: CamelCasedType<CourseUserCompletedBlockIndicator>;
}

export function TrainingInfoLayout({
  completedBlockIndicator,
}: TrainingInfoLayoutProps) {
  return (
    <div>
      <div className="mb-5 space-y-4 sm:space-x-4">
        <div className="inline-block w-full p-4 space-y-4 rounded-lg sm:w-72 bg-primary-200">
          <div className="text-sm font-bold text-primary-500">TOTAL POINTS</div>
          <div className="text-2xl font-bold text-primary-700">
            {completedBlockIndicator.totalPoints || 0}
          </div>
        </div>
        <div className="inline-block w-full p-4 space-y-4 rounded-lg sm:w-72 bg-info-200">
          <div className="text-sm font-bold text-info-500">
            ASSOCIATED DOMAINS
          </div>
          <div className="text-2xl font-bold text-info-700">
            {completedBlockIndicator.domains?.length}
          </div>
        </div>
      </div>
      {/* indicator list container */}
      <div className="space-y-3">
        {completedBlockIndicator.domains ? (
          completedBlockIndicator.domains.map((dom, i) => {
            return (
              <TrainingDomain
                title={dom?.name || ""}
                points={dom?.totalPoints || 0}
                indicators={dom?.indicators || []}
                childList={dom?.children || []}
                key={i}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
