import { useSearchParams } from "react-router-dom";
import {
  Resource as ResourceOriginal,
  useCollectionListScreenQuery,
} from "../../../generated/graphql";
import { CatalogListLayout } from "../../../organisms/components";
import { NavBarLayout } from "../../../templates/components";
import { gql } from "@apollo/client";
import { CamelCasedType } from "../../../helpers/components";
import { LoadingCentered } from "../../../molecules/components";

export type Resource = CamelCasedType<ResourceOriginal>;

export interface CatalogListProps {
  prop?: string;
}

export function CatalogList({ prop = "default value" }: CatalogListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const collectionUuid: string = searchParams.get("collection_uuid") || "";

  const collectionListScreenQueryResponse = useCollectionListScreenQuery({
    variables: {
      resourceCollectionUuid: collectionUuid,
    },
  });

  const emptyDefaultResource = {
    resourceCollection: { name: "" },
    resources: {},
  };

  if (collectionListScreenQueryResponse.loading) {
    return <LoadingCentered />;
  }

  return (
    <NavBarLayout>
      <CatalogListLayout
        resources={
          collectionListScreenQueryResponse.data?.catalogListScreen
            ?.resources as Resource[]
        }
        title={
          collectionListScreenQueryResponse.data?.catalogListScreen
            ?.resourceCollection?.name || ""
        }
      />
    </NavBarLayout>
  );
}
