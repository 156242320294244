import { resourceTypeIcon } from "../../../helpers";
import { ColorObj } from "../../../helpers/ColorHelper";
import { Icon, Image } from "../../../atoms/components";
import { CatalogCardExpireButton } from "../CatalogCardExpireButton";
import { daysFromTodayWithTime } from "../../../helpers/DateFormatting";
import { CourseBlock } from "../../../pages/components/CourseViewer";

const handleStatus = (status: string) => {
  switch (status) {
    case "passed":
      return (
        <div className="self-start text-green-500 ">
          <Icon name="check_circle"></Icon>
        </div>
      );
    case "failed":
      return (
        <div className="self-start text-red-500 ">
          <Icon name="error"></Icon>
        </div>
      );

    default:
      return <div className="ml-4"></div>;
  }
};

export interface CourseListLayoutCardProps {
  block?: CourseBlock;
  cardStyle?: ColorObj;
  daysTilExpired?: number;
  expiryDate?: string;
}

export function CourseListLayoutCard({
  block,
  cardStyle,
  daysTilExpired = 0,
  expiryDate,
}: CourseListLayoutCardProps) {
  return (
    <div className="w-full">
      <div className="flex items-center w-full py-4 border-b-2 border-gray-200 hover:cursor-pointer">
        {handleStatus(block?.userstatus?.status as string)}
        <div>
          {(
            block?.isAccessible === null
              ? block?.prerequisitesCourseBlocks?.some(
                  (prerequisitesCourseBlock) =>
                    prerequisitesCourseBlock?.hasUserCompleted === false
                )
              : !block?.isAccessible
          ) ? (
            <div className="absolute flex items-center justify-center w-20 h-20 mx-2 text-2xl text-gray-600 bg-gray-400/70">
              {daysFromTodayWithTime(expiryDate || "") === "expired" ? (
                <Icon name="timer_off"></Icon>
              ) : (
                <Icon name="lock"></Icon>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <Image
            className="w-20 h-20 mx-2"
            src={block?.coverUri || ""}
            alt={block?.coverUri || ""}
          />
        </div>

        <div
          className={
            "text-base font-bold text-gray-700 flex flex-col" +
            // (block.status !== "locked" ? "" : "/50")
            (true ? "" : "/50")
          }
        >
          <Icon
            name={resourceTypeIcon(block?.resource?.type)}
            className="mr-auto text-base font-bold text-gray-700"
            style={{ color: cardStyle?.backgroundColor }}
          ></Icon>
          <p>{block?.title}</p>
        </div>
        {daysTilExpired !== 0 &&
        daysFromTodayWithTime(expiryDate || "") !== "" &&
        daysFromTodayWithTime(expiryDate || "") !== "expired" ? (
          <div className="ml-auto">
            <CatalogCardExpireButton
              expiryDate={expiryDate || ""}
              daysTilExpired={daysTilExpired || 0}
            ></CatalogCardExpireButton>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
