import { useCompletedDomainsQuery } from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { NavBarLayout } from "../../../templates/components";
import { TrainingInfoLayout } from "../../../organisms/components/TrainingInfoLayout/TrainingInfoLayout";
import { LoadingCentered } from "../../../molecules/components";

export function TrainingInfo() {
  const { loading, error, data, refetch } = useCompletedDomainsQuery({
    fetchPolicy: "cache-and-network",
  });
  useErrorHandler(error);

  return (
    <NavBarLayout className="flex flex-col w-full h-full min-h-screen bg-gray-100 overflow-clip">
      {/* <div className="flex-row space-x-6"> */}

      <main className="w-full h-full p-4">
        <h1 className="mb-6 text-2xl font-black text-gray-700">
          <strong>Domains and Training Indicators</strong>
        </h1>
        {loading ? (
          <div className="h-full">
            <LoadingCentered />
          </div>
        ) : (
          <TrainingInfoLayout
            completedBlockIndicator={
              data?.tisScreen?.completedBlockIndicator || {}
            }
          />
        )}
      </main>
      {/* </div> */}
    </NavBarLayout>
  );
}
