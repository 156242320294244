import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { Iframe } from "../../../atoms/components";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { isMobile } from 'react-device-detect';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export interface ViewResourcePdfProps {
  uri?: string;
  style?: React.CSSProperties;
}

export function ViewResourcePdf({
  uri = "https://d1g96a0e9vp024.cloudfront.net/sample/d3cd44b9-519b-4a4c-b482-bd46d3ab1063",
  style,
}: ViewResourcePdfProps) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState(pageNumber.toString());
  const [zoom, setZoom] = useState(1.0);
  const [inputZoom, setInputZoom] = useState(`${Math.round(zoom * 100)}%`);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const defaultZoom = window.innerWidth < 600 ? 0.7 : 1.0;
    setZoom(defaultZoom);
    setInputZoom(`${Math.round(defaultZoom * 100)}%`);
  }, []);

  function highlightPattern(text: any, pattern: any) {
    return text.replace(pattern, (value: any) => `<mark>${value}</mark>`);
  }

  const textRenderer = useCallback(
    (textItem: any) => highlightPattern(textItem.str, searchText),
    [searchText]
  );

  function onChange(event: any) {
    setSearchText(event.target.value);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.js';

  function onDocumentLoadSuccess({ numPages }: { numPages: number | null }) {
    setNumPages(numPages);
  }

  function onLoadError(error: any) {
    console.log(error);
  }

  function onSourceError(error: any) {
    console.log(error);
  }

  const handleZoomIn = () => {
    setZoom((prevZoom) => {
      const newZoom = prevZoom + 0.1;
      setInputZoom(`${Math.round(newZoom * 100)}%`);
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(0.1, prevZoom - 0.1);
      setInputZoom(`${Math.round(newZoom * 100)}%`);
      return newZoom;
    });
  };

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newZoomInput = event.target.value;
    setInputZoom(newZoomInput);

    const newZoom = parseFloat(newZoomInput) / 100 || 1.0;
    setZoom(newZoom);
  };

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const handleInputPageNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.target.value;

    if (newInputValue !== '') {
      if (/^\d+$/.test(newInputValue)) {
        let newPageNumber = parseInt(newInputValue, 10);
        if (newPageNumber <= 0) {
          newPageNumber = 1;
        } else if (newPageNumber > numPages!) {
          newPageNumber = numPages!;
        }
        setInputPageNumber(newPageNumber.toString());
        changePage(newPageNumber - pageNumber);
      }
    } else {
      setInputPageNumber('');
    }
  };

  function previousPage() {
    changePage(-1);
    setInputPageNumber((prevInputPageNumber) => (parseInt(prevInputPageNumber, 10) - 1).toString());
  }

  function nextPage() {
    changePage(1);
    setInputPageNumber((prevInputPageNumber) => (parseInt(prevInputPageNumber, 10) + 1).toString());
  }

  if (isMobile) {
    return (
      <TransformWrapper>
        <TransformComponent>
          <div style={{ width: '100vw', ...style }}>
            {/* Fixed Toolbar with Zoom Controls and Page Numbers */}
            <div style={{ color: 'white', position: 'fixed', top: 0, left: 0, right: 0, background: '#323639', padding: '10px', zIndex: 1000, width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Page
              <input
                type="text"
                value={inputPageNumber}
                onChange={handleInputPageNumberChange}
                style={{ width: '40px', textAlign: 'center', marginRight: '10px', marginLeft: '10px', color: 'black' }}
              />
              <span> / {numPages}</span>
              <div style={{ marginRight: '20px', marginLeft: '20px' }}>
                |
              </div>

              <button onClick={handleZoomOut} style={{ marginRight: '10px' }}>
                ➖
              </button>
              <input
                type="text"
                value={inputZoom}
                onChange={handleZoomChange}
                style={{ width: '70px', color: 'black' }}
              />
              <button onClick={handleZoomIn} style={{ marginLeft: '10px' }}>
                ➕
              </button>
            </div>

            {/* PDF Document */}
            <div style={{ marginTop: '70px', position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Document
                file={uri}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
                onSourceError={onSourceError}
              >
                <Page pageNumber={pageNumber} scale={zoom} customTextRenderer={textRenderer} />
              </Document>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                pageNumber !== 1 ?

                  <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    style={{ marginRight: '10px' }}
                  >
                    Prev
                  </button>
                  : null
              }
              {
                pageNumber !== numPages ?
                  <button
                    type="button"
                    disabled={pageNumber >= numPages!}
                    onClick={nextPage}
                    style={{ marginLeft: '10px' }}
                  >
                    Next
                  </button>
                  : null
              }
            </div>
          </div>
        </TransformComponent>
      </TransformWrapper>
    );
  } else {
    return <Iframe className="h-full " src={uri} style={style} />;
  }
}
