import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Icon } from "../../../atoms/components";
import { LoadingCentered } from "../../../molecules/components";
import { ForumContext } from "../../../pages/components/ForumViewer";
import {
  useDeleteForumTopicMutation,
  useForumTopicHideMutation,
} from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { Modal } from "../Modal";

interface TopicRowMenuProps {
  courseUuid: string;
  topicUuid: string;
  title: string;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
  hideTopicRowMenu: () => void;
}
function TopicRowMenu({
  courseUuid,
  topicUuid,
  title,
  setToastOpen,
  setToastAction,
  hideTopicRowMenu,
}: TopicRowMenuProps) {
  const forumContext = useContext(ForumContext);
  const [isHideModalOpen, setIsHideModalOpen] = useState<boolean>(false);
  const [
    hideForumTopic,
    { data: hideData, loading: hideLoading, error: hideError },
  ] = useForumTopicHideMutation({
    variables: {
      forumTopicUuid: topicUuid,
      input: {
        course_uuid: courseUuid || "",
        title: title,
      },
    },
    onCompleted: () => {
      // setReload(true);
    },
  });
  useErrorHandler(hideError);

  function handleHide() {
    hideForumTopic({
      variables: {
        forumTopicUuid: topicUuid,
        input: {
          course_uuid: courseUuid || "",
          title: title,
          is_active: false,
        },
      },
      onCompleted: () => {
        // setReload(true);
      },
    });
    setIsHideModalOpen(false);
    setToastAction("hide");
    setToastOpen(true);
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [
    deleteForumTopic,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useDeleteForumTopicMutation({
    variables: {
      forumTopicUuid: topicUuid,
    },
    onCompleted: () => {
      // setReload(true);
    },
  });
  useErrorHandler(deleteError);

  function handleDelete() {
    forumContext.deleteForumTopic({
      variables: {
        forumTopicUuid: topicUuid,
      },
      onCompleted: () => {
        setIsDeleteModalOpen(false);
        setToastAction("delete");
        setToastOpen(true);
        forumContext.refetchForum({
          courseUuid: courseUuid,
        });
        hideTopicRowMenu();
      },
    });
  }
  return (
    <div className="fixed absolute z-10 flex flex-col text-lg bg-white rounded-lg shadow-lg w-fit h-fit right-3 top-1 ">
      {/* <button className='w-full px-4 py-2 rounded-t-lg hover:bg-gray-100'
        onClick={() => setIsHideModalOpen(true)}
      >
        <p className='text-left'>Hide</p>
      </button> */}
      <button
        className="w-full px-4 py-2 rounded-lg hover:bg-gray-100"
        onClick={() => setIsDeleteModalOpen(true)}
      >
        <p className="text-left">Delete</p>
      </button>

      {/* Modal for Hide */}
      <Modal
        isOpen={isHideModalOpen}
        onRequestClose={() => setIsHideModalOpen(false)}
      >
        <div className="text-center w-80">
          <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
          <h2 className="mb-2">
            This action will hide this topic &quot;{title}&quot; from the forum.
          </h2>

          {/* Action Buttons Div */}
          <div className="flex justify-around">
            <Button
              onClick={() => {
                handleHide();
              }}
            >
              Hide
            </Button>
            <Button
              color="primaryOutline"
              onClick={() => setIsHideModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal for Delete */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      >
        {forumContext.deleteForumTopicLoading && <LoadingCentered />}
        <div className="text-center w-80">
          <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
          <h2 className="mb-2">
            This action cannot be undone after{" "}
            <span className="font-bold">
              deleting this topic &quot;{title}&quot;.
            </span>{" "}
          </h2>

          {/* Action Buttons Div */}
          <div className="flex justify-around">
            <Button
              color="error"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
            <Button
              color="primaryOutline"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

interface TopicRowProps {
  courseUuid: string;
  topicUuid: string;
  title: string;
  createdBy: string;
  dateCreated: string;
  timeCreated: string;
  numComments: number;
  isModerator: boolean;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
}
export function TopicRow({
  courseUuid,
  topicUuid,
  title,
  createdBy,
  dateCreated,
  timeCreated,
  numComments,
  isModerator,
  setToastOpen,
  setToastAction,
}: TopicRowProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromScreen = searchParams.get("from_screen");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  return (
    <div className="">
      <div className="flex flex-row justify-between mb-1">
        <div className="">
          <button
            className=""
            onClick={() => {
              navigate(
                `/topicviewer?course_uuid=${courseUuid}&topic_uuid=${topicUuid}&resource_uuid=na&from_screen=${fromScreen}`
              );
            }}
          >
            <p className="mb-1 text-xl font-black text-gray-900">{title}</p>
          </button>
          <div className="flex flex-col cursor-default md:flex-row">
            <div className="flex flex-row">
              <div className="mr-1 text-secondary-500">{createdBy}</div>
              <div className="mx-1 text-gray-500">
                {dateCreated} - {timeCreated}
              </div>
            </div>
            <div className="flex flex-row ml-1 font-bold text-gray-500">
              <Icon name="chat_bubble_outline"></Icon>
              <p className="ml-1">{numComments} comment/s</p>
            </div>
          </div>
        </div>
        {isModerator ? (
          <div className="relative">
            <button className="" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Icon name="more_vert" className="text-lg text-gray-500"></Icon>
            </button>
            {isMenuOpen && (
              <TopicRowMenu
                courseUuid={courseUuid}
                topicUuid={topicUuid}
                title={title}
                hideTopicRowMenu={() => {
                  setIsMenuOpen(false);
                }}
                setToastOpen={setToastOpen}
                setToastAction={setToastAction}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <hr className="my-4"></hr>
    </div>
  );
}
