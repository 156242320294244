import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "../../../atoms/components";
import {
  ClaimAccountReminder,
  IconButton,
} from "../../../molecules/components";
import { InformationButton } from "../InformationButton";
import { isGuestContext } from "../../../context/components/IsGuest";
import { useContext } from "react";

export interface ResourceNavbarProps {
  resourceName?: string;
  isRedirected?: boolean;
  isNextEnabled?: boolean;
  courseBlockUuid?: string;
  onNext?: () => any;
  onClose?: () => any;
  className?: string;
}

export function ResourceNavbar({
  resourceName = "",
  isRedirected = false,
  isNextEnabled = false,
  courseBlockUuid = "",
  onNext,
  onClose,
  className,
}: ResourceNavbarProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const location = useLocation();
  const pathArray = window.location.pathname.split("/");
  const courseUuid = searchParams.get("course_uuid") || false;
  const scheduleUuid = searchParams.get("course_schedule_uuid") || false;
  const resourceUuid = searchParams.get("resource_uuid") || false;
  const status = searchParams.get("status") || false;

  const { isGuest } = useContext(isGuestContext);

  return (
    <div className={className || " h-[6dvh] box-border"}>
      <div className="box-border flex items-center w-full h-full px-6 py-2 shadow-sm place-content-around ">
        <button
          onClick={() => {
            if (
              isNextEnabled ||
              (courseUuid && resourceUuid && courseUuid !== "null")
            ) {
              if (onClose) {
                onClose();
              }
              navigate(
                `/courseviewer?course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`,
                {
                  state: {
                    scrollYValue: location.state
                      ? location.state.scrollYValue
                      : 0,
                  },
                }
              );
            } else if (pathArray.pop() === "courseactivity") {
              navigate(`/profile/certification?status=${status || ""}`);
            } else if (parentScreenName === "profile") {
              navigate(
                `/profile/certification/courseactivity?course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&status=${
                  status || ""
                }`
              );
            } else if (parentScreenName === "search") {
              navigate(
                `/screen/search?search_value=${searchParams.get(
                  "search_value"
                )}`
              );
            } else {
              if (onClose) {
                onClose();
              }
              navigate(`/screen/${parentScreenName}`);
            }

            // }
          }}
        >
          <Icon name="close" className="text-2xl text-gray-500"></Icon>
        </button>

        <p className="flex-1 text-base font-bold leading-5 text-center text-primary-500">
          {resourceName}
        </p>

        <div className="flex items-center justify-center">
          <div className="text-2xl text-gray-500">
            <InformationButton></InformationButton>
          </div>

          {isNextEnabled ? (
            <IconButton
              onClick={onNext}
              icon="chevron_right"
              className="ml-2"
              type="button"
            >
              <span className="hidden md:block">&nbsp;Next</span>
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isGuest ? <ClaimAccountReminder /> : ""}
    </div>
  );
}
