import { useQuery, gql } from "@apollo/client";
import { CastBlocks, LoadingCentered } from "../../../molecules/components";
import { useParams } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { Modal } from "../../../organisms/components";
import { NavBarLayout } from "../../../templates/components";
import { useContext } from "react";
import {
  useCastBlocksQuery,
  useSearchSlugQuery,
} from "../../../generated/graphql";
import { isGuestContext } from "../../../context/components/IsGuest";

export interface ScreenCastBlockListProps {}

export function ScreenCastBlockList({}: ScreenCastBlockListProps) {
  const { slug } = useParams();

  const SearchSlug = useSearchSlugQuery({ variables: {} });
  const { loading, error, data } = useCastBlocksQuery({
    variables: {
      slug: slug || "",
    },
    // To proceed even if may error because of not allowed
    errorPolicy: "ignore",
  });

  useErrorHandler(error);

  const isGuest = useContext(isGuestContext);

  return (
    <NavBarLayout>
      {loading ? (
        <div data-testid="loading">
          <LoadingCentered />
        </div>
      ) : (
        <CastBlocks data={data || {}} />
      )}
    </NavBarLayout>
  );
}
