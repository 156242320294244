import { Footer, NeedHelp, SignupForm } from "../../../molecules/components";
import { SignupLayout } from "../../../templates/components";

export interface SignupProps {
  prop?: string;
}

export function Signup({ prop = "default value" }: SignupProps) {
  return (
    <SignupLayout
      footer={<Footer _type="signup" />}
      signupform={<SignupForm />}
      help={<NeedHelp _type="signup" />}
    ></SignupLayout>
  );
}
