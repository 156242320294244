import { Button } from "../../../atoms/components";
import { InputWithLabel } from "../InputWithLabel";

export interface SignupFormProps {
  prop?: string;
}

export function SignupForm({ prop = "default value" }: SignupFormProps) {
  return (
    <form className="p-4 sm:p-8">
      <div className="font-bold text-xl mb-4 text-center">Sign Up</div>
      <div className="font-bold  mb-4 text-center text-gray-400">
        Account Details
      </div>
      <div className="mb-4">
        <InputWithLabel _label="Email" placeholder="Email" />
      </div>
      <div className="mb-8">
        <div className="mb-4">
          <InputWithLabel
            _label="Password"
            placeholder="Password"
            type="password"
          />
        </div>
        <InputWithLabel
          _label="Retype Password"
          placeholder="Retype Password"
          type="password"
        />
      </div>
      <div className="w-full">
        <Button type="submit" width="w-full">
          next
        </Button>
      </div>
    </form>
  );
}
