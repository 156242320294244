import { useEffect, useState } from "react";
import {
  CommentsListDocument,
  PassUserProfile,
  useCommentsListQuery,
} from "../../../generated/graphql";
import { CommentInput } from "../../../molecules/components/CommentInput";
import { Comment } from "../../../molecules/components/Comment";
import { useLazyQuery } from "@apollo/client";
import { LoadingCentered } from "../../../molecules/components";
import { CamelCasedType } from "../../../helpers/components";
import { Loading } from "../../../atoms/components";

export interface CommentsAreaProps {
  courseUuid: string;
  topicUuid: string;
  userProfile: CamelCasedType<PassUserProfile>;
  setToastOpen: (data: any) => void;
  setToastAction: (data: any) => void;
}
export function CommentsArea({
  courseUuid,
  topicUuid,
  userProfile,
  setToastOpen,
  setToastAction,
}: CommentsAreaProps) {
  const [reload, setReload] = useState<boolean>(false);
  const [didLoad, setDidLoad] = useState<boolean>(false);

  const { loading, error, data, refetch } = useCommentsListQuery({
    variables: {
      courseUuid: courseUuid,
      forumTopicUuid: topicUuid,
    },
    notifyOnNetworkStatusChange: true,
  });

  function refetchComments() {
    refetch();
  }

  // const comments = [];

  return (
    <div className="pt-12 space-y-6">
      <h2 className="font-bold text-gray-500 text-md">Comments</h2>
      {/* Current user's input area */}
      <CommentInput
        topicUuid={topicUuid}
        setToastOpen={setToastOpen}
        setToastAction={setToastAction}
        lastName={userProfile.familyName || ""}
        firstName={userProfile.givenName || ""}
        refetchComments={refetchComments}
      />
      {/* Other's comments */}
      {loading ? (
        <div className="w-48 h-48 mx-auto">
          <Loading />
        </div>
      ) : (
        data?.courseViewScreen?.forumTopic?.comments?.map(
          (comment: any, index: number) => {
            const firstName = comment?.createdBy?.firstName;
            const lastName = comment?.createdBy?.lastName;
            return (
              <Comment
                key={index}
                lastName={lastName}
                firstName={firstName}
                text={comment?.text as string}
                datetime={comment?.created as string}
                uuid={comment?.uuid}
                isUserModerator={comment?.isUserModerator}
                isFlagged={comment?.isFlagged}
                setToastOpen={setToastOpen}
                setToastAction={setToastAction}
                refetchComments={refetchComments}
              />
            );
          }
        )
      )}
    </div>
  );
}
