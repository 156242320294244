import { Button, Icon } from "../../../atoms/components";

export interface SubmissionFeedbackProps {
  results?: string | null;
  maxScore?: number | null;
  totalScore?: number | null;
  chances?: number | null;
  submissions?: number;
  retake?: () => void;
  inTimer?: boolean;
  passingMark?: number;
  passOnSubmit?: boolean;
}

export function SubmissionFeedback({
  results,
  maxScore,
  totalScore,
  chances,
  submissions,
  retake = () => {},
  inTimer,
  passingMark = 50,
  passOnSubmit,
}: SubmissionFeedbackProps) {
  return (
    <div>
      {passOnSubmit && !inTimer ? (
        <div className="w-full py-4 font-bold text-center rounded bg-success-100 text-success-500">
          <div className="font-bold">Your answer has been submitted</div>
        </div>
      ) : results === "passed" && !passOnSubmit ? (
        <div className="w-full py-4 font-bold text-center rounded bg-success-100 text-success-500">
          {!inTimer ? (
            <div className="font-bold">Your answer has been submitted</div>
          ) : (
            <div className="font-bold uppercase"> current score</div>
          )}
          <div
            className={`flex font-bold justify-center ${
              inTimer ? "text-4xl" : "text-xl"
            } mt-2 text-success-600`}
          >
            <Icon name="check_circle" className="mr-2" />
            {totalScore || 0}/{maxScore}
          </div>
        </div>
      ) : results === "failed" && !passOnSubmit ? (
        <div className="w-full py-4 font-bold text-center rounded bg-error-100 text-error-500">
          {!inTimer ? (
            <div className="font-bold">Your answer has been submitted</div>
          ) : (
            <div className="font-bold uppercase">current score</div>
          )}
          <div
            className={`flex font-bold justify-center text-error-600 ${
              inTimer ? "text-4xl" : "text-xl"
            }`}
          >
            <Icon name="cancel" className="mr-2" />
            {totalScore || 0}/{maxScore}
          </div>
          {maxScore && (
            <div className="my-2 text-sm italic font-normal text-center text-error-500">
              (passing grade is at least{" "}
              {Math.ceil((passingMark / 100) * maxScore)} pt(s))
            </div>
          )}
        </div>
      ) : (
        <div className="w-full py-4 text-center rounded bg-info-100 text-info-500">
          {!inTimer ? (
            <div className="font-bold">Your answer has been submitted</div>
          ) : (
            <div className="font-bold uppercase">current score</div>
          )}
          <div className="mx-4 mt-4 text-sm italic text-info-600">
            <b>Pending final score</b>
            <div>Some questions will be checked by your course admin</div>
          </div>
        </div>
      )}
      {!inTimer && results === "passed" && !passOnSubmit ? (
        <div className="w-full font-bold text-center bg-success-100 text-success-500">
          Congratulations!
        </div>
      ) : (
        ""
      )}
      {!inTimer && (
        <div
          className={`w-full flex justify-center py-2 bg-gradient-to-b ${
            results === "passed" || passOnSubmit
              ? "from-success-100"
              : results === "failed"
              ? "from-error-100"
              : "from-info-100"
          }`}
        >
          <Button
            className="w-full mx-4 text-sm bg-white border sm:w-24 border-primary-600 text-primary-600"
            color="primaryInverted"
            onClick={retake}
            disabled={
              chances && submissions ? chances - submissions === 0 : false
            }
          >
            Retake
          </Button>
        </div>
      )}
      {!inTimer && chances && submissions && (
        <div className="flex justify-center w-full mb-4 text-sm text-primary-600">
          <b>{chances - submissions}</b> &nbsp;chance(s) left
        </div>
      )}
    </div>
  );
}
