const ResourceTypeIconMap = new Map([
  // Types
  ["video", "play_arrow"],
  ["pdf", "picture_as_pdf"],
  ["buribook-with-activity", "import_contacts"],
  ["burislides", "view_carousel"],
  ["casticulate", "view_carousel"],
  ["html", "html"],
  ["xapi", "attachment"],
  ["link", "link"],
  ["audio", "music"],
  ["assessment", "assignment"],
  ["epub", "menu_book"],
  ["embed", "code"],
  ["course", "school"],
  ["text", "text_fields"],
  // Subtypes
  ["email", "email"],
  ["markdown", "text_fields"],
  ["file-upload", "how_to_vote"],
  ["freedom-wall", "chat_bubble"],
  ["google-slide", "add_to_drive"],
  ["google-sheet", "add_to_drive"],
  ["google-form", "add_to_drive"],
  ["google-doc", "add_to_drive"],
  ["flarum", "message"],
  ["youtube", "play_arrow"],
]);

export function resourceTypeIcon(value: any): string {
  let icon: any;

  if (typeof value === "object" && value !== null) {
    icon =
      ResourceTypeIconMap.get(value.subtype) ||
      ResourceTypeIconMap.get(value.type);
  } else {
    icon = ResourceTypeIconMap.get(value);
  }

  return icon || null;
}
