import { useState, useEffect } from "react";
import { Image, Icon, ProgressBar } from "../../../atoms/components";
import { ResourceContentBurislideSpread } from "../../../generated/graphql";
import { ColorObj, parseColorToStyle } from "../../../helpers/ColorHelper";

export function CarouselSlide({
  item,
  manageNavDisabled = () => {},
  finalAppearance,
}: {
  item: ResourceContentBurislideSpread;
  manageNavDisabled: (isNavDisabled: boolean) => void;
  finalAppearance?: {
    finalBgColor: ColorObj;
    finalTextStyle: ColorObj;
  };
}) {
  const [slideIndex, setSlideIndex] = useState(0);

  const backgroundColor = finalAppearance?.finalBgColor;

  // For disabling continue
  useEffect(() => {
    if (slideIndex < (item.uris?.length || 1) - 1) {
      manageNavDisabled(true);
    } else manageNavDisabled(false);
  }, [slideIndex]);

  return (
    <div className="relative flex flex-col justify-center w-full h-full max-w-full mx-auto overflow-x-hidden">
      {/* {index !== 0 && ( */}
      <div className="relative flex w-full">
        <button
          onClick={() => {
            setSlideIndex((prev) => {
              if (prev > 0) return prev - 1;

              return prev;
            });
          }}
          className={`text-4xl md:text-5xl lg:text-5xl transition-all absolute left-0 my-auto top-0 bottom-0 z-30 ${
            slideIndex <= 0
              ? "hidden"
              : "rounded-full flex items-center bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 mr-4 opacity-50 hover:opacity-100"
          } `}
        >
          <Icon name="chevron_left" />
        </button>

        {/* Image */}
        <div
          className={`flex transition-transform duration-700 ease-in-out h-[80vh] mx-auto`}
          style={{
            transform: `translateX(-${100 * slideIndex}%) `,
          }}
        >
          {item.uris?.map((imageUri, index) => {
            return (
              <div
                key={index}
                className={` bg-gray-100 h-[90%] min-w-[90%] max-w-[90%] mx-[5%] my-auto rounded flex relative border-2 border-black transition-all duration-500 drop-shadow-lg `}
                style={backgroundColor}
              >
                <Image src={imageUri || ""} className={`mx-auto `} />
              </div>
            );
          })}
        </div>

        <button
          onClick={() => {
            setSlideIndex((prev) => {
              if (prev + 1 < (item.uris?.length || 1)) {
                return prev + 1;
              }
              return prev;
            });
          }}
          className={`text-4xl md:text-5xl lg:text-5xl transition-all absolute right-0 my-auto top-0 bottom-0 z-30 ${
            // index === (item.uris?.length || 1) - 1 || index !== slideIndex
            slideIndex === (item.uris?.length || 1) - 1
              ? "hidden"
              : "rounded-full flex items-center bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 mr-4 opacity-50 hover:opacity-100"
          }`}
        >
          <Icon name="chevron_right" />
        </button>
      </div>
      <div className="flex px-2 mt-2">
        <ProgressBar value={slideIndex + 1} max={item.uris?.length || 1} />
      </div>
    </div>
  );
}
