import { Button, Icon, Image } from "../../../atoms/components";
import { resourceTypeIcon } from "../../../helpers";
import { CourseBlock } from "../../../pages/components/CourseViewer";
import { Modal, ModalProps } from "../Modal";

export interface ResourceCompleteModalProps extends ModalProps {
  nextBlocks?: any;
  onBackToMainPage?: () => any;
  onBlockClick?: (block: CourseBlock) => any;
}

export function ResourceCompleteModal(props: ResourceCompleteModalProps) {
  let nextBlock = props.nextBlocks?.[0];
  let otherBlocks = props.nextBlocks?.slice(1);

  return (
    <Modal {...props}>
      <div className="mx-auto">
        <div className="flex flex-col items-center">
          <span className="text-[60px] text-success-500">
            <Icon name="check_circle"></Icon>
          </span>

          <h1 className="text-2xl font-bold">Resource Complete</h1>
        </div>

        {nextBlock ? (
          <div className="flex flex-col items-start p-4 my-5 rounded-md bg-secondary-100 ">
            <p className="mb-4 text-base font-bold text-secondary-500">
              <span className="inline-block mr-1">
                <Icon name="bookmark"></Icon>
              </span>
              This is your next resource
            </p>

            <button
              className="flex mb-4 text-left"
              onClick={() => {
                if (props.onBlockClick) {
                  props.onBlockClick(nextBlock as CourseBlock);
                }
              }}
            >
              <Image
                src={nextBlock?.coverUri || ""}
                className="flex-grow-0 w-20 h-20 mx-2"
              ></Image>

              <div>
                <div className="text-gray-500">
                  <Icon
                    name={resourceTypeIcon(nextBlock?.resource?.type || "")}
                  ></Icon>
                </div>

                <p className="self-stretch flex-grow-0 text-base font-bold text-gray-700">
                  {nextBlock?.title ? nextBlock.title : ""}
                </p>
              </div>
            </button>

            <Button
              onClick={() => {
                if (props.onBlockClick) {
                  props.onBlockClick(nextBlock as CourseBlock);
                }
              }}
              className="self-end"
              color="secondary"
            >
              Go
            </Button>
          </div>
        ) : (
          ""
        )}

        {otherBlocks && otherBlocks.length > 0 ? (
          <div className="my-5">
            <h2 className="mb-2 text-xl font-bold">Other Resources Unlocked</h2>

            <ul>
              {otherBlocks.map((block: any, i: number) => {
                return (
                  <li key={i} className="py-3 border-b">
                    <button
                      className="flex text-left"
                      onClick={() => {
                        if (props.onBlockClick) {
                          props.onBlockClick(block);
                        }
                      }}
                    >
                      <Image
                        className="w-20 h-20 mr-2"
                        src={block.coverUri || ""}
                        alt={block.coverUri || ""}
                      />

                      <div className="min-w-0 font-bold text-gray-700 truncate">
                        <Icon
                          name={resourceTypeIcon(block.resource?.type)}
                          className="font-bold text-gray-700 "
                        ></Icon>

                        <p className="truncate">{block.title}</p>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <Button
          color="secondaryOutline"
          onClick={(event) => {
            props.onBackToMainPage?.();
          }}
          className="w-full my-5"
        >
          Back to main page
        </Button>
      </div>
    </Modal>
  );
}
