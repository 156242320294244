import { Button } from "../../../atoms/components";
import { InputWithLabel } from "../InputWithLabel";

export interface ConfirmationFormProps {
  prop?: string;
}

export function ConfirmationForm({
  prop = "default value",
}: ConfirmationFormProps) {
  return (
    <form className="p-4 sm:p-8">
      <div className="font-bold text-2xl mb-4 text-center">
        Resend Confirmation Instruction
      </div>
      <div className="mb-8">
        <InputWithLabel _label="Email" placeholder="Email" type="email" />
      </div>
      <div className="w-full">
        <Button type="submit" aria-label="submit" width="w-full">
          send
        </Button>
      </div>
    </form>
  );
}
