import React, { createContext, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ViewResourceLayout } from "../../../templates/components/ViewResourceLayout";
import { ResourceNavbar } from "../../../organisms/components/ResourceNavbar";
import {
  useTopicViewQuery,
  useUserUuidQuery,
} from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { TopicLayout } from "../../../organisms/components/TopicLayout/TopicLayout";
import { LoadingCentered } from "../../../molecules/components";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function TopicViewer() {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid");

  // const { loading, data, error } = useNavbarQuery();

  const { loading, error, data, refetch } = useTopicViewQuery({
    variables: {
      courseUuid: courseUuid || "",
      forumTopicUuid: searchParams.get("topic_uuid") || "",
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch: userRefetch,
  } = useUserUuidQuery({
    fetchPolicy: "cache-and-network",
  });

  useErrorHandler(error);

  if (loading || !data) {
    return <LoadingCentered />;
  }

  return (
    // <div>{query.get("topic_uuid")}</div>
    <ViewResourceLayout
      navbar={
        // May sariling NavBar na kasi si assessment
        <ResourceNavbar
          resourceName={data?.courseViewScreen?.forumTopic?.title || ""}
          isNextEnabled={false}
          // courseBlockUuid={courseBlockUuid as string}
        />
      }
    >
      {loading || !data ? (
        <div className="relative h-48">
          <LoadingCentered />
        </div>
      ) : (
        <div className="max-w-6xl px-10 mx-auto my-4">
          {/* Title + description area */}

          <TopicLayout
            topic={data?.courseViewScreen?.forumTopic || {}}
            courseUuid={courseUuid || ""}
            userProfile={
              userData?.accountAndUserDetailsScreen?.userProfile || {}
            }
          />
        </div>
      )}
    </ViewResourceLayout>
  );
}
