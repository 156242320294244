import { Iframe } from "../../../atoms/components/Iframe";

export interface ViewResourceEmbedProps {
  src?: string;
}

export function ViewResourceEmbed({
  src = "https://d1g96a0e9vp024.cloudfront.net/sample/d3cd44b9-519b-4a4c-b482-bd46d3ab1063",
}: ViewResourceEmbedProps) {
  return <Iframe src={src} className="w-full h-full"/>;
}
