import React from "react";
import { ExternalLink, Icon } from "../../../atoms/components";
import { CastColor } from "../../../generated/graphql";
import { ColorObj, parseColorToStyle } from "../../../helpers/ColorHelper";

export interface ViewResourceLinkProps {
  link?: string;
  textColor?: ColorObj;
}

export function ViewResourceLink({
  link = "https://helpx.adobe.com/indesign/using/generate-qr-code.html",
  textColor = {},
}: ViewResourceLinkProps) {
  return (
    <div className="flex flex-col flex-wrap items-center px-6 py-12 ">
      <div className={`text-9xl mb-4`} style={textColor}>
        <Icon name="insert_link"></Icon>
      </div>

      <div className={`flex text-base`} style={textColor}>
        <div className="mr-2">
          <Icon name="insert_link"></Icon>
        </div>

        <ExternalLink uri={link} />
      </div>
    </div>
  );
}
