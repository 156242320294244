import { gql, useQuery, useMutation } from "@apollo/client";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { LoadingCentered } from "../../../molecules/components";
import { Navbar } from "../../../organisms/components";
import { Button, Icon } from "../../../atoms/components";
import { useState } from "react";
import { useErrorHandler } from "react-error-boundary";

export const GetSingleUserSubscriptionQuery = gql`
  query User_subscription($userSubscriptionId: ID!) {
    subscriptionScreen: subscription_screen {
      userSubscription: user_subscription(
        user_subscription_id: $userSubscriptionId
      ) {
        uuid
        onTrial: on_trial
        isActive: is_active
        cancelled
        expires
        plan {
          uuid
          name
          trialPeriod: trial_period
          trialUnit: trial_unit
          planPeriod: plan_period
          planUnit: plan_unit
          isRecurring: is_recurring
          isActive: is_active
          product {
            id
            title
            slug
            description
          }
        }
        invoiceReceipts: invoice_receipts {
          uuid
          charge {
            amount
            status
            modified
          }
        }
      }
    }
  }
`;

export const GetAllPlansQuery = gql`
  query Subscription_plans {
    subscriptionScreen: subscription_screen {
      subscriptionPlans: subscription_plans {
        name
        price
        planPeriod: plan_period
        planUnit: plan_unit
        productId: product_id
        subscriptionPlanId: subscription_plan_id
      }
    }
  }
`;

const ChangeSubscriptionQuery = gql`
  mutation Subscription_change(
    $userSubscriptionId: ID!
    $subscriptionPlanIdTo: ID!
  ) {
    subscription_change(
      user_subscription_id: $userSubscriptionId
      subscription_plan_id_to: $subscriptionPlanIdTo
    )
  }
`;

const CancelSubscriptionQuery = gql`
  mutation Subscription_cancel($userSubscriptionId: ID!) {
    subscription_cancel(user_subscription_id: $userSubscriptionId) {
      uuid
    }
  }
`;

const UncancelSubscriptionQuery = gql`
  mutation Subscription_uncancel($userSubscriptionId: ID!) {
    subscription_uncancel(user_subscription_id: $userSubscriptionId)
  }
`;

export interface SubscriptionDetailProps {}

export function SubscriptionDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userSubscriptionId = searchParams.get("userSubscriptionId");

  const { data, error, loading } = useQuery(GetSingleUserSubscriptionQuery, {
    variables: {
      userSubscriptionId: userSubscriptionId,
    },
  });

  useErrorHandler(error);

  const { data: plansData, error: plansError } = useQuery(GetAllPlansQuery);

  useErrorHandler(plansError);

  const [
    uncancelSubscription,
    {
      data: uncancelSubscriptionData,
      error: uncancelSubscriptionError,
      loading: uncancelSubscriptionLoading,
    },
  ] = useMutation(UncancelSubscriptionQuery, {
    refetchQueries: [
      {
        query: GetSingleUserSubscriptionQuery,
        variables: {
          userSubscriptionId: userSubscriptionId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    cancelSubscription,
    {
      data: cancelSubscriptionData,
      error: cancelSubscriptionError,
      loading: cancelSubscriptionLoading,
    },
  ] = useMutation(CancelSubscriptionQuery, {
    refetchQueries: [
      {
        query: GetSingleUserSubscriptionQuery,
        variables: {
          userSubscriptionId: userSubscriptionId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useErrorHandler(cancelSubscriptionError);

  // Change Subscription Mutation
  const [
    changeSubscription,
    { data: changeSubscriptionData, error: changeSubscriptionError },
  ] = useMutation(ChangeSubscriptionQuery);

  useErrorHandler(changeSubscriptionError);

  const [changePlanUi, setChangePlanUi] = useState<boolean>(false);
  const [planIndex, setPlanIndex] = useState<number>(0);
  const [openPreview, setOpenPreview] = useState<boolean>();

  const subscription = data?.subscriptionScreen.userSubscription;
  const plansDataArray = plansData?.subscriptionScreen.subscriptionPlans;
  return (
    <div>
      <Navbar />
      {data && !cancelSubscriptionLoading && !uncancelSubscriptionLoading ? (
        <div className={!changePlanUi ? "" : "flex"}>
          <div
            className={"w-1/2 mb-10 " + (!changePlanUi ? "mx-auto" : "mx-10")}
          >
            <Link to={"../"} className="flex items-center my-5">
              <Icon name="chevron_left" className="text-4xl"></Icon>
              Go back
            </Link>

            {/* Current Plan Details table */}
            <table className="table-auto w-full border mb-5">
              <thead className="border">
                <tr className="text-left">
                  <th className="border border-r-0 p-4">Plan Details</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border">
                  <td className="w-1/6 p-4 border">Subscription Name</td>
                  <td className="p-4 border">{subscription.plan.name}</td>
                </tr>
                <tr className="border">
                  <td className="w-1/6 p-4 border">Status</td>
                  <td className="p-4 border">
                    {subscription?.cancelled
                      ? "Cancelled, recurring payment is on stop."
                      : "Continued billing"}
                  </td>
                </tr>
                <tr className="border">
                  <td className="w-1/6 p-4 border">Type</td>
                  <td className="w-full p-4 border">
                    {subscription.plan?.isRecurring
                      ? "Monthly Subscription"
                      : "Prepaid"}
                  </td>
                </tr>
                <tr className="border">
                  <td className="w-1/6 p-4 border">Subscription End</td>
                  <td className="w-full p-4 border">
                    {dateFormattingSchedules(subscription.expires as string)}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Payment Details Section */}
            <h1 className="mt-8 mb-5 text-center text-3xl ">Payments</h1>
            <div className="flex border">
              <div className=" w-1/2 p-4">
                <h2>
                  <span className="font-bold">Payment Date</span>
                </h2>
              </div>

              <div className="border-x w-1/4 p-4 break-words">
                <h2>
                  <span className="font-bold">Amount Due</span>
                </h2>
              </div>
              <div className=" w-1/4 p-4 break-words">
                <h2>
                  <span className="font-bold">Status</span>
                </h2>
              </div>
            </div>
            {subscription.invoiceReceipts.map((receipt: any) => {
              return (
                <div key={receipt.uuid} className="flex border border-t-0">
                  <td className="w-1/2 p-4">
                    {dateFormattingSchedules(receipt.charge.modified as string)}
                  </td>
                  <td className="w-1/4 p-4 border-x">
                    {receipt.charge.amount}
                  </td>
                  <td className="w-1/4 p-4">
                    {paymentStatusSwitch(receipt.charge.status)}
                  </td>
                </div>
              );
            })}
          </div>

          {/* All Of Preview Elements */}
          <div
            className={
              changePlanUi ? "w-1/2 h-fit mt-20 mr-10 border " : "hidden"
            }
          >
            {/* New Plan Selector */}
            <h2 className="border p-4 font-black">Choose Plan to Change to</h2>
            <select
              className="text-center w-full font-bold"
              defaultValue={0}
              onChange={(e) => {
                setPlanIndex(parseInt(e.target.value));
                setOpenPreview(!openPreview);
              }}
            >
              {plansDataArray?.map((plan: any, i: number) => {
                return (
                  <option key={plan.productId} value={i}>
                    {plan.name as string}
                  </option>
                );
              })}
              <option value="1"></option>
            </select>

            {/* New plan preview table */}
            <table className="table-auto w-full  mb-5">
              <thead>
                <tr className="text-left">
                  <th className="border-y-2 p-4">Plan Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-1/6 p-4">{`Name: ${
                    plansDataArray[planIndex as number].name
                  }`}</td>
                </tr>
                <tr>
                  <td className="w-1/6 p-4">{`Price: ${
                    plansDataArray[planIndex as number].price
                  }`}</td>
                </tr>
                <tr>
                  <td className="w-1/6 p-4">{`Plan Period: ${
                    plansDataArray[planIndex as number].planPeriod
                  }`}</td>
                </tr>
              </tbody>
            </table>

            {/* Proceed with changes buttons */}
            <div className="flex justify-end p-4">
              <Button
                onClick={() => {
                  setOpenPreview(false);
                  setChangePlanUi(false);
                }}
                color="error"
                className="mr-2"
              >
                Never Mind
              </Button>
              <Button
                onClick={() => {
                  changeSubscription({
                    variables: {
                      userSubscriptionId: subscription?.uuid,
                      subscriptionPlanIdTo:
                        plansDataArray[planIndex as number].subscriptionPlanId,
                    },
                  });
                }}
                color="success"
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <LoadingCentered data-testid="Loading" />
      )}

      {/* Change Plan Button and Cancel Plan Buttons */}
      {!changePlanUi &&
      data &&
      !cancelSubscriptionLoading &&
      !uncancelSubscriptionLoading ? (
        <div className="flex w-1/3 mx-auto">
          <Button
            className="mx-auto my-4"
            onClick={() => {
              if (!subscription.cancelled) {
                cancelSubscription({
                  variables: { userSubscriptionId: subscription?.uuid },
                });
              } else {
                uncancelSubscription({
                  variables: { userSubscriptionId: subscription?.uuid },
                });
              }

              // if (!cancelSubscriptionError && !uncancelSubscriptionError) {
              //   navigate(0);
              // }
            }}
            color="error"
          >
            {!subscription?.cancelled ? "Cancel Plan" : "Uncancel Plan"}
          </Button>
          <Button
            className="mx-auto my-4"
            onClick={() => {
              setChangePlanUi(!changePlanUi);
            }}
          >
            Change Plan
          </Button>
        </div>
      ) : null}
    </div>
  );
}

function paymentStatusSwitch(status: string) {
  if (status === "PAYMENT_SUCCESS") {
    return "Success";
  } else if (status === "PAYMENT_PENDING") {
    return "Pending";
  } else {
    return "Failed";
  }
}
