import { Icon, Image } from "../../../atoms/components";
import { resourceTypeIcon } from "../../../helpers";
import { CatalogCardProps } from "../CatalogCard";

export interface CatalogListItemProps extends CatalogCardProps { }

export function CatalogListItem({
  name,
  locked = false,
  success,
  isCourse = false,
  coverUri,
  type,
  subtype,
  key,
  resourceIconStyle,
}: CatalogListItemProps) {
  let courseSuccessIcon;
  // Dont render icon if success is null or undefined
  if (success === true) {
    courseSuccessIcon = "check_circle";
  } else if (success === false) {
    courseSuccessIcon = "error";
  }

  return (
    <div key={key} className="relative flex gap-2 py-4">
      {isCourse && courseSuccessIcon ? (
        <Icon
          role="img"
          name={courseSuccessIcon}
          className={`text-xl ${success ? "text-success-500" : "text-error-500"
            }`}
        />
      ) : null}

      <Image
        src={coverUri || ""}
        alt={name || ""}
        className="h-20 w-20"
      ></Image>

      <div className="flex flex-col px-2 text-left">
        <Icon
          role="img"
          className="text-xl text-gray-500 "
          style={resourceIconStyle}
          name={resourceTypeIcon(subtype || type)}
        ></Icon>

        <p className="line-clamp-2 sm:line-clamp-none">
          {name}
        </p>
      </div>

      {locked ? (
        <div className="z-10 absolute inset-0 flex bg-gray-300 opacity-70">
          <div className="flex text-gray-500  text-2xl justify-center items-center h-20 w-20">
            <Icon name="lock"></Icon>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export interface CatalogCardLabelProps {
  type?: string | null | undefined;
  subtype?: string | null | undefined;
  isCourse?: boolean;
  success?: boolean | null | undefined;
  resourceIconStyle?: any;
}
