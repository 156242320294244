export interface IframeProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {}

export function Iframe({ ...props }: IframeProps) {
  return (
    <iframe
      {...props}
      width="100%"
      height="1080"
      title={props.src}
      allowFullScreen
    ></iframe>
  );
}
