import { gql } from "@apollo/client";
import { useFaviconQuery } from "../../../generated/graphql";
import { useLayoutEffect } from "react";
// import * from "../../../../public/manifest.json"

export interface FaviconProps {
  prop?: string;
}

export const FAVICON_QUERY = gql`
  query Favicon {
    browserTitleBar: browser_title_bar {
      faviconUri: favicon_uri
      title
      pwa {
        icon192: icon_192
        icon512: icon_512
      }
    }
  }
`;

export function Favicon({ prop = "default value" }: FaviconProps) {
  let faviconElement = document.getElementById("favicon");
  let appleFaviconElement = document.getElementById("apple-favicon");
  let manifest = document.getElementById("manifest-file");

  const baseManifest = {
    short_name: "Cast",
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",

  };

  const { loading, data, error } = useFaviconQuery();

  useLayoutEffect(() => {
    if (data?.browserTitleBar) {
      const updatedManifest = {
        ...baseManifest,
        name: data.browserTitleBar.title || "Cast",
        start_url: window.location.origin,
        icons: [
          {
            src: data.browserTitleBar.faviconUri || "https://buriblob.sgp1.cdn.digitaloceanspaces.com/favicon.png",
            sizes: "64x64 32x32 24x24 16x16",
            type: "image/png",
            purpose: "any maskable"
          },
          {
            src: data.browserTitleBar.pwa?.icon192 || "https://buriblob.sgp1.cdn.digitaloceanspaces.com/logo192.png",
            type: "image/png",
            sizes: "192x192",
            purpose: "any maskable"
          },
          {
            src: data.browserTitleBar.pwa?.icon512 || "https://buriblob.sgp1.cdn.digitaloceanspaces.com/logo512.png",
            type: "image/png",
            sizes: "512x512",
            purpose: "any maskable",
          },
        ],
      };

      faviconElement?.setAttribute(
        "href",
        data.browserTitleBar.faviconUri || "/favicon.png"
      );

      appleFaviconElement?.setAttribute(
        "href",
        data.browserTitleBar.faviconUri || "/favicon.png"
      );

      manifest?.setAttribute(
        "href",
        `data:application/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(updatedManifest)
        )}`
      );

      document.title = data.browserTitleBar.title || "Cast";
    }
  }, [data]);

  return <></>;
}
