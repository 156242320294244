import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../atoms/components/Icon";
import { CastBlock } from "../../../generated/graphql";
import { CastBlockPropertiesButtonSearch as CastBlockPropertiesButtonSearchOriginal } from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import {
  CastColor,
  colorHelperBase,
  parseColorToStyle,
} from "../../../helpers/ColorHelper";

export type CastBlockPropertiesButtonSearch =
  CamelCasedType<CastBlockPropertiesButtonSearchOriginal>;

interface Content {
  uuid: string;
  image: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: string;
}

export interface SearchButtonBlockProps {
  block: CastBlock;
}

export function SearchButtonBlock({ block }: SearchButtonBlockProps) {
  const searchBlockProperties: CastBlockPropertiesButtonSearch =
    block.attributes?.properties?.__typename ===
      "CastBlockPropertiesButtonSearch"
      ? block.attributes.properties
      : {};

  const base = searchBlockProperties.appearance?.base;
  const appearance = parseColorToStyle({
    backgroundColor: base?.backgroundColor,
    foregroundColor: base?.foregroundColor,
    fontFamily: base?.fontFamily,
  });
  return (
    <div
      className="w-full h-72 py-16 px-10 md:px-20 bg-primary-700 space-y-4"
      style={parseColorToStyle({
        backgroundColor: base?.backgroundColor,
        foregroundColor: base?.foregroundColor,
        fontFamily: base?.fontFamily,
      })}
    >
      <h1
        className="text-white text-4xl font-black mx-auto w-fit"
        style={{
          color: appearance.color,
        }}
      >
        {searchBlockProperties.title as string}
      </h1>
      <h2
        className="text-primary-200 text-2xl mx-auto w-fit"
        style={{
          color: appearance.color,
          fontFamily: appearance.fontFamily,
        }}
      >
        What are you looking for?
      </h2>
      <section aria-label="search section buttons" className="h-9 gap-4 flex">
        <Link
          to={"/screen/search"}
          className="flex-1 flex items-center bg-white rounded-md border border-gray-500 gap-1 text-gray-400 p-2"
        >
          <Icon className="" name="search" /> <p>Search</p>
        </Link>

        <Link
          to={"/screen/search"}
          className="flex justify-center items-center rounded-md px-3 py-2 gap-1 w-10 sm:w-24 border border-white text-white"
          style={{
            borderColor: appearance.color,
          }}
        >
          <Icon
            name="filter_list"
            style={{
              color: appearance.color,
            }}
          />{" "}
          <p
            style={{
              color: appearance.color,
            }}
          >
            <span className="hidden md:inline">Filter</span>
          </p>
        </Link>
      </section>
    </div>
  );
}
