export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function dateFormatting(date: Date) {
  let currentString = "";
  let yearFormat = date.getFullYear();
  let monthFormat = monthList[date.getMonth()];
  let dateFormat = date.getDate();
  let hourFormat = singleNumberFormat(
    date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
  );
  let minuteFormat = singleNumberFormat(date.getMinutes());
  let secondFormat = singleNumberFormat(date.getSeconds());
  let ampm = date.getHours() < 12 ? "am" : "pm";

  currentString +=
    monthFormat +
    " " +
    dateFormat +
    ", " +
    yearFormat +
    " | " +
    hourFormat +
    ":" +
    minuteFormat +
    ":" +
    secondFormat +
    " " +
    ampm;

  return currentString;
}

export function dateFormattingNoTime(date: Date) {
  let currentString = "";
  let yearFormat = date.getFullYear();
  let monthFormat = monthList[date.getMonth()];
  let dateFormat = date.getDate();

  currentString += monthFormat + " " + dateFormat + ", " + yearFormat;

  return currentString;
}

const singleNumberFormat = (n: number) => {
  return n.toString().padStart(2, "0");
};

export function dateFormattingSchedules(date: string) {
  // 2022-10-04T08:47:51.378000Z

  if (date === null || date === "") {
    return "";
  }

  let dateArray: string[] = date.split("-");
  let year: string = dateArray[0];
  let month: number = Number(dateArray[1]) - 1;
  let day: string = dateArray[2].split("T")[0];

  let resultDate = monthList[month] + " " + day + " " + year;

  return resultDate;
}

// Thanks Chat GPT
export function daysFromTodayWithTime(date: string) {
  if (date === null || date === "") {
    return "";
  }

  const targetDateTime = new Date(date);

  // Get the current date and time
  const currentDateTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = targetDateTime.getTime() - currentDateTime.getTime();

  // Calculate the number of days
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  // Format the time
  const hours = targetDateTime.getHours();
  const minutes = targetDateTime.getMinutes();
  const amOrPm = hours < 12 ? "AM" : "PM";
  const formattedTime = `${hours % 12}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amOrPm}`;

  // Create and return the result object
  if (daysDiff <= 0) {
    return "expired";
  } else {
    return `${daysDiff} days - ${formattedTime}`;
  }
}
