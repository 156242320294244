import React from "react";
import { InputTextProps } from "../InputText";

export interface InputRadioProps extends InputTextProps {
  children?: React.ReactNode;
}

export function InputRadio({ children, ...props }: InputRadioProps) {
  return (
    <input
      {...props}
      type="radio"
      className="rounded h-4 w-4 text-primary-500 hover:text-primary-600 focus:ring-primary-600"
    ></input>
  );
}
