import React from "react";
import { Markdown, Icon } from "../../../atoms/components";

export interface ViewResourceMarkdownProps {
  text?: string;
  style?: React.CSSProperties;
}

export function ViewResourceMarkdown({
  text = "google.com\n# Headers\n\n# H1\n## H2\n### H3\n#### H4\n##### H5\n###### H6\n\nAlternatively, for H1 and H2, an underline-ish",
  style,
}: ViewResourceMarkdownProps) {
  return (
    <div className="px-8 py-4" style={style}>
      <Markdown text={text} className="mx-auto"></Markdown>
    </div>
  );
}
