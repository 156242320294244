import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getExternalLinkTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const EXTERNAL_LINK_TEMPLATE = `
  <div class="w-full flex flex-col items-center ${colorTheme.base} h-full rounded-lg relative px-4 py-2 md:px-8 md:py-4 space-y-4 ">
  
    <div class="flex font-titillium justify-between items-center w-full">
      <div class="flex text-4xl font-black whitespace-nowrap w-fit mr-8" > 
        {{#isGbf}}
          <p class=" text-teal-600 mr-1">GBF</p>  
          <p class=" text-teal-800 " >Class Builder</p>
        {{/isGbf}}
      </div>
  
      <!-- Progress Bar -->
      <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
        {{#progressBar}}
          <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
            <i class="material-icons " >
              {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
            </i> 
            <p class="text-sm w-fit line-clamp-1 font-bold text-center {{#lastVisitedTitlePage}}{{id}}{{/lastVisitedTitlePage}}" > 
              {{data.title}}  
            </p>
          </section>
          <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
          </div>
        {{/progressBar}}
      </div>
    </div>
  
    <div class=" w-full h-full flex-1 box-border overflow-auto bg-white rounded-3xl p-8 flex flex-col space-y-4">
  
      <div class="w-full overflow-auto flex flex-col space-y-4 items-center text-center my-auto ">
        <p class="font-black text-black text-2xl md:text-3xl">{{header}}</p>
  
        <div class=" overflow-auto ">
          {{subtext}}
        </div>
    
        <a href="{{link}}" target="_blank" class=" w-fit px-12 py-2 rounded-full text-center bg-gradient-to-r ${colorTheme.gradientButton} font-bold text-white active:opacity-70 hover:shadow-lg hover:opacity-80 transition-all duration-150 " >
        {{downloadText}}
        </a>
      </div>

      {{#imageUri}}
        <img src="{{imageUri}}" class=" w-full h-full flex-1 object-contain mx-auto overflow-auto" />
      {{/imageUri}}
    
    </div>
  
    <div class=" w-full  flex justify-between self-end ${colorTheme.gradientPanel}  px-4 py-4 box-border h-fit rounded-3xl items-center" >
      <button id="backButton" class="text-white p-2  rounded-3xl font-titillium font-semibold border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
        <i class="material-icons  " id="previousPage" >arrow_back</i>
      </button>
    
      <button id="nextButton"  class="text-white p-2  md:px-4 md:py-2  rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 ml-auto hover:opacity-80   " >
        <span class="hidden md:inline-block">Continue</span>   
        <i class="material-icons  " id="previousPage" >arrow_forward</i>
      </button>
    </div>
  </div>
  `;

  if (theme === null || theme === undefined) {
    return EXTERNAL_LINK_TEMPLATE;
  }

  return EXTERNAL_LINK_TEMPLATE;
}
