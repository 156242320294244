import { useHomeScreenQuery } from "../../../generated/graphql";
import { LoadingCentered } from "../../../molecules/components";
import { useErrorHandler } from "react-error-boundary";
import { gql } from "@apollo/client";
import { Navigate } from "react-router-dom";

export interface HomeProps {
  prop?: string;
}

const HOME_SCREEN = gql`
  query HomeScreen {
    home_screen {
      initial_slug
    }
  }
`;

export function Home({ prop = "default value" }: HomeProps) {
  const { data, loading, error } = useHomeScreenQuery();

  useErrorHandler(error);

  if (loading || !data) {
    return <LoadingCentered />;
  } else {
    return (
      <Navigate
        to={`/screen/${data.home_screen?.initial_slug}`}
        replace={true}
      ></Navigate>
    );
  }
}
