import { TextareaHTMLAttributes } from "react";

export interface InputTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: string;
}

export function InputTextArea(props: InputTextAreaProps) {
  const { label, className, ...otherProps } = props;

  return (
    <>
      {label ? <label htmlFor={otherProps.name}>{label}</label> : <></>}
      <textarea
        {...otherProps}
        className={`w-full border border-gray-300 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100 ${className}`}
      />
    </>
  );
}
