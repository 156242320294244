import React from "react";
import { Navbar } from "../../../organisms/components";

export interface NavBarLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export function NavBarLayout({ children, className }: NavBarLayoutProps) {
  return (
    <div className={className || ""}>
      <Navbar />
      <section className="overflow-hidden">{children}</section>
    </div>
  );
}
