import { gql } from "@apollo/client";
import {
  FormEvent,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import {
  Button,
  Icon,
  InputRadio,
  InputTextArea,
  Label,
  Loading,
  Select,
} from "../../../atoms/components";
import {
  AccountAndUserDetailsSidePanel,
  InputWithLabel,
  Toast,
} from "../../../molecules/components";
import {
  AccountAndUserDetailsScreenFormTab as AccountAndUserDetailsScreenFormTabOriginal,
  useAccountAndUserDetailsQuery,
  useUpdateAccountMutation,
  useUpdateProfileMutation,
  useGetFormItemsLazyQuery,
  useFormSubmitMutation,
  AccountAndUserDetailsScreenFormTabSubmit,
  AccountAndUserDetailsScreenFormTabSubmitFormFields,
  AccountAndUserDetailsScreenCustomField as AccountAndUserDetailsScreenCustomFieldOriginal,
  useGetFormSubmissionSummariesLazyQuery,
} from "../../../generated/graphql";
import { useErrorHandler } from "react-error-boundary";
import { LoadingCentered } from "../../../molecules/components/LoadingCentered";
import { NavBarLayout } from "../../../templates/components/NavBarLayout";
import { isGuestContext } from "../../../context/components/IsGuest";
import { CamelCasedType } from "../../../helpers/components";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import "./AccountAndUserDetails.css";

// Flags for Deped

const isDepedProd = false;

export type AccountAndUserDetailsScreenFormTab =
  CamelCasedType<AccountAndUserDetailsScreenFormTabOriginal>;

export type AccountAndUserDetailsScreenCustomField =
  CamelCasedType<AccountAndUserDetailsScreenCustomFieldOriginal>;

export interface AccountAndUserDetailsProps {
  profileImage?: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
}

export function AccountAndUserDetailsEmail({ email }: { email?: string }) {
  const [inEditMode, setInEditMode] = useState(false);

  const [isToastPresent, setIsToastPresent] = useState(false);

  const [newEmail, setNewEmail] = useState<string>("");
  const [retypeEmail, setRetypeEmail] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [mutateFunction, { data, loading, error }] = useUpdateAccountMutation({
    variables: {
      details: {
        email: newEmail,
        current_password: currentPassword,
      },
    },
  });

  const changeEmail = async (event: FormEvent) => {
    event.preventDefault();

    await mutateFunction();

    setInEditMode(false);
    setIsToastPresent(true);
  };

  if (loading) {
    return (
      <div className="h-40 relative">
        <LoadingCentered />
      </div>
    );
  }

  if (!inEditMode) {
    return (
      <div>
        {!loading && isToastPresent && (
          <Toast
            title="Email Sent!"
            message="An email was sent to you. Please follow instructions stated on the email to continue."
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )}

        <InputWithLabel
          id="email"
          _label="Email"
          type="email"
          aria-label="Email"
          value={email}
          disabled
        />
        <button
          type="button"
          aria-label="Click to change email"
          onClick={() => setInEditMode(true)}
          className="text-primary-500 my-2 cursor-pointer hover:text-primary-600 uppercase"
        >
          Change email
        </button>
      </div>
    );
  } else {
    return (
      <form onSubmit={changeEmail}>
        <InputWithLabel
          id="new-mail"
          _label="New Email"
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />

        <InputWithLabel
          id="retype-email"
          _label="Retype New Email"
          type="email"
          value={retypeEmail}
          onChange={(e) => setRetypeEmail(e.target.value)}
        />

        <InputWithLabel
          id="current-password"
          _label="Current Password"
          type="password"
          aria-label="Enter current password"
          required={true}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <div className="flex justify-between">
          <Button
            className="my-3"
            type="submit"
            role="submit"
            disabled={!newEmail || newEmail !== retypeEmail}
          >
            Save Changes
          </Button>

          <Button
            className="my-3"
            type="button"
            onClick={() => setInEditMode(false)}
            color="error"
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export function AccountAndUserDetailsPassword() {
  const [inEditMode, setInEditMode] = useState(false);
  const [isToastPresent, setIsToastPresent] = useState(false);

  const [newPassword, setNewPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [mutateFunction, { data, loading, error }] = useUpdateAccountMutation({
    variables: {
      details: {
        password: newPassword,
        password_confirmation: retypePassword,
        current_password: currentPassword,
      },
    },
  });

  const changePassword = async (event: FormEvent) => {
    event.preventDefault();
    setInEditMode(false);
    setIsToastPresent(true);

    await mutateFunction();
  };

  const toggleToEditMode = () => {
    setInEditMode(true);

    if (!error) {
      setNewPassword("");
      setRetypePassword("");
      setCurrentPassword("");
    }
  };

  if (loading) {
    return (
      <div className="h-40 relative">
        <LoadingCentered />
      </div>
    );
  }

  if (!inEditMode) {
    return (
      <div>
        {!loading && isToastPresent && (
          <Toast
            title={!error ? "Password Changed!" : "Invalid Password! "}
            message={
              !error
                ? "Password has been changed successfully."
                : "Password must contain 8 characters atleast 1 letter, 1 number and 1 special character"
            }
            toastStatus={!error ? "success" : "error"}
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )}

        <InputWithLabel
          id="password"
          _label="Password"
          type="password"
          aria-label="Password"
          aria-required="true"
          value={"            "}
          disabled={true}
        />
        <button
          type="button"
          aria-label="Click to change password"
          onClick={toggleToEditMode}
          className="text-primary-500 my-2 cursor-pointer hover:text-primary-600 uppercase"
        >
          Change password
        </button>
      </div>
    );
  } else {
    return (
      <form onSubmit={changePassword}>
        <InputWithLabel
          id="new-password"
          _label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <InputWithLabel
          id="retype-password"
          _label="Retype new password"
          type="password"
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
        />

        <InputWithLabel
          id="current-password"
          _label="Type current password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <div className="flex justify-between">
          <Button
            className="my-3"
            type="submit"
            role="submit"
            disabled={
              !newPassword || newPassword !== retypePassword || !currentPassword
            }
          >
            Save Changes
          </Button>

          <Button
            className="my-3"
            type="button"
            onClick={() => setInEditMode(false)}
            color="error"
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export function AccountAndUserDetailsProfile(props: {
  firstName: string;
  lastName: string;
  customFields: any;
}) {
  const [firstName, setFirstName] = useState<string>(props.firstName);
  const [lastName, setLastName] = useState<string>(props.lastName);
  const [customFieldStates, setCustomFieldStates] = useState({});

  const handleInputChange = (name: string, value: any) => {
    setCustomFieldStates((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [isToastPresent, setIsToastPresent] = useState(false);

  const [mutateFunction, { data, loading, error }] = useUpdateProfileMutation({
    variables: {
      details: {
        given_name: firstName,
        family_name: lastName,
        custom_fields: customFieldStates,
      },
    },
  });

  useErrorHandler(error);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await mutateFunction();

    setIsToastPresent(true);
  };

  if (loading) {
    return (
      <div className="h-40 relative">
        <LoadingCentered />
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
      {!loading && isToastPresent && (
        <Toast
          title="Changes Saved!"
          message="changes saved successfully."
          onClose={() => setIsToastPresent(false)}
        ></Toast>
      )}

      <div className="flex flex-col md:flex-row w-full ">
        <InputWithLabel
          id="firstname"
          _label="First Name"
          aria-label="First Name"
          aria-required="true"
          type="text"
          value={firstName}
          className="w-full md:pr-2"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputWithLabel
          id="lastname"
          _label="Last Name"
          aria-label="Last Name"
          aria-required="true"
          type="text"
          value={lastName}
          className="w-full"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      {props.customFields !== null
        ? props.customFields.map((customField: any) => {
            // Hindi daw kasi input talaga si select. Nested HTML element daw siya so... magugulo po yung props ng InputWithLabel kasi pang input lang, specially sa extends part
            if (!customField.options) {
              return (
                <InputWithLabel
                  id={customField.inputName}
                  key={"customFieldWithoutOptions:" + customField.label}
                  _label={customField.label}
                  aria-label={customField.label}
                  required={customField.required}
                  type={customField.inputType}
                  name={customField.inputName}
                  disabled={customField.disabled}
                  value={
                    customFieldStates[
                      customField.inputName as keyof typeof customFieldStates
                    ] === ""
                      ? ""
                      : customFieldStates[
                          customField.inputName as keyof typeof customFieldStates
                        ] ||
                        customField.value ||
                        ""
                  }
                  onChange={(e) => {
                    handleInputChange(customField.inputName, e.target.value);
                  }}
                  className="w-full md:w-1/2 md:md:pr-2"
                ></InputWithLabel>
              );
            } else if (customField.options) {
              return (
                <div
                  key={"customField with options:" + customField.label}
                  className="w-full md:w-1/2 md:pr-2"
                >
                  <h2 className="block text-gray-400 mt-3 mb-2">
                    {customField.required ? (
                      <span className="text-error-400 mr-1">*</span>
                    ) : (
                      ""
                    )}
                    {customField.label}
                  </h2>
                  <Select
                    id={customField.inputName}
                    className="w-full"
                    disabled={customField.disabled}
                    onChange={(e) => {
                      if (e.target.value !== "Choose option")
                        handleInputChange(
                          customField.inputName,
                          e.target.value
                        );
                    }}
                    value={
                      customFieldStates[
                        customField.inputName as keyof typeof customFieldStates
                      ] ||
                      customField.value ||
                      "Choose option"
                    }
                  >
                    <option value="Choose option" disabled>
                      Choose option
                    </option>
                    {customField.options.map((optionValue: string) => {
                      return (
                        <option
                          key={optionValue}
                          value={optionValue}
                          id={optionValue}
                        >
                          {optionValue}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              );
            }
          })
        : ""}

      <Button
        type="submit"
        role="submit"
        tabIndex={0}
        aria-label="Save"
        className="w-fit ml-auto"
      >
        Save
      </Button>
    </form>
  );
}

export function ClaimAccountForm(props: {
  firstName: string;
  lastName: string;
}) {
  const [newEmail, setNewEmail] = useState<string>("");
  // const [retypeEmail, setRetypeEmail] = useState<string>("");
  // const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [error, setError] = useState(false);
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);

  const [isToastPresent, setIsToastPresent] = useState(false);

  const [
    mutateEmailAndPasswordFunction,
    {
      data: emailMutationData,
      loading: emailMutationLoading,
      error: emailMutationError,
    },
  ] = useUpdateAccountMutation({
    variables: {
      details: {
        email: newEmail,
        password: newPassword,
      },
    },
    onError: () => {
      setError(true);
    },
  });

  const [
    mutateProfileFunction,
    {
      data: profileMutationData,
      loading: profileMutationLoading,
      error: profileMutationError,
    },
  ] = useUpdateProfileMutation({
    variables: {
      details: {
        given_name: firstName,
        family_name: lastName,
      },
    },
    onError: () => {
      setError(true);
    },
  });

  // useErrorHandler(emailMutationError);
  // useErrorHandler(passwordMutationError);
  // useErrorHandler(profileMutationError);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await mutateEmailAndPasswordFunction();
    // await mutatePasswordFunction();
    if (!emailMutationError) await mutateProfileFunction();

    setIsToastPresent(true);
  };

  if (
    emailMutationLoading &&
    // passwordMutationLoading &&
    profileMutationLoading
  ) {
    return (
      <div className="h-40 relative">
        <LoadingCentered />
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
      {!emailMutationLoading && !profileMutationLoading && isToastPresent ? (
        !error ? (
          <Toast
            title="Account Claimed!"
            message="Please check your email to finalize changes!"
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        ) : (
          <Toast
            title="Account Claim Unsuccessful!"
            message="Please check your claim details"
            toastStatus="error"
            onClose={() => setIsToastPresent(false)}
          ></Toast>
        )
      ) : (
        ""
      )}

      {/* New Email */}
      <InputWithLabel
        id="new-mail"
        _label="New Email"
        type="email"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        required
      />

      {/* <InputWithLabel
        id="retype-email"
        _label="Retype New Email"
        type="email"
        value={retypeEmail}
        onChange={(e) => setRetypeEmail(e.target.value)}
      /> */}

      {/* New Password */}

      <InputWithLabel
        id="new-password"
        _label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        onFocus={() => {
          setIsPasswordFocus(true);
        }}
        onBlur={() => {
          setIsPasswordFocus(false);
        }}
        required
      />

      <p className="text-xs">
        {isPasswordFocus
          ? "Must be 8 characters long, contain 1 letter, 1 number, 1 special character"
          : ""}
      </p>
      {/* First Name of the User */}
      <InputWithLabel
        id="firstname"
        _label="First Name"
        aria-label="First Name"
        aria-required="true"
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        // placeholder="Guest First Name"
        required
      />
      <InputWithLabel
        id="lastname"
        _label="Last Name"
        aria-label="Last Name"
        aria-required="true"
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        // placeholder="Guest Last Name"
        required
      />
      <span>
        <Button type="submit" role="submit" tabIndex={0} aria-label="Save">
          Save
        </Button>
      </span>
    </form>
  );
}

function FormItemTypeSwitch({
  formItem,
  handleFormChanges,
}: {
  formItem?: AccountAndUserDetailsScreenCustomField;
  handleFormChanges({
    name,
    value,
    file,
  }: {
    name: string;
    value?: string | number | boolean | Date | undefined;
    file?: File;
  }): void;
}) {
  const nonInputElementArray = ["select", "textarea", "radio"];

  if (!formItem?.inputType) {
    return <></>;
  }

  switch (formItem?.inputType) {
    case "textarea": {
      return (
        <div>
          <Label className="block text-gray-400 mt-3 mb-2">
            {formItem.required ? (
              <span className="text-error-400 mr-1">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>
          <InputTextArea
            key={formItem?.id}
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          />
        </div>
      );
    }
    case "select": {
      return (
        <div>
          <Label className="block text-gray-400 mt-3 mb-2">
            {formItem.required ? (
              <span className="text-error-400 mr-1">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>
          <Select
            key={formItem?.id}
            className="w-full"
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          >
            <option value="Choose option">Choose option</option>
            {formItem.options?.map((option) => {
              return <option key={option}>{option}</option>;
            })}
          </Select>
        </div>
      );
    }
    case "radio": {
      return (
        <div>
          <Label className="block text-gray-400 mt-3 mb-2">
            {formItem.required ? (
              <span className="text-error-400 mr-1">*</span>
            ) : (
              ""
            )}
            {formItem.label}
          </Label>

          {formItem.options?.map((option) => {
            return (
              <div key={option} className="flex items-center ">
                <InputRadio
                  required={formItem.required || false}
                  key={option}
                  name={formItem.inputName || ""}
                  value={option || ""}
                  onChange={(e) =>
                    handleFormChanges({
                      name: formItem?.inputName || "",
                      value: e.target.value,
                    })
                  }
                />
                <p className="ml-2">{option}</p>
              </div>
            );
          })}
        </div>
      );
    }
    case "file": {
      return (
        <div key={formItem?.id}>
          <InputWithLabel
            _label={formItem?.label || ""}
            type={formItem?.inputType || ""}
            required={formItem.required || false}
            onChange={(e) => {
              const file = e.target.files?.[0];

              if (file)
                handleFormChanges({
                  name: formItem?.inputName || "",
                  value: file.name,
                  file: file,
                });
            }}
          />
        </div>
      );
    }
    default:
      return (
        <div>
          <InputWithLabel
            _label={formItem?.label || ""}
            type={formItem?.inputType || ""}
            required={formItem.required || false}
            onChange={(e) =>
              handleFormChanges({
                name: formItem?.inputName || "",
                value: e.target.value,
              })
            }
          />
        </div>
      );
  }
}

export function ApprovalStatusIcon({
  approvalStatus,
}: {
  approvalStatus: string;
}) {
  switch (approvalStatus) {
    case "rejected": {
      return <Icon name="error" className="text-xl text-error-700" />;
    }
    case "pending": {
      return <Icon name="error" className="text-xl text-info-700" />;
    }
    default: {
      return <>{approvalStatus}</>;
    }
  }
}

export function ProfileSectionSwitch({
  currentProfileSection,
  email,
  firstName,
  lastName,
  customFields,
  formTabs,
  refetch,
}: {
  currentProfileSection: string;
  email: string;
  firstName: string;
  lastName: string;
  customFields: any;
  formTabs: AccountAndUserDetailsScreenFormTab[];
  refetch: () => void;
}) {
  // Get the specific tab being viewed
  const currentFormTab = formTabs.find(
    (formTab) => formTab.name === currentProfileSection
  );

  const [addView, setAddView] = useState<boolean>(false);
  const [getFormItems, formItems] = useGetFormItemsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [getFormSummaries, formSummaries] =
    useGetFormSubmissionSummariesLazyQuery({
      fetchPolicy: "network-only",
    });

  const [formFields, setFormFields] = useState<
    AccountAndUserDetailsScreenFormTabSubmitFormFields[]
  >([]);
  const [formResponseDetails, setFormResponseDetails] =
    useState<AccountAndUserDetailsScreenFormTabSubmit>({
      form_tab_id: currentFormTab?.id || "",
      form_fields: formFields,
    });

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  function handleFormChanges({
    name,
    value,
    file,
  }: {
    name: string;
    value?: string | number | Date | boolean;
    file?: File;
  }) {
    if (!formFields.some((field) => field.name === name)) {
      setFormFields((prev) => [
        ...prev,
        {
          name: name,
          file: file as unknown as string,
          value: value,
        },
      ]);
    } else {
      const updatedFormFields = formFields.map((field) => {
        if (field.name === name)
          return {
            name: name,
            file: file,
            value: value,
          };

        return field;
      });

      setFormFields(updatedFormFields);
    }
  }

  useEffect(() => {
    setAddView(false);

    // Get Form Summaries for the current tab of meron lang tab id
    if (currentFormTab?.id)
      getFormSummaries({
        variables: {
          formTabId: currentFormTab.id,
        },
      });

    // Cleanup responses
    setFormResponseDetails({
      form_tab_id: currentFormTab?.id || "",
      form_fields: [],
    });

    // Cleanup form fields state
    setFormFields([]);
  }, [currentProfileSection]);

  useEffect(() => {
    setFormResponseDetails((prev) => {
      return {
        form_tab_id: prev.form_tab_id || currentFormTab?.id || "",
        form_fields: [...formFields],
      };
    });
  }, [formFields]);

  const [
    submitForm,
    {
      data: submissionData,
      loading: submissionLoading,
      error: submissionError,
    },
  ] = useFormSubmitMutation({
    onCompleted: () => {
      setAddView(false);
      setFormFields([]);
      setToastVisible(true);
      refetch();
    },
  });

  if (submissionLoading) {
    return <LoadingCentered />;
  }

  switch (currentProfileSection) {
    case "User Details":
      return (
        <div
          className={`md:flex ${
            isDepedProd ? "" : "flex-col"
          } justify-items-stretch gap-4 w-full`}
        >
          {!isDepedProd && (
            <div className="flex gap-4 border-b border-gray-300 pb-4">
              <div className="flex-1">
                <AccountAndUserDetailsEmail
                  email={email as string}
                ></AccountAndUserDetailsEmail>
              </div>

              <div className="flex-1 w-full">
                <AccountAndUserDetailsPassword></AccountAndUserDetailsPassword>
              </div>
            </div>
          )}

          {!isDepedProd && (
            <AccountAndUserDetailsProfile
              firstName={firstName}
              lastName={lastName}
              customFields={customFields}
            ></AccountAndUserDetailsProfile>
          )}

          {isDepedProd && (
            <>
              <div className="flex-1">
                <AccountAndUserDetailsEmail
                  email={email as string}
                ></AccountAndUserDetailsEmail>
              </div>

              <div className="flex-1 w-full">
                <AccountAndUserDetailsPassword></AccountAndUserDetailsPassword>
              </div>
            </>
          )}
        </div>
      );
    case "Additional User Details":
      return (
        <AccountAndUserDetailsProfile
          firstName={firstName}
          lastName={lastName}
          customFields={customFields}
        ></AccountAndUserDetailsProfile>
      );
    default:
      return (
        <div>
          <div className={`${toastVisible ? "block" : "hidden"}`}>
            <Toast
              message="Submission Successful"
              title="Great!"
              toastStatus="success"
              onClose={() => setToastVisible(false)}
              closeDelay={2000}
            />
          </div>

          {!addView ? (
            <Button
              className="my-2"
              onClick={(e) => {
                e.stopPropagation();
                setAddView(true);
                getFormItems({
                  variables: {
                    formTabId: currentFormTab?.id || "",
                  },
                  fetchPolicy: "cache-first",
                });
              }}
            >
              + Add
            </Button>
          ) : (
            <button className="text-lg font-bold">
              <Icon
                name="chevron_left"
                className="bg-gray-700 text-white text-2xl h-8 w-8 text-center rounded-lg"
                onClick={() => {
                  setAddView(false);
                  setFormFields([]);
                }}
                role="button"
              />
            </button>
          )}

          {!addView && formSummaries.loading && (
            <div className="h-40 w-40 mt-20 mx-auto my-auto">
              <Loading />
            </div>
          )}

          {!addView &&
            !formSummaries.loading &&
            formSummaries.data?.accountAndUserDetails?.formTab?.formSubmissionSummaries?.map(
              (formSubmissionSummary) => {
                return (
                  <div
                    key={formSubmissionSummary?.id}
                    className="bg-white p-2 rounded shadow-md mb-2 "
                  >
                    <div className="flex w-full justify-between items-center">
                      <h2 className="font-bold text-gray-700">
                        {formSubmissionSummary?.title}
                      </h2>
                      <ApprovalStatusIcon
                        approvalStatus={
                          formSubmissionSummary?.approvalStatus || ""
                        }
                      />
                    </div>

                    <h3 className="text-gray-500 text-sm">
                      {formSubmissionSummary?.subtitle}
                    </h3>
                    <h3 className="font-bold text-gray-500">
                      {dateFormattingSchedules(
                        formSubmissionSummary?.dateCreated ||
                          "2023-10-02T07:29:46.449Z"
                      )}
                    </h3>
                    <p className="text-xs text-primary-600">
                      {formSubmissionSummary?.fileDescription}
                    </p>
                  </div>
                );
              }
            )}

          {addView && formItems.loading && (
            <div className="h-40 w-40 mt-20 mx-auto my-auto">
              <Loading />
            </div>
          )}

          {addView && !formItems.loading && formItems.data && (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const formData = new FormData();

                formData.append("details", "{}");

                // formData.append("form_tab_id", formResponseDetails.form_tab_id || "")
                // formResponseDetails.form_fields?.forEach((field, index) => {
                //   formData.append(
                //     `form_fields[${index}].name`,
                //     field?.name || ""
                //   );
                //   formData.append(
                //     `form_fields[${index}].value`,
                //     field?.value || ""
                //   );
                //   if (field?.file)
                //     formData.append(`form_fields[${index}].name`, field?.file);
                // });

                submitForm({
                  variables: {
                    details: formResponseDetails,
                  },
                });
              }}
            >
              {formItems.data.accountAndUserDetailsScreen?.formTab?.formItems?.map(
                (formItem) => {
                  return (
                    <FormItemTypeSwitch
                      key={formItem?.id}
                      formItem={formItem || {}}
                      handleFormChanges={handleFormChanges}
                    />
                  );
                }
              )}
              <Button className="ml-auto mt-2" type="submit">
                Submit
              </Button>
            </form>
          )}
        </div>
      );
  }
}

export function AccountAndUserDetails({
  ...props
}: AccountAndUserDetailsProps) {
  const { loading, data, error, refetch } = useAccountAndUserDetailsQuery();

  const refetchData = () => {
    refetch();
  };

  useErrorHandler(error);

  const userInfo = data?.accountAndUserDetailsScreen?.userInfo;
  const userProfile = data?.accountAndUserDetailsScreen?.userProfile;
  const customFields = data?.accountAndUserDetailsScreen?.customFields;
  const formTabs = data?.accountAndUserDetailsScreen?.formTabs;

  const firstName = (userProfile?.givenName || userInfo?.firstName) as string;

  const lastName = (userProfile?.familyName || userInfo?.lastName) as string;

  const { isGuest } = useContext(isGuestContext);

  const defaultProfileSections = ["User Details", "Additional User Details"];
  const dynamicProfileSections =
    formTabs?.map((formTab, index: number) => {
      if (formTab?.name) return formTab?.name as string;

      return "Unknown Tab" + index;
    }) || [];

  const profileSectionArray = [
    ...defaultProfileSections,
    ...dynamicProfileSections.filter(
      (section) => !defaultProfileSections.includes(section)
    ),
  ];

  const [currentProfileSection, setCurrentProfileSection] =
    useState<string>("User Details");

  return (
    <NavBarLayout className="h-full min-h-screen w-full overflow-clip bg-gray-200 flex flex-col">
      <div className="flex max-w-full flex-1">
        {isDepedProd && (
          <AccountAndUserDetailsSidePanel
            className="w-1/4 py-6 px-4 hidden lg:block"
            currentPage="accountAndUserDetails"
          />
        )}

        <main
          className={`py-6 px-4 h-full flex-1 max-w-full lg:max-w-[75%] ${
            isDepedProd ? "" : "mx-auto"
          }`}
        >
          <h1 className="text-2xl font-black text-gray-700 mb-4 ">
            Account and User Details
          </h1>

          {/* Profile Page Navigation */}

          {isDepedProd && (
            <nav
              className={`inline-flex overflow-x-auto space-x-2 max-w-full mb-4 custom-scrollbar scrollbar-color-primary ${
                currentProfileSection === "Add Training" ? "hidden" : ""
              } `}
            >
              {profileSectionArray.map((profileSectionName, index) => {
                return (
                  <button
                    key={index}
                    onClick={(e) =>
                      setCurrentProfileSection(profileSectionName)
                    }
                    className={`bg-primary-200 hover:bg-primary-300 whitespace-nowrap ${
                      currentProfileSection === profileSectionName
                        ? "bg-primary-300"
                        : ""
                    } text-primary-700 py-1 px-2 rounded-md shadow-md transition-colors duration-300`}
                  >
                    {profileSectionName}
                  </button>
                );
              })}
            </nav>
          )}

          {loading ? (
            <LoadingCentered></LoadingCentered>
          ) : isGuest ? (
            <div>
              <div className="my-12 p-12 rounded-lg border bg-white ">
                <h2 className="text-gray-700 font-bold text-lg">
                  Claim account
                </h2>

                <h3 className="text-gray-599 text-sm">
                  To save your progress we highly advice to claim your accout by
                  providing the required details below
                </h3>

                <ClaimAccountForm
                  firstName="Guest FirstName"
                  lastName="Guest LastName"
                />
              </div>
            </div>
          ) : (
            <div>
              {isDepedProd && (
                <h2 className="text-lg font-bold">{currentProfileSection}</h2>
              )}

              {/* Add Button, not visible for non form tabs, hidden when already inside the form */}

              <div
                className={`p-4 rounded-lg border shadow-md bg-white ${
                  !defaultProfileSections.includes(currentProfileSection)
                    ? "p-0 bg-gray-200 shadow-none"
                    : ""
                }`}
              >
                <ProfileSectionSwitch
                  currentProfileSection={currentProfileSection}
                  email={userInfo?.email || ""}
                  firstName={firstName}
                  lastName={lastName}
                  customFields={customFields}
                  formTabs={formTabs as AccountAndUserDetailsScreenFormTab[]}
                  refetch={refetchData}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    </NavBarLayout>
  );
}
