interface CasticulateThemeObject {
  base: string;
  gradientButton: string;
  gradientPanel: string;
  basicButtonText: string;
  basicButtonBorder: string;
  basicPanel: string;
}

export const ColorThemes: { [key: string]: CasticulateThemeObject } = {
  purple: {
    base: "bg-[#e1e5fd]",
    gradientButton: "bg-gradient-to-r from-[#0b6fa8] to-[#835bb6]",
    gradientPanel: "bg-gradient-to-r from-[#0b6fa8] to-[#835bb6]",
    basicButtonText: "text-[#4f60b5]",
    basicButtonBorder: "border-[#4f60b5]",
    basicPanel: "bg-[#4f60b5]",
  },
  blue: {
    base: "bg-[#e1f4ff]",
    gradientButton: "bg-gradient-to-r from-[#3382d4] to-[#2855a8]",
    gradientPanel: "bg-gradient-to-r from-[#3382d4] to-[#2855a8]",
    basicButtonText: "text-[#3863b8]",
    basicButtonBorder: "border-[#3863b8]",
    basicPanel: "bg-[#3863b8]",
  },
  default: {
    base: "bg-[#e5f3ec]",
    gradientButton: "bg-gradient-to-r from-[#02585b] to-[#038b8a]",
    gradientPanel: "bg-gradient-to-r from-[#02585b] to-[#038b8a]",
    basicButtonText: "text-[#02585b]",
    basicButtonBorder: "border-[#02585b]",
    basicPanel: "bg-[#02585b]",
  },
};

export const colorThemes = {};
