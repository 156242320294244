import React, { useState, useContext } from "react";

import { colorHelperBase } from "../../../helpers/ColorHelper";
import { CastColor } from "../../../generated/graphql";
import { Icon, Image } from "../../../atoms/components";
import { Modal } from "../Modal";
import { useSearchParams } from "react-router-dom";
import { TopicRow } from "../TopicRow";
import { ForumTopicCreateViewer } from "../ForumTopicCreateViewer";
import { CourseViewScreen } from "../../../pages/components/CourseViewer";
import { Toast } from "../../../molecules/components";

function getMonthText(month: number) {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
  }
}

interface ForumViewerBlockProps {
  courseViewScreen: CourseViewScreen | undefined | null;
}
export function ForumViewerBlock({ courseViewScreen }: ForumViewerBlockProps) {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid") || "";
  const course = courseViewScreen?.course;
  const isModerator = courseViewScreen?.isUserForumAdmin || false;
  const baseStyle = {
    style: colorHelperBase(
      course?.styleInfo?.base?.backgroundColor as CastColor,
      course?.styleInfo?.base?.foregroundColor as CastColor
    ),
  };
  const forumTopicsList = courseViewScreen?.forumTopics || [];
  const topicsList = forumTopicsList.map((topic: any, index: number) => {
    var firstName = topic?.createdByUserDetails?.firstName;
    var lastName = topic?.createdByUserDetails?.lastName;
    var fullName = firstName + " " + lastName;
    var email = topic?.createdByUserDetails?.email;
    var datetimeCreated = new Date(topic?.created || "");
    var dateCreated =
      getMonthText(datetimeCreated.getMonth()) +
      " " +
      datetimeCreated.getDate() +
      ", " +
      datetimeCreated.getFullYear();
    var timeCreated = datetimeCreated.toLocaleTimeString();
    return {
      topicUuid: topic?.uuid || "",
      title: topic?.title || "",
      createdBy: fullName || email || topic?.createdBy || "",
      dateCreated: dateCreated || "",
      timeCreated: timeCreated || "",
      numComments: topic?.comments?.length || 0,
      isModerator: topic?.isUserAdmin || false,
      isActive: topic?.isActive || false,
    };
  });
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastAction, setToastAction] = useState<string>("");
  const [isTopicCreateModalOpen, setIsTopicCreateModalOpen] = useState(false);

  return (
    <div>
      {/* Cover */}
      <div
        className="flex flex-col w-full px-6 py-6 my-2 text-white md:px-20 bg-primary-500 "
        style={baseStyle.style as React.CSSProperties}
      >
        {/* Course Header */}
        <div className="flex flex-col flex-wrap md:flex-row md:items-center ">
          <Image
            src={course?.coverUri || ""}
            className="w-32 h-32 mb-3 md:mb-0"
          ></Image>
          <div className="flex-1 md:ml-2">
            <div className="text-2xl ">
              <Icon name="school"></Icon>
            </div>
            <p className="text-2xl font-black">{course?.title}</p>
          </div>
        </div>
      </div>

      {toastOpen ? (
        toastAction == "delete" ? (
          <Toast
            title="Topic Deleted"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : toastAction == "hide" ? (
          <Toast
            title="Topic Hidden"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : toastAction == "create" ? (
          <Toast
            title="Topic Posted"
            message=""
            closeDelay={3000}
            toastStatus="success"
            onClose={() => setToastOpen(false)}
          ></Toast>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <div className="mx-6 md:mx-20">
        <p className="my-4 text-2xl font-black text-gray-900">Forums</p>

        {isModerator ? (
          <div className="w-full mb-4">
            <button
              className="flex flex-row justify-center w-full rounded bg-primary-100 text-primary-600"
              onClick={() => setIsTopicCreateModalOpen(true)}
            >
              <Icon name="add_circle" className=""></Icon>
              <p className="ml-1">ADD TOPIC</p>
            </button>

            <Modal
              className={"w-full h-full"}
              disableCloseButton={true}
              isOpen={isTopicCreateModalOpen}
              onRequestClose={() => setIsTopicCreateModalOpen(false)}
            >
              <ForumTopicCreateViewer
                courseUuid={courseUuid}
                setIsTopicCreateModalOpen={setIsTopicCreateModalOpen}
                setToastOpen={setToastOpen}
                setToastAction={setToastAction}
              ></ForumTopicCreateViewer>
            </Modal>
          </div>
        ) : (
          <></>
        )}

        {topicsList.length > 0 ? (
          <div className="">
            {/* List of Topics */}
            {topicsList.map((topic: any, index: number) => {
              if (topic.isActive) {
                return (
                  <TopicRow
                    {...topic}
                    key={index}
                    courseUuid={courseUuid}
                    setToastOpen={setToastOpen}
                    setToastAction={setToastAction}
                  />
                );
              }
            })}
          </div>
        ) : (
          <div className="w-full">
            <p className="my-4 text-base italic text-gray-400">
              Currently, there are no topics available for discussion. Kindly
              wait for the administrators to initiate a topic.
            </p>
          </div>
        )}
      </div>
    </div>
  );
  {
    /*==================================================================================*/
  }
}
