import React, { useState, useEffect } from "react";
import { SearchBar } from "../../../molecules/components/SearchBar/SearchBar";
import { Icon } from "../../../atoms/components/Icon";
import { gql, useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { SearchBlock } from "../../../organisms/components";
import { NavBarLayout } from "../../../templates/components";
import { LoadingCentered } from "../../../molecules/components";
import { useSearchParams } from "react-router-dom";

export interface Content {
  uuid: string;
  image: string;
  title: string;
  type: string;
  subtype: string;
  description: string;
}

export const SEARCH_SCREEN_QUERY = gql`
  query GetSearchScreen($q: String!, $limit: Int, $offset: Int) {
    search_screen(q: $q, limit: $limit, offset: $offset) {
      resources {
        uuid
        name
        type
        subtype
        cover_uri
        description
      }
    }
  }
`;

const handleData = (data: any) => {
  let processedData: Content[] = [];

  data?.search_screen?.resources?.map((resource: any) => {
    resource?.name && resource?.cover_uri && resource?.uuid
      ? processedData.push({
          image: resource?.cover_uri,
          title: resource?.name,
          uuid: resource?.uuid,
          type: resource?.type,
          subtype: resource?.subtype,
          description: resource?.description,
        })
      : processedData.push();
  });

  return processedData;
};

export interface SearchProps {}

export function Search() {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get("search_value") || ""
  );
  const [limit, setLimit] = useState<number>(30);
  const { loading, data, refetch, error } = useQuery(SEARCH_SCREEN_QUERY, {
    variables: {
      q: searchValue ? `${searchValue}:*` : "",
      limit: 30,
    },
  });

  useErrorHandler(error);

  useEffect(() => {
    setLimit(30);
  }, [searchValue]);

  window.onscroll = function () {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      !loading
    ) {
      setLimit(limit + 20);
      refetch({ q: searchValue, limit: limit });
    }
  };

  return (
    <NavBarLayout>
      <div className="overscroll-auto">
        <div className="m-4 md:mx-16">
          {/* <div className="m-4 md:mx-16" ref={ref}> */}
          <SearchBar
            resultCount={handleData(data)?.length}
            setSearchValue={setSearchValue}
          />

          <div>
            <SearchBlock resources={handleData(data)} />
          </div>

          <div>
            {handleData(data).length === 0 && loading ? (
              <LoadingCentered />
            ) : handleData(data).length === 0 && !loading ? (
              <div className=" text-center text-gray-500 ">
                <Icon
                  name="sentiment_neutral"
                  className="text-8xl inline-block w-full"
                ></Icon>
                <p className="text-base">No results found</p>
                <p className="text-base">Please try another keyword</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </NavBarLayout>
  );
}
