import React from "react";
import { twMerge } from "tailwind-merge";

export interface InputDateProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
}

export function InputDate(props: InputDateProps) {
  return (
    <input
      {...props}
      type="date"
      className={twMerge(
        `w-full border border-gray-300 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100 ${props.className}`
      )}
    />
  );
}
