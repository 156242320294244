import { Icon, Button } from "../../../atoms/components";

export interface CantNextVideoProps {
  closeModal: () => void;
}

export function CantNextVideo({ closeModal }: CantNextVideoProps) {
  return (
    <div className="text-center">
      <span className="text-[60px] text-error-500">
        <Icon name="dangerous"></Icon>
      </span>
      <h1 className="my-5 text-2xl font-bold">
        You Can&apos;t Go Past This Resource
      </h1>
      <p className="">Please finish this video resource to proceed</p>
      <Button
        color="secondaryOutline"
        onClick={closeModal}
        className="w-full my-5 hover:bg-secondary-500 hover:text-white"
      >
        Back to Resource
      </Button>
    </div>
  );
}
