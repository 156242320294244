import styles from './ForumBar.css';
import { Icon } from "../../../atoms/components";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForumBarQuery} from "../../../generated/graphql";

export interface ForumBarProps {
  courseUuid: string;
}
export function ForumBar({
  courseUuid,
}: ForumBarProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const { loading, data, error } = useForumBarQuery({variables: {courseUuid: courseUuid}});
  const courseViewScreen = data?.courseViewScreen;
  const topicsList = courseViewScreen?.forumTopics;
  const activeTopicsList = topicsList?.filter((topic) => topic?.isActive);
  const forumTopicCount = activeTopicsList?.length;
  const forumTopicCountText = forumTopicCount ? `${forumTopicCount}` : 'No Topics';

  return <div className={styles.ForumBar}>
      <div className="mx-20">
        <button
            className="flex flex-row items-start justify-between bg-gray-200 p-4 w-full my-6 rounded-md "
            onClick={ () => {navigate(`/forumviewer?course_uuid=${courseUuid}&resource_uuid=na&from_screen=${parentScreenName}`)}}
          >
            <p className="text-base font-bold text-gray-600">
              <span className="inline-block mr-1">
                <Icon name="forum" />
              </span>
              Forum
            </p>
            <p className="text-sm font-normal text-gray-600">
              {forumTopicCountText} discussion(s)
            </p>
        </button>
      </div>
    </div>;
}
