import React, { useState } from "react";
import { Icon } from "../../../atoms/components";
import { Modal } from "../../../organisms/components";
import { daysFromTodayWithTime } from "../../../helpers/DateFormatting";

export interface CatalogCardExpireButtonProps {
  daysTilExpired?: number;
  expiryDate?: string;
}

export function CatalogCardExpireButton({
  daysTilExpired,
  expiryDate,
}: CatalogCardExpireButtonProps) {
  const hasExpiration = true;
  const [isExpirationModalOpen, setIsExpirationModalOpen] =
    useState<boolean>(false);

  const openExpirationModal = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpirationModalOpen(true);
  };

  const expirationModal = () => {
    return (
      <div>
        <Modal
          isOpen={isExpirationModalOpen}
          onRequestClose={(e) => {
            e.stopPropagation();
            setIsExpirationModalOpen(false);
          }}
        >
          <h1 className="text-2xl font-black text-gray-700 text-center mb-4">
            Resource Expiry
          </h1>
          <p className="text-center">
            This resource will no longer be accessible after the expiry schedule
            mentioned below
          </p>

          {/* Expiry Date Div */}
          <div className="bg-info-100 flex flex-col items-center p-4 mt-4 rounded">
            <h2 className="text-info-500 font-bold">
              <Icon
                name="timer_off"
                style={{ fontFamily: "Material icons Outlined" }}
                className="mr-1"
              ></Icon>
              Expires After
            </h2>
            {/* Wait for date format */}
            <p className="font-bold text-2xl text-info-600">
              {daysFromTodayWithTime(expiryDate || "")}
            </p>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    // Used div instead of button pangit daw po i-nest yung button under button.
    <div
      className="text-xl px-1 "
      onClick={(e) => {
        openExpirationModal(e);
      }}
    >
      {expirationModal()}
      {hasExpiration ? (
        <Icon
          name="timer"
          className="text-error-500"
          style={{ fontFamily: "Material Icons Outlined" }}
        />
      ) : null}
    </div>
  );
}
