import React from "react";
import { twMerge } from "tailwind-merge";

export interface InputTextProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
}

export function InputText(props: InputTextProps) {
  const { className, ...prop } = props;

  return (
    <input
      {...prop}
      className={twMerge(
        `w-full border-2 border-gray-300 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:bg-gray-100 ${className}`
      )}
    />
  );
}
